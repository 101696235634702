.view-contact-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.view-contact-content {
  display: flex;
  flex-direction: column;
  width: 650px;
  height: 650px;
  background-color: #f2f5f8;
  font-family: "Work Sans";
  padding: 20px 35px ;

  .contact-title-container {
    display: flex;
    flex-direction: row;

    .contact-name {
      font-size: 20px !important;
      font-weight: 600 !important;
      color: #505665;
      margin: 20px 0;
    }

    .view-contact-btn {
      display: flex;
      justify-content: space-around;
      min-height: 66px;

      button {
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: bold;
        line-height: 14px;
        height: 32px;
        width: 122px;
        padding: 0 10px;
        margin: auto 0;
      }

      .cancel-btn {
        background-color: white;
        color: #3d73dd;
      }

      .create-btn {
        background-color: #3d73dd;
        color: white;
      }

      .hidden {
        visibility: hidden !important;
      }
    }
  }

  .contact-data-container {
    display: flex;
    flex-direction: column;
    .contact-info-field {
      border-bottom: 1px solid #b4c6d9 !important;
      padding: 12px  0;
      .contact-info-item {
        display: flex;
        flex-direction: row;
        font-family: "Work Sans";
        color: #505665;
        font-size: 14px;

        .contact-info-title {
          color: #98a9bb;
        }

        .note-info-content {
          min-height: 100px;
        }
      }
    }
  }
}

.shipping-address-content {
  margin-top: 20px;

  .shipping-address-content-scrollbar {
    max-height: 480px;
    width: 590px;
    position: relative;
    .not-available-msg-container {
      display: grid;
      place-items: center;
      height: 300px;
      .not-available-msg {
        color: #98a9bb;
      }
    }
    .shipping-card-content {
      margin-bottom: 10px;
      width: 580px;
      font-family: "Work Sans";
      .shipping-card-info {
        background-color: rgba(61, 115, 221, 0.1);
        display: flex;
        justify-content: space-between;
        border-radius: 6px;

        .shipping-card-info-title {
          display: flex;
          flex-direction: column;
          padding: 10px !important;
          width: 100%;

          .shipping-card-title {
            font-size: 20px;
            font-weight: bold;
            color: #505665;
            line-height: 24px;
            padding: 0 7px;
            margin-bottom: 5px;
            margin-top: 7px;
          }

          .shipping-card-data {
            display: flex;
            flex-direction: column;
            padding: 0 !important;
            .shipping-card-info-field {
              border-bottom: 1px solid #b4c6d9 !important;
              padding: 6px 7px;
              .shipping-card-info-item {
                display: flex;
                flex-direction: row;
                font-family: "Work Sans";
                color: #505665;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 5px;

                .shipping-card-info-name {
                  color: #98a9bb;
                  padding: 0 !important;
                }
              }
            }
          }

        }
        .shipping-card-btn-container {
          display: flex;
          padding: 0 !important;
          justify-content: flex-end;

          .card-btn {
            background-color: rgba(61, 115, 221, 0.1);
            border-radius: 0 10px 10px 0;
            display: flex;
            align-items: center;
            width: 26px !important;
            svg {
              color: #3d73dd;
            }
          }

          .bottom-borders {
            border-bottom-right-radius: 0;
          }
        }
      }
      .shipping-card-bottom-borders {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .shipping-card-info-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: rgba(216, 224, 232, 0.3);
        padding: 10px !important;

        .cost-positions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding-right: 10px;

          .create-cost {
            display: flex;
          }
        }

        .details-container {
          background-color: rgba(216, 224, 232, 0.3);
          border-radius: 6px;
          padding: 0 !important;
          height: 100%;

          .details-title {
            background-color: rgba(61, 115, 221, 0.1);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            padding: 5px;
            span {
              color: #505665;
              font-size: 14px;
              font-weight: bold;
              margin: 5px;
            }
          }

          .details-item {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #b4c6d9;

            margin:10px 10px 0 10px;

            .details-item-title {
              color: #505665;
              font-size: 14px;
              font-weight: bold;
              line-height: 16px;
              padding: 0 !important;
              margin-bottom: 5px;
            }

            .detail-item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0 !important;

              .detail-count {
                color: #000000;
                font-weight: bold;
              }

              span {
                color: #98a9bb;
                font-size: 14px;
              }
            }
          }

          .detail-total-cost {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 30px 10px 10px 10px;

            .total-cost-item {
              display: flex;
              flex-direction: column;

              .total-cost {
                color: #505665;
                font-size: 20px;
                font-weight: 600;
              }

              span {
                color: #98a9bb;
                font-size: 14px;
              }
            }
          }
        }

      }
    }
  }
}
