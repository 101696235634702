.customer-contact-details {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  background-color: rgba(216, 224, 232, 0.3);
  border-radius: 6px;
  margin-bottom: 10px;

  .customer-contact-details-title {
    background-color: rgba(61, 115, 221, 0.1);
    min-height: 34px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 15px !important;
    display: flex;
    justify-content: space-between;

    .customer-details-link {
      color: #3d73dd;
      cursor: pointer;
      margin-right: 15px;
    }

    span {
      color: #505665;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
  }

  .customer-contact-details-content {
    color: #98a9bb;
    font-family: "Work Sans";
    font-size: 14px;
    display: flex;
    flex-direction: row;
    padding: 10px 5px !important;
    .customer-details-info-title {
      display: flex;
      flex-direction: column;
    }
    .customer-details-info-data {
      color: #505665;
      display: flex;
      flex-direction: column;

      .customer-details-info-data-item {
        width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
