.create-task-help-dialog-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0 !important;
  }
}

.create-task-help-dialog-content {
  width: 653px;
  height: 610px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  color: #505665;
  font-size: 14px;
  padding: 30px;

  .help-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
  }

  .help-title:first-of-type {
    margin-top: 0;
  }
}
