.cost-position-content {
  margin-bottom: 10px;
  font-family: "Work Sans";
  .cost-position-info {
    background-color: rgba(61, 115, 221, 0.1);
    display: flex;
    justify-content: space-between;
    border-radius: 6px;

    .cost-position-info-title {
      display: flex;
      justify-content: space-between;
      padding: 15px 26px 15px 9px !important;
      width: 100%;

      .cost-position-title {
        font-size: 20px;
        font-weight: bold;
        color: #505665;
        line-height: 24px;
      }

      .total-cost-container {
        display: flex;

        .cost-position-total-price {
          color: #505665;
          font-size: 20px;
          font-weight: 600;
          font-family: "Work Sans";
          line-height: 24px;
          margin-left: 70px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .price {
            color: #333333;
            font-size: 12px;
            font-weight: normal;
            padding-right: 71px;
          }

        }
      }
    }
    .cost-card-btn-container {
      display: flex;
      padding: 0px !important;
      justify-content: flex-end;

      .card-btn {
        background-color: rgba(61, 115, 221, 0.1);
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        width: 26px !important;
        svg {
          color: #3d73dd;
        }
      }

      .bottom-borders {
        border-bottom-right-radius: 0;
      }
    }
  }
  .cost-bottom-borders {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .cost-position-info-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(216, 224, 232, 0.3);
    padding: 10px !important;

    .cost-positions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-right: 10px;

      .create-cost {
        display: flex;
      }
    }

    .details-container {
      background-color: rgba(216, 224, 232, 0.3);
      border-radius: 6px;
      padding: 0 !important;
      height: 100%;

      .details-title {
        background-color: rgba(61, 115, 221, 0.1);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: 5px;
        span {
          color: #505665;
          font-size: 14px;
          font-weight: bold;
          margin: 5px;
        }
      }

      .details-item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #b4c6d9;

        margin:10px 10px 0 10px;

        .details-item-title {
          color: #505665;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          padding: 0 !important;
          margin-bottom: 5px;
        }

        .detail-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 !important;

          .detail-count {
            color: #000000;
            font-weight: bold;
          }

          span {
            color: #98a9bb;
            font-size: 14px;
          }
        }
      }

      .detail-total-cost {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 10px 10px 10px;

        .total-cost-item {
          display: flex;
          flex-direction: column;

          .total-cost {
            color: #505665;
            font-size: 20px;
            font-weight: 600;
          }

          span {
            color: #98a9bb;
            font-size: 14px;
          }
        }
      }
    }

  }

  .cost-position-type-content {
    background-color: rgba(215, 225, 240, 0.8);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;

    .cost-position-type-container {
      width: 100%;
      padding-top: 20px;
    }

    .create-cost-type-item {
      margin-left: 10px;
      margin-bottom: 6px;
    }

    .cost-position-type-top {
      display: flex;
      flex-direction: row;
      margin-bottom: 6px;
      padding: 0 6px;
    }

    .cost-position-types {
      margin-bottom: 10px;
      padding: 0 6px !important;

      .cost-position-type-titles {
        display: flex;
        flex-direction: row;
        padding: 0 !important;
        margin-bottom: 5px;
        margin-right: 35px;
        span{
          color: #98a9bb;
        }
      }

      .cost-position-type-inputs {
        display: flex;
        flex-direction: row;
        padding: 0 5px !important;
        margin-bottom: 5px;

        .dropdown-without-borders {
          margin: 0 !important;
          border-radius: 0 !important;
          width: 100% !important;
        }

        .without-borders {
          border-radius: 0 !important;
        }

        .type-delete-btn {
          margin-left: 8px;
          padding: 5px !important;
        }

        .type-delete-btn-div {
          width: 50px;
        }

        .without-left-borders {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }

        .without-right-borders {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .cost-position-type-input-container {
          padding: 0 !important;
        }
        input {
          width: 100%;
        }

      }
    }

    .cost-position-type-item {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding: 6px !important;

      span {
        color: #98a9bb;
        padding-left: 8px;
        margin: 5px 0;
        width: 100%;
      }
    }

    .cost-type-input {
      input {
        max-width: 392px;
      }

    }

    .cost-amount-total-input {
      input {
        max-width: 202px;
      }
    }

    .cost-type-card-btn-container {
      display: flex;
      padding: 0 !important;
      justify-content: flex-end;
      width: 26px ;
      .card-btn {
        background-color: rgba(61, 115, 221, 0.1);
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;

        svg {
          color: #3d73dd;
        }
      }

      .bottom-borders {
        border-bottom-right-radius: 0;
      }
    }
  }

  .create-cost-btn {
    background-color: #3d73dd !important;
    border-radius: 6px;
    color: white !important;
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px !important;
    min-width: 155px;
    padding: 0 15px;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }
}


