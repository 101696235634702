@import "../../assets/scss/material-dashboard-pro-react.scss";

.timer-container {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 40px;
  font-family: "Work Sans" !important;
  
  .timer-header {
    align-items: center;
    background-color: #3d73dd;
    display: flex;
    flex-direction: row;
    min-height: 44px !important;
    justify-content: space-between;

    .timer-header-title {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }

    .timer-header-content {
      display: flex;
      flex-direction: row;

      .timer-time {
        background-color: #0acf05;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        height: 17px;
        padding: 3px;
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }

  .timer-content {
    padding: 21px 11px 10px 11px !important;
    display: flex;
    flex-direction: column;
    background-color: #dae3f0;

    .timer-main-content {
      display: flex;
      flex-direction: row;

      .timer-info-content {
        width: 586px;
  
        .timer-type-items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 4px;
  
          .timer-type {
            width: 50%;

            div {
              display: flex;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              background-color: #e9eef3;
              border-radius: 6px;
              color: #505665;
              height: 32px;
              width: 145px;
              margin: 0 4px;
              text-align: center;
            }
          }
  
          .selected {
            div {
              background-color: #3d73dd;
              color: #ffffff;
            }
          }
        }
  
        .timer-order-position-cost {
          display: flex;
          flex-direction: row;
          margin-top: 16px;
          margin-left: 4px;
  
          .timer-dropdown-item {
            display: flex;
            flex-direction: column;
            width: 50% !important;
  
            .timer-dropdown {
              width: calc(100% - 5px) !important;
              margin: 0 5px 0 0 !important;

              .MuiButton-label {
                color: inherit !important;
                padding-left: 0 !important;
              }
            }

            .ikt-ap_custom-dropdown-toggle-btn {
              min-width: calc(100% - 5px) !important;
              max-width: calc(100% - 5px) !important;
            }

            .ikt-ap_custom-dropdown-menu {
              min-width: calc(100% - 4px) !important;
              max-width: calc(100% - 4px) !important;
              max-height: 140px !important;
              margin-left: 2px !important;
            }

            .ikt-ap_custom-autocomplete {
              span,
              input {
                font-family: 'Work Sans' !important;
              }
            }
          }
        }

        .timer-order-position-cost.two-row {
          flex-direction: column;

          .timer-row {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
          }

          .timer-row:first-of-type {
            margin-top: 0;
          }
        }
  
        .timer-note {
          margin-top: 19px;
  
          .description-test-area {
            height: 60px;
            width: 576px;
            background-color: white;
            border-radius: 6px;
            margin: 0 4px;
          }
        }
  
        span {
          color: #98a9bb !important;
          padding-left: 10px;
        }
      }
  
      .timer-dropdowns {
        display: flex;
        flex-direction: row;
  
        .timer-dropdown-item {
          padding:  0!important;
  
          button {
            margin: 0 !important;
          }
  
          .dropdown-title {
            color: #98a9bb !important;
            padding-left: 10px;
          }
        }
      }
  
      .timer-note {
        display: flex;
        flex-direction: column;
        max-width: 515px;
  
        span {
          color: #98a9bb !important;
          padding-left: 10px;
        }
      }
  
      .timer-start {
        justify-content: center;
        align-items: center;
        background-color: #e9eef3;
        border-radius: 5px;
        color: #505665;
        display: flex;
        flex-direction: column;
        padding: 10px;
  
        .timer-time {
          font-size: 36px;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 210px;
          margin-top: 5px;
          margin-bottom: 55px;
  
          .timer-wrapper {
            margin-top: 50px;
          }
  
          .hours-wrapper {
            font-size: 14px;
            font-weight: normal;
            margin-top: 10px;
          }
        }
  
        .start-btn {
          margin-top: 20px;
          width: 100%;
        }
      }
    }

    .timer-btn-bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 5px;

      .create-btn {
        margin-left: 10px;
      }
    }
  }

  .timer-btn {
    border-radius: 6px !important;
    color: white;
    font-size: 12px;
    font-family: "Work Sans" !important;
    font-weight: bold !important;
    line-height: 14px;
    height: 32px !important;
    padding: 0 10px !important;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }

  .create-btn,
  .create-btn:hover,
  .create-btn:active {
    width: 109px !important;
    background-color: #0fd280 !important;
    color: white !important;
  }

  .control-btn,
  .control-btn:hover,
  .control-btn:active {
    width: 190px !important;
    background-color: #3d73dd !important;
    color: white !important;
  }

  .cancel-btn,
  .cancel-btn:hover,
  .cancel-btn:active {
    width: 109px !important;
    background-color: white !important;
    color: #3d73dd !important;
  }
}
