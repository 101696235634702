@import "../../../assets/scss/material-dashboard-pro-react.scss";

.material-list-position-content {
  font-family: 'Work Sans';
  margin: 5px 0;

  .material-list-position-info {
    background-color: rgba(10, 64, 172, 0.1);
    display: flex;
    justify-content: space-between;
    border-radius: 6px;

    .material-list-position-info-container {
      padding: 17px 25px 13px 8px !important;
      width: 100%;

      .material-list-position-info-title {
        position: relative;
        display: flex;

        .position-title {
          font-size: 20px;
          font-weight: bold;
          color: #505665;
          line-height: 24px;
          padding-bottom: 2px;
          width: 90px;

          .MuiCheckbox-root {
            padding: 0 7px 0 0 !important;
            margin: 0 !important;
          }
        }

        .rie-preview {
          display: inline-block;
          width: 60px !important;
          position: relative;
          bottom: -1px;
        }

        .rie-input-edit {
          display: inline-block;
          position: static;
          margin-top: -4px;

          input {
            max-width: 60px;
          }
        }

        .position-inputs {
          display: flex;
          flex-direction: row;
          margin-top: -7px;

          .position-type-dropdown,
          .ikt-ap_custom-dropdown {
            width: 100px !important;
          }

          .alternative-checkbox-container {
            margin-left: 5px;
            padding-top: 2px;
          }

          .alternative-checkbox-container > span {
            padding-right: 5px !important;
          }

          .title-outer-container {
            margin-right: 10px;
            width: 230px;

            .ikt-ap_input-container {
              padding-top: 5px;
            }
          }
        }

        .position-total-price {
          color: #505665;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          position: absolute;
          right: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          
          .parts-item {
            display: flex;
            flex-direction: column;
            text-align: end;

            .parts-price {
              font-size: 14px;
              font-weight: 800;
              color: #505665;
              line-height: 16px;
              padding: 0 3px;
            }
          }
          .refresh-price-btn {
            .refresh-icon {
              width: 15px;
              height: 15px;
              padding: 0px!important;
              margin: 0px!important;
            }
          }
          .price {
            color: #333333;
            font-size: 12px;
            font-weight: normal;
            padding-right: 50px;
            display: flex;
            flex-direction: column;

            .price-title {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              text-align: right;
              color: #333333;
            }
            .price-value {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #505665;
              align-self: flex-end;
            }
            .price-total {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              display: flex;
              align-items: center;
              text-align: right;
              color: #505665;
              align-self: flex-end;
            }
            .price-override {
              color: red !important;
            }
          }
        }
      }

      @media (max-width: 1220px) {
        .material-list-position-info-title {
          height: 85px;

          .position-total-price {
            top: 40px;
          }
        }
      }

      .position-info-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: 59px;
        width: 100%;
              .price-input {
                display: flex;
                flex-direction: column;
                width: 10%;
              }
              .metrics-input {
                display: flex;
                flex-direction: column;
                width: 10%;
                .position-metrics-dropdown {
                  width: 100%!important;
                  margin: 0px;
                }
              }
              .amount-input {
                display: flex;
                flex-direction: column;
                width: 10%;
              }

        .position-input-item {
          display: flex;
          flex-direction: column;
          padding-right: 8px;
          max-width: 28%;
          flex-basis: 28%;
          span {
            color: #98a9bb;
            padding-left: 10px;
            padding-bottom: 4px;
          }

          @media (max-width: $screen-md-min) {
            span {
              padding-left: 0;
            }
          }
        }

        

        .name-input,
        .material-input {
          max-width: 23%;
          flex-basis: 23%;
        }

        @media (max-width: $screen-md-min) {
          .position-input-item {
            max-width: 31.333%;
            flex-basis: 31.333%;
          }

          .description-input {
            max-width: 15%;
            flex-basis: 15%;
          }
        }

        .assignee-input {
          max-width: 15%;
          flex-basis: 15%;
        }

        .description-input {
          max-width: 15%;
          flex-basis: 15%;
          input {
            cursor: pointer;
          }
        }

        .position-date-picker {
          max-width: 15%;
          flex-basis: 15%;
        }
        .position-inner-inputs-container {
          display: flex;
          flex-direction: row;
          width: 74%;
          .description-input {
            flex-direction: column;
            display: flex;
            width: 48%;
          }
          .assignee-input {
            display: flex;
            flex-direction: column;
            max-width: 15%;
            flex-basis: 15%;
          }
          .position-date-picker {
            display: flex;
            flex-direction: column;
            max-width: 15%;
            flex-basis: 15%;
          }

        }
      }

      .position-parts-price {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        color: #333333;
        margin-top: 15px;
        width: 18%;


      }
      .position-input-span {
        color: #98a9bb;
        padding-left: 10px;
        padding-bottom: 4px;
      }
    }

    .card-btn-container {
      display: flex;
      padding: 0px !important;
      justify-content: flex-end;
      width: 26px !important;
      .card-btn {
        background-color: rgba(61, 115, 221, 0.1);
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        width: 26px !important;
        svg {
          color: #3d73dd;
        }
      }

      .bottom-borders {
        border-bottom-right-radius: 0;
      }
    }
  }

  .alternative-position {
    background-color: rgba(15, 210, 128, 0.1) !important;
  }

  .bottom-borders {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .position-info-content {
    background-color: rgba(216, 224, 232, 0.3);
    font-family: 'Work Sans';
    padding: 12px;

    .position-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 !important;

      .position-btn-part {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .position-btn {
          border-radius: 6px !important;
          height: 32px !important;
          padding: 9px 30px !important;
          font-size: 12px;
          font-family: 'Work Sans';
          font-weight: bold;
          margin: 18px 8px 10px 0;
        }

        .blue {
          background-color: #3d73dd;
          color: white;
        }

        .white {
          background-color: white;
          color: #3d73dd;
        }

        .green {
          background-color: #0fd280;
          color: white;
          margin-right: 0 !important;
        }
      }

      .delete-position-btn {
        .position-btn {
          width: 170px !important;
        }
      }

      .right-aligned {
        justify-content: flex-end;
      }
    }
  }
}