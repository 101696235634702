.cost-center-container {
  margin-bottom: 65px;
  .cost-center-list-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px 24px 0 24px !important;
  }

  .page-ination-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
}

.material-list-add-position-btn {
  background-color: #3d73dd !important;
  color: white;
  font-size: 12px;
  font-family: "Work Sans" !important;
  font-weight: bold !important;
  line-height: 14px;
  height: 32px !important;
  width: 100%;
  padding: 0 15px !important;
}
