.manufacturer-details-container {
  font-family: "Work Sans";

  .manufacturer-details-top-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .manufacturer-details-top-left {
      padding: 0 21px !important;
      font-size: 12px;

      .inquiries-count {
        font-weight: bold;
      }

    }

    .manufacturer-details-top-right {
      display: flex;
      padding: 0 24px !important;
      flex-wrap: wrap;
      margin-left: -6px;

      .status-dropdown {
        margin-top: 5px;
        .status-title {
          display: block;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          color: white;
          font-family: "Work Sans";
        }

        button {
          height: 23px;
          width: 130px !important;
          padding: 0 5px !important;
          border-radius: 5px !important;
        }

      }

      .red {
        color: #f84141;
        button {
          background-color: #f84141 !important;
        }
        .CircularProgressbar-path {
          stroke: #f84141 !important;
        }
      }

      .green {
        color: #0fd280;
        button {
          background-color: #0fd280 !important;
        }
        .CircularProgressbar-path {
          stroke: #0fd280 !important;
        }
      }
    }

    .manufacturer-details-btn {
      background-color: #3d73dd !important;
      border-radius: 6px !important;
      min-width: 100px;
      color: white;
      font-size: 12px;
      font-family: 'Work Sans' !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 16px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }

    .delete-manufacturer-btn {
      background-color: white !important;
      color: #3d73dd !important;
      margin-right: 5px;
    }
  }

  .manufacturer-details-content {
    width: 100%;
    padding: 0 39px !important;
    margin-top: 30px;

    .manufacturer-details-card {
      display: flex;
      flex-direction: column;
      background-color: rgba(216, 224, 232, 0.3);
      border-radius:10px;
      padding: 0 !important;
      margin-bottom: 10px;
      width: 100%;

      .manufacturer-details-card-title {
        background-color: rgba(61, 115, 221, 0.1);
        min-height: 34px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 5px 11px !important;
        span {
          color: #505665;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
        }
      }

      .manufacturer-details-card-content {
        color: #98a9bb;
        font-family: "Work Sans";
        font-size: 14px;
        display: flex;
        flex-direction: column;
        padding: 10px 17px !important;
        min-width: 1100px !important;

        .manufacturer-information {
          display: flex;
          flex-direction: row;

          .manufacturer-info-item {
            display: flex;
            flex-direction: row;
            font-size: 14px;

            .info-title {
              color: #98a9bb;
              padding: 0 !important;
            }

            .info {
              color: #505665;
              padding: 0 !important;
            }

            .bold {
              font-weight: bold
            }
          }

          .company-info {
            display: flex;
            flex-direction: column;
            margin-right: 25px;
            width: 100%;

            .company-logo {
              width: 99px;
              height: 66px;
              margin-bottom: 15px;

              img {
                height: 100%;
                width: auto;
              }
            }
          }

          .manufacturer-info {
            display: flex;
            flex-direction: column;
            width: 100%;

            .reset-password {
              color: #3d73dd;
              cursor: pointer;
              font-size: 12px;
              font-weight: bold;
              margin: 24px 0 39px 0;
            }
          }
        }

        .manufacturer-calendar-container {
          display: flex;
          margin-top: 20px;
          align-content: center;
        }
      }
    }
  }

  .contact-person-card {
    background-color: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 19px 2px !important;
    margin: 20px 0;

    .card-title {
      font-size: 12px;
      font-weight: 600;
      color: #333333;
      padding: 0 15px;
    }
    .contact-person-item {
      color: #3d73dd;
      display: flex;
      flex-direction: row;
      .item-title {
        color: #98a9bb !important;
      }
    }
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }
}
