.logo-content {
  position: relative;

  .logo-upload {
    background-color: #e9eef3;
    border-radius: 6px;
    border: 1px solid #e9eef3;
    color: #98a9bb;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 8px 8px;
    width: 300px;
    position: relative;

    .logo-img {
      border-radius: 6px;
      height: 60px;
      width: 99px;
    }

    .logo-container {
      background-color: white;
      border-radius: 6px;
      height: 60px;
      width: 99px;
    }

    .logo-upload-content {
      display: flex;
      flex-direction: column;
      line-height: 16px;
      padding: 0 10px 0 0;
      margin: 0;
      span {
        margin-left: 11px;
      }
    }

    .file-input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .file-input + label {
      font-size: 12px;
      font-weight: bold;
      color: rgba(61, 115, 221, 1);
      padding-left: 13px;
      padding-top: 10px;
    }

    .file-input:focus + label,
    .file-input + label:hover {
      color: rgba(61, 115, 221, 0.8);
    }
    
    .remove-button {
      position: absolute;
      right: 25px;
      bottom: 10px;
      font-size: 12px;
      font-weight: bold;
      color: rgba(61, 115, 221, 1);
    }

    .remove-button:hover {
      color: rgba(61, 115, 221, 0.8);
      cursor: pointer;
    }
  }

  .error {
    border-color: #f84141;
  }

  .error-msg-container {
    font-size: 14px !important;
    color: #f84141 !important;
    padding-left: 4px;
    margin-top: 4px;
    max-width: 100%;
  }
}
