@import '../../assets/scss/material-dashboard-pro-react.scss';

$highlight-color: #eee;

.ikt-ap_custom-hierarchical-multiselect-filter {
  position: relative;

  .ikt-ap_custom-dropdown-toggle-btn {
    border: 1px solid #eeeeee;
    border-radius: 6px !important;

    &:hover,
    &:focus {
      border-color: #477eea;
    }
    span {
      overflow: hidden;
      margin-right: 12px;
    }
    span img {
      position: absolute;
      right: 0;
      margin-right: 2.5px;
    }
  }

  .ikt-ap_custom-dropdown-toggle-btn {
    span {
      display: flex !important;
    }
    .status-title {
      text-align: left;
    }
  }

  .MuiListItem-button {
    color: #000;
    &:hover {
      background-color: transparent;
      color: #000;
      box-shadow: none;
    }
  }

  .dropdown-error {
    border: 1px solid #f84141 !important;
  }

  .ikt-ap_custom-dropdown-menu {
    border-radius: 6px;
    border: 1px solid #477eea;
    position: absolute;
    left: 3px !important;
    text-align: left !important;
    min-width: calc(100% - 6px);
    max-width: calc(100% - 6px);
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border: 1px solid slategrey;
    }
  }

  .ikt-ap_custom-dropdown-menu-icon {
    left: -10px;
  }

  .ikt-ap_custom-dropdown-menu-no-icon {
    left: 0;
  }

  .ikt-ap_custom-dropdown-toggle-btn{
    span {
      display: flex !important;
    }
    .status-title {
      text-align: left;
    }
  }

  .mh-menu-item {
    padding: 7px 5px;

    .checkbox-container {
      vertical-align: top;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      bottom: 1px;

      .MuiButtonBase-root {
        margin: 0;
        padding: 0;

        span {
          margin: 0;

          input {
            margin: 0;
          }
        }
      }
    }

    .text-container {
      color: black;
      display: inline-block;
      white-space: normal !important;
      margin-left: 6px;
    }

    &:hover {
      background-color: transparent !important;
      box-shadow: none !important;

      .text-container {
        color: black !important;
      }
    }
    
  }

  .mh-menu-item-l1 {
    padding-left: 16px !important;
  }

  .mh-menu-item-l2 {
    padding-left: 27px !important;
  }

  .mh-menu-item-l3 {
    padding-left: 38px !important;
  }

  .ikt-ap_custom-dropdown-item-highlighted {
    color: #3d73dd !important;
    background-color: $highlight-color !important;
    box-shadow: none !important;

    .text-container {
      color: #3d73dd !important;
    }

    &:hover {
      color: #3d73dd !important;
      background-color: $highlight-color !important;
      box-shadow: none !important;

      .text-container {
        color: #3d73dd !important;
      }
    }
  }

  .MuiList-root {
    width: 100%;
    max-height: 250px;
    z-index: 200;
    overflow-y: auto;
  }

  @media (max-width: $screen-md-min) {
    div[role="tooltip"] {
      left: 0;
      right: 0;
    }
  }
}


