$highlight-color: black;
$border-color: #ccc;

.RTEditor-root {
    width: 100%;
    height: 100%;

    padding: 4px;
    border: 1px solid $border-color;

    .RTEditor-toolbar {
        padding-bottom: 4px;
        border-bottom: 1px solid $border-color;

        .toolbar-button-group {
            display: inline-block;
            padding: 0 5px;
            margin: 0;

            border-right: 1px solid $border-color;

            .toolbar-btn {
                color: gray;
                padding: 0px !important;
                margin: 0 5px;

                img {
                    max-width: 30px;
                    max-height: 30px;
                }
            }
        }

        .toolbar-button-group:first-of-type {
            padding-left: none;
        }

        .toolbar-button-group:last-of-type {
            padding-right: none;
            border-right: none;
        }
    }

    .DraftEditor-root {
        padding: 6px 3px 6px 3px;
        max-height: calc(100% - 31px);
        overflow-y: auto;
        overflow-x: hidden; /* fixes a problem caused by the placeholder */
    }
}

.RTEditor-error {
    border-color: red !important;
}

.RTEditor-toolbar-menu {
    li {
        cursor: pointer;
        display: block;

        min-height: 34px;
        line-height: 34px;

        padding: 0 8px;

        color: $highlight-color;
        background-color: none;
    }

    .heading-1-option {
        font-size: 20px;
        font-weight: bold;
    }

    .heading-2-option {
        font-size: 18px;
        font-weight: bold;
    }

    .heading-3-option {
        font-size: 16px;
        font-weight: bold;
    }

    .heading-4-option {
        font-size: 14px;
        font-weight: bold;
    }

    .selected {
        color: white !important;
        background-color: $highlight-color !important;
    }
}