.dropdown-button-outer-container {
  position: relative;

  .dropdown-button-label-container {
    display: inline-block;
    position: relative;
    left: -14px;
  }

  .dropdown-button-img-container {
    display: inline-block;
    position: absolute;
    right: 0;
    min-width: 30px !important;
    min-height: 100%;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    vertical-align: center;

    img {
      display: inline-block;
      margin-top: 12px;
      margin-right: 2px;
      transform: none;
    }

    .img-rotated {
      transform: rotate(180deg);
    }
  }

  .dropdown-button-menu-outer-container {
    z-index: 10000 !important;

    .dropdown-button-menu {  
      background-color: transparent !important;
      box-shadow: none !important;

      .dropdown-button-menu-item {
        margin-top: 6px;
      }
    }
  }
}