.create-compound-material-dialog {
  .create-material-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;
    .dialog-title {
      display: flex;
      font-family: 'Work Sans' !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .create-material-content {
    width: 800px !important;
    // height: 700px;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: 'Work Sans';
    padding: 15px 20px 0 20px;

    .units-checkbox {
      margin-left: 0;
      margin-top: 2px;

      svg {
        fill: gray;
      }
      span {
        font-family: "Work Sans" !important;
        font-size: 14px !important;
        color: #505665 !important;
        line-height: 16px !important;
      }
      span:hover {
        background-color: rgba(180, 198, 217, 0.1) !important;
      }
    }

    .units-checkbox-checked {
      svg {
        fill: #4277de !important;
      }
    }

    .user-info-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .materials-list-header {
        .dropdown-blue {
          margin: 5px;
        }
      }
      .material-info-header {
        padding: 5px !important;
        .header-item {
          padding: 5px !important;
        }
        div > input {
          margin-top: 5px;
          padding: 15px;
        }
      }
      .material-dropdown {
        width: 100% !important;
        .MuiButton-label {
          color: #333333;
        }
      }
      .description {
        padding: 5px !important;
        height: 120px !important;
      }
      input {
        width: 100% !important;
      }

      .item-title {
        font-size: 14px;
        font-weight: 400;
        color: #98a9bb;
        line-height: 16px;
        margin-left: 10px;
      }

      .stock-dropdown {
        background-color: #0fd280 !important;
        height: 23px !important;
        padding: 2px 10px !important;
        border-radius: 6px !important;

        span {
          color: #ffffff !important;
          margin-left: 0px;
        }
      }
      .user-status {
        width: 100%;
        height: 32px;
      }
      .user-status-dropdown {
        width: 100% !important;
        height: 32px !important;
        margin: 0px !important;
      }
    }

    .user-info {
      display: flex !important;
      flex-direction: column !important;
      height: 60px;

      textarea {
        margin-top: 4px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #98a9bb;
        line-height: 16px;
        margin-left: 10px;
      }

      .margin {
        margin-top: 30px;
      }

      .user-radio-container {
        margin-left: -25px;

        label {
          height: 25px;
        }

        span {
          font-family: 'Work Sans';
          color: #505665;
        }
      }
    }
    .add-material-btn {
      background-color: #3d73dd !important;
      color: white;
      font-size: 12px;
      border-radius: 6px !important;
      font-family: 'Work Sans';
      font-weight: bold;
      line-height: 14px;
      height: 32px !important;
      width: 150px;
      padding: 0 30px;
      margin: 5px;

      span {
        color: white !important;
      }
      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }
  }

  .user-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 20px;
    width: 800px;

    button {
      font-size: 12px;
      font-family: 'Work Sans';
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }

    .cancel-btn {
      background-color: white !important;
      color: #3d73dd !important;
    }

    .create-btn {
      min-width: 150px !important;
      background-color: #0fd280 !important;
      color: white;
    }
  }
  .compound-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #f2f5f8;
    font-family: 'Work Sans';
    margin-bottom: 30px;
    .footer-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 16px;
      .footer-info-item {
        .footer-info-title {
          color: #98a9bb;
        }
        .ikt-ap_input-container {
          width: 120px;
          
          .ikt-ap_input-error-msg {
            max-width: 122px !important;
            padding-left: 0 !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
          }
        }
        .total {
          height: 32px;
          padding-top: 5px !important;
        }
      }
    }
  }
  .material-component-list {
    background-color: #e9eef3;
    margin: 11px;
    height: 180px;
    width: 100%;
    border-radius: 6px;
    overflow: auto;
    .sub-cost-container {
      .material-component-item {
        padding: 5px !important;
        margin-top: 5px;
        .material-component-remove-btn {
          padding: 0px !important;
          margin: 5px;
          span {
            margin: 0px !important;
          }
        }
      }
    }
  }

  .material-input-icon {
    width: 20px;
    height: 20px;
  }
  .material-input-styled > input[value] {
    color: #3d73dd;
  }
  .material-input-styled > input::first-line {
    background-color: rgba(61, 115, 221, 0.1);
  }
}
