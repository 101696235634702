@import "../../../../assets/scss/material-dashboard-pro-react.scss";

.company-details-page {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  margin: 0 auto;
  padding-top: 70px;
  flex: 1;
  .company-details-page-title {
    font-weight: bold;
    text-align: center;
    font-size: 36px;
    color: #505665;
    line-height: 42px;
    margin-bottom: 50px;
  }
  .company-details-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .company-details {
      display: flex;
      flex-direction: column;
      margin: 0 7px;
      .input-title {
        color: #98a9bb;
        font-size: 14px;
        padding-left: 9px;
        padding-bottom: 3px;
      }

      .billing-address-input {
        background-color: white;
      }

      .dropdown-title {
        color: #98a9bb;
        font-size: 14px;
        padding-left: 9px;
        padding-bottom: 3px;
      }

      .input-container {
        min-height: 60px;
      }

      input {
        width: 300px;
      }

      .delivery-options {
        span {
          font-family: "Work Sans" !important;
          color: #98a9bb;
        }

        .MuiFormControlLabel-label {
          color: #505665;
          font-size: 14px;
          line-height: 16px;
        }

        .MuiCheckbox-colorSecondary:hover {
          background-color: rgba(232, 232, 232, 0.8);
        }

        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
        }
      }

      .time-selection {
        display: flex;
        flex-direction: row;

        .time-divider {
          font-size: 35px;
          color: #98a9bb;
          margin-top: 3px;
        }

        .dropdown-style {
          background-color: white !important;
          border-radius: 6px !important;
          display: flex !important;
          padding-right: 10px !important;
          padding-left: 10px !important;
          color: black !important;
          margin-top: 0 !important;
          width: 134px;
          height: 32px;
          box-shadow: none !important;
          justify-content: space-between !important;
          text-transform: none !important;

          span {
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
          }

          b {
            color: #3d73dd;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .company-details-page {
    padding-top: 50px;

    .company-details-page-title {
      margin-bottom: 30px;
    }
  }
}
