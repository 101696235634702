.create-role-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.create-role-content {
  width: 355px;
  height: 347px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  padding-top: 30px;

  .role-info-item {
    display: flex;
    flex-direction: row;


    input {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 10px;
    }

    .role-item {
      display: flex;
      flex-direction: column;
    }

    .role-checkbox-container {
      display: flex;
      flex-direction: row;
      height: 20px;

      label {
        margin-right: 0px !important;
      }
    }

    .role-checkbox {
      height: 20px;
      margin: 3px 0;

      span {
        color: rgba(0, 0, 0, 0.5)
      }
    }

    .stock-dropdown {
      background-color: #0fd280 !important;
      height: 23px !important;
      width: 100% !important;
      padding: 2px 10px !important;
      border-radius: 6px !important;

      span {
        color: #ffffff !important;
      }
    }

    .description-text {
      background-color: white;
      width: 100%;
      padding: 0 !important;
    }
  }
}

.role-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 20px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white;
    color: #3d73dd;
  }

  .create-btn {
    background-color: #0fd280;
    color: white;
  }
}
