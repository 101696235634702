.custom-page-ination {
  display: flex;

  .previous {
    display: flex;
    justify-content: center;
    width: 96px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 6px;
    padding-top: 9px;
    a {
      color: #3d73dd;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }

  }

  .next {
    display: flex;
    justify-content: center;
    width: 96px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 6px;
    padding-top: 9px;
    a {
      color: #3d73dd;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }

  }

  .active {
    a {
      color: #3c4858 !important;
    }
  }

  li {
    display: inline-block;
    cursor: pointer;
    margin-right: 36px;
    border-radius: 5px;
    padding-top: 5px;

    a {
      color: #3d73dd;
      font-size: 14px;
      font-weight: bold;
    }
  }

}
