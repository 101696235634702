.ikt-ap_contacts-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .contact-dropdown-style,
  .contact-dropdown-hierarchical-style {
    background-color: white !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    display: flex !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    color: black !important;
    margin-right: 5px !important;
    width: 170px !important;
    height: 32px;
    justify-content: space-between !important;

    span {
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 100;
      line-height: 16px;
      text-transform: none;
    }

    b {
      color: #3d73dd;
    }
  }

  .contacts-header {
    display: flex;
    margin: 0 39px !important;
    margin-top: 25px !important;
    border-bottom: 2px solid #3d73dd;

    .contacts-header-title {
      padding: 0 !important;
      font-size: 12px;
      font-family: 'Work Sans';
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: left;
      padding-bottom: 4px !important;
    }
    
    .col-sort-active {
      color: #3d73dd !important;

      img { // arrow icon
        display: inline-block;
        margin-left: 2px;
        margin-top: -1px;
        width: 12px;
        height: 6px;
      }
    }

    @media only screen and (max-width: 1500px) {
      ._private-phone {
        display: none;
      }
    }
    @media only screen and (max-width: 1280px) {
      ._private-phone,
      ._salutation,
      ._email {
        display: none;
      }
    }
    ._actions {
      text-align: right;

      position: absolute;
      right: 40px;
    }

    ._select {
      padding-left: 2px !important;

      .MuiCheckbox-root {
        padding: 0 !important;
        margin: -2px 16px 0 9px;
      }
    }
  }

  .contacts-content {
    display: flex;
    margin: 0 39px !important;

    .no_customers {
      display: flex;
      margin: 20px auto 0 auto;
      color: red;
      font-weight: bold;
      font-size: 15px;
    }

    .contacts-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;

      .contact-item-title {
        font-size: 14px;
        font-family: 'Work Sans';
        color: #505665;
        line-height: 14px;
        text-align: left;
        padding-top: 19px !important;
        padding-bottom: 4px !important;
      }

      ._select {
        width: 30px;

        .MuiCheckbox-root {
          padding: 0 !important;
          margin: -2px 0 0 -4px;
        }
      }

      .action-btn-container {
        padding: 19px 0 4px 0 !important;
      }
      .bold {
        font-weight: 600;
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 5px;
      }
      ._private-phone,
      ._mobile-phone,
      ._email {
        word-break: break-all;
      }

      ._actions {
        position: absolute;
        right: 46px;

        text-align: right;
      }
      @media only screen and (max-width: 1500px) {
        ._actions {
          display: flex;
        }
        ._private-phone {
          display: none;
        }
      }
      @media only screen and (max-width: 1280px) {
        ._private-phone,
        ._salutation,
        ._email {
          display: none;
        }
        ._actions {
          display: inline;
        }
      }
    }

    .contacts-item-selected {
      background-color: #d7e6f5 !important;

      /* checkbox */
      svg {
        fill: #4277de !important;
      }
    }

    .contacts-card-item {
      display: flex;
      position: relative;
      color: #505665;
      background-color: white;
      border-radius: 11px;
      flex-direction: column;
      font-family: 'Work Sans';
      line-height: 16px;
      font-size: 14px;
      margin: 10px;
      padding: 20px 19px 0px 19px;
      height: 218px;
      width: 312px;

      .contact-name {
        color: #3c4858;
        font-weight: bold;
      }

      .contact-company-name {
        color: #3c4858;
        margin-top: 10px;
        margin-bottom: 24px;
      }

      .contact-card-info {
        margin-bottom: 8px;
      }

      .contact-card-action-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        bottom: 13px;
        right: 20px;

        button {
          padding: 5px !important;
          margin-left: 5px !important;
        }
      }
    }
    .dark {
      background-color: rgba(180, 198, 217, 0.1);
    }
  }

  .card-content {
    margin: 14px 29px 0px 29px !important;
  }

  .contacts-dropdown-content {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px !important;

    .add-contact-btn,
    .delete-customers-btn,
    .add-contact-btn-dropdown {
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: 'Work Sans' !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }

    .add-contact-btn,
    .add-contact-btn-dropdown {
      background-color: #0fd280 !important;
    }

    .delete-customers-btn {
      background-color: #f84141 !important;
    }

    .select-dropdown-button-child {
      margin: 0;
      width: 145px !important;
    }

    .add-contact-btn-dropdown {
      width: 145px !important;
    }

    .import {
      background-color: #3d73dd !important;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }
}
