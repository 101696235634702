@import '../../assets/scss/material-dashboard-pro-react.scss';

.update-material-category-dialog {

  .update-material-category-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;

    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .update-material-category-content {
    width: 445px;
    height: 275px !important;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding: 0 35px !important;

    .update-material-category-info {
      padding-top: 14px;

      .info-section {
        margin-bottom: 8px;

        .info-title {
          color: #98a9bb;
          font-size: 14px;
          padding-left: 10px;
          padding-bottom: 4px;
          padding-top: 4px;
        }

        .category-dropdown-container {
          button {
            width: 100% !important;
          }
        }
      }
    }
  }

  .update-material-category-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 20px;

    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }

    .cancel-btn {
      background-color: white;
      color: #3d73dd;
    }

    .create-btn {
      background-color: #0fd280;
      color: white;
    }
  }
}
