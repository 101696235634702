.position-content {
  margin: 5px 0;
 .position-info {
   background-color: rgba(61, 115, 221, 0.1);
   display: flex;
   flex-direction: row;
   font-family: "Work Sans";
   justify-content: space-between;
   border-radius: 10px;

   .position-info-container {
     padding: 9px 0 !important;
     width: 100%;
     .position-info-title {
       display: flex;
       justify-content: space-between;
       .position-title {
          font-size: 20px;
          font-weight: bold;
          color: #505665;
          line-height: 24px;
        }

       .position-delete-btn {
         padding: 2px !important;
       }
     }

     .position-info-inputs {
       display: flex;
       flex-direction: row;
       padding: 7px 8px 0 8px;

       .position-input-item {
         display: flex;
         flex-direction: column;
         padding: 0 !important;
         margin-left: 5px;
         width: 100%;

         .types-container {
           display: flex;
           flex-direction: row;
         }

         .position-type-container {
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           min-width: 260px;

            .project-type {
              display: flex;
              cursor: pointer;
              align-items: center;
              background-color: #e9eef3;
              border-radius: 6px;
              padding: 0 15px;
              margin: 0 3px;
              color: #505665;
              height: 32px;
              min-width: 128px;
              text-align: center;
            }

            .selected {
              background-color: #3d73dd;
              color: #ffffff;
            }

           .errors {
             border: 1px solid red;
           }
         }

         .item-title {
           color: #98a9bb;
           padding-left: 10px;
           padding-bottom: 5px;
         }

         input {
           width: 100%;
         }

         button {
           margin: 0;
           width: 100% !important;
           span {
             color: black !important;
           }
         }

         .ikt-ap_input-container {
           padding: 0 3px;
         }
       }

     }

     .position-parts-price {
       display: flex;
       flex-direction: row;
       font-size: 12px;
       color: #333333;
       margin-top: 15px;

       .parts-item {
         display: flex;
         flex-direction: column;

         .parts-price {
           font-size: 14px;
           font-weight: 800;
           color: #505665;
           line-height: 16px;
           padding: 0 3px;
         }
       }
     }
   }

   .card-btn-container {
     display: flex;
     padding: 0px !important;
     justify-content: flex-end;
     .card-btn {
       background-color: rgba(61, 115, 221, 0.1);
       border-radius: 0 10px 10px 0;
       display: flex;
       align-items: center;
       width: 26px !important;
       svg {
         color: #3d73dd;
       }
     }

     .bottom-borders {
       border-bottom-right-radius: 0;
     }
   }
 }
 .bottom-borders {
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
 }

  .sub-cost-center-content {
    background-color: rgba(216, 224, 232, 0.3);
    font-family: "Work Sans";
    padding: 10px 8px;

    .position-tasks {
      .position-info-header {
        font-size: 14px;
        color: #98a9bb;
        line-height: 16px;

        .header-item {
          padding: 0 10px 5px 10px !important;
        }
      }
    }

    .sub-cost-container {
      padding: 0 !important;

      .sub-cost-item {
        padding: 2px 3px !important;

        button {
          margin: 0;
          width: 100% !important;
        }

        .sub-cost-remove-btn {
          padding: 2px !important;
          width: 30px !important;
        }
      }
    }
    .sub-cost-center-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
        .position-btn {
          height: 32px !important;
          padding: 9px 30px !important;
          border-radius: 6px !important;
          font-size: 12px;
          font-family: "Work Sans";
          font-weight: bold;
        }

        .blue {
          background-color: #3d73dd;
          color: white;
        }

        .white {
          background-color: white;
          color: #3d73dd;
        }

        .green {
          background-color: #0fd280;
          color: white;
        }
    }
  }
}
