.ikt-ap_users-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .users-dropdown-content {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px !important;

    .user-dropdown-style {
      background-color: white !important;
      border-radius: 6px !important;
      box-shadow: none !important;
      display: flex !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      color: black !important;
      margin-right: 5px !important;
      width: 150px !important;
      height: 32px;
      justify-content: space-between !important;

      span {
        font-family: 'Work Sans';
        font-size: 14px;
        font-weight: 100;
        line-height: 16px;
        text-transform: none;
      }

      b {
        color: #3d73dd;
      }
    }

    .create-user-btn {
      background-color: #0fd280 !important;
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: 'Work Sans' !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }
  }

  .users-header {
    display: flex;
    margin: 0 39px !important;
    margin-top: 25px !important;
    border-bottom: 2px solid #3d73dd;

    .users-header-title {
      font-size: 12px;
      font-family: 'Work Sans';
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: left;
      padding-bottom: 4px !important;
    }

    @media only screen and (max-width: 1280px) {
      ._phone {
        display: none;
      }
      ._email {
        display: none;
      }
    }

    ._actions {
      text-align: right;
    }
         
    .col-sort-active {
      color: #3d73dd !important;

      img { // arrow icon
        display: inline-block;
        margin-left: 2px;
        margin-top: -1px;
        width: 12px;
        height: 6px;
      }
    }
  }

  .users-content {
    display: flex;
    margin: 0 39px !important;

    .no_users {
      display: flex;
      margin: 20px auto 0 auto;
      color: red;
      font-weight: bold;
      font-size: 15px;
    }

    .users-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;

      .user-item-title {
        font-size: 14px;
        font-family: 'Work Sans';
        color: #505665;
        line-height: 14px;
        text-align: left;
        padding-top: 19px !important;
        padding-bottom: 4px !important;

        .stock-dropdown {
          background-color: #0fd280 !important;
          height: 23px !important;
          padding: 2px 10px !important;
          border-radius: 6px !important;

          span {
            color: #ffffff !important;
          }
        }

        .inactive_user {
          background-color: red !important;
        }
      }

      ._email {
        word-break: break-all;
      }

      @media only screen and (max-width: 1450px) {
        ._status-dropdown {
          max-width: 120px;
        }
      }
      @media only screen and (max-width: 1280px) {
        ._phone {
          display: none;
        }
        ._email {
          display: none;
        }
      }
      @media only screen and (max-width: 1250px) {
        // ._status-dropdown {
        //   max-width: 85px;
        // }
      }
      @media only screen and (max-width: 1150px) {
        .status {
          justify-content: center !important;
        }
      }
      @media only screen and (max-width: 1100px) {
        ._actions {
          display: flex;
        }
      }
      @media only screen and (max-width: 959px) {
        ._actions {
          text-align: right;
          display: inline;
        }
      }
      @media only screen and (max-width: 850px) {
        ._actions {
          margin: 0 auto;
          padding: 19px 0px !important;
        }
      }
      ._actions {
        text-align: right;
        padding-right: 5px !important;
      }
      .bold {
        font-weight: 600;
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 5px;
      }

      .status {
        display: flex;
        justify-content: left;
        margin-top: -10px;
      }
    }

    .users-card-item {
      display: flex;
      position: relative;
      color: #3c4858;
      background-color: white;
      border-radius: 11px;
      flex-direction: column;
      font-family: 'Work Sans';
      line-height: 16px;
      font-size: 14px;
      margin: 10px;
      padding: 20px 19px 0px 19px;
      height: 218px;
      width: 312px;

      .stock-dropdown {
        background-color: #0fd280 !important;
        height: 23px !important;
        padding: 2px 10px !important;
        border-radius: 6px !important;

        span {
          color: #ffffff !important;
        }
      }

      .inactive_user {
        background-color: red !important;
      }

      .bold {
        font-weight: bold;
      }

      .user-status-actions {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 25px;
        position: absolute;
        bottom: 12px;
      }

      .user-name {
        padding: 20px 0;
      }

      .user-email {
      }

      .user-phone-number {
        margin-top: 8px;
      }

      .user-status {
        margin-top: 2px;
      }

      .user-actions {
        margin-left: 18px;

        button {
          padding: 7px !important;
        }
      }

      .user-id-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .contact-name {
        color: #3c4858;
        font-weight: bold;
      }

      .contact-company-name {
        color: #3c4858;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .contact-card-info {
        margin-bottom: 6px;
      }

      .contact-card-action-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }

    .dark {
      background-color: rgba(180, 198, 217, 0.1);
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }
}
