.create-task-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .info-btn {
    color: gray;
    padding: 0px !important;
    margin-right: 10px;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.create-task-content {
  width: 654px;
  height: 530px;
  padding: 10px 0;
  background-color: #f2f5f8;
  font-family: "Work Sans" !important;
  font-size: 14px;
  color: #98a9bb;

  .task-loader {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    width: 100%;
  }

  .ikt-ap_custom-dropdown .MuiButton-label {
    padding-left: 0 !important;
  }

  /*.metrics-content-item .ikt-ap_custom-dropdown .ikt-ap_custom-dropdown-menu {
    max-width: 100%;
  }*/

  .metrics-content-item .ikt-ap_custom-dropdown .MuiButton-label {
    font-size: 10px;
  }

  .task-content-item {
    display: flex;
    flex-direction: column;
    padding: 0 5px !important;
    margin: 2px 0;
    width: 100%;

    span {
      padding-left: 10px;
    }

    input {
      width: 100%;
    }

    .MuiButton-label {
      padding-left: 0 !important;
    }

    button {
      margin: 0 !important;
      width: 100% !important;
    }
  }

  .amount {
    padding-right: 2px !important;

    max-width: 90px;
  }

  .metrics {
    padding-left: 2px !important;
    padding-right: 10px !important;

    max-width: 90px;
  }

  .task-content-top {
    display: flex;
    flex-direction: column;
    padding: 0 30px !important;

    .task-name {

    }

    .task-assignee-deadline {
      display: flex;
      flex-direction: row;
      padding: 0 !important;
    }

    .task-description {
      padding: 0 6px !important;

      .description-area {
        span {
          padding-left: 10px;
        }
        .description-test-area {
          height: 105px;
          width: 100%;
          background-color: white;
          border-radius: 6px;
        }
      }
    }

    .task-material-amount-dimensions {
      display: flex;
      flex-direction: row;
      padding: 0!important;

      .material {
        max-width: 185px !important;
      }

      .dimensions {
        min-width: 224px !important;
        padding: 0 !important;
      }

      .dimensions-inputs {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .edges-l {
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
        .edges-r {
          border-radius: 0 !important;
        }
        .edges-b {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0 !important;
        }

        .edges-input-icon {
          margin-right: 5px;
        }

        .last {
          margin-right: 0 !important;
        }
      }
    }

    /*.task-content-top-assignee {
      display: flex;
      flex-direction: row;
      padding: 0 !important;
    }

    .task-content-top-left {
      padding: 0 !important;
    }

    .task-content-top-materials {
      padding: 0 5px !important;

      .description-area {
        margin-top: 10px;
        span {
          padding-left: 10px;
        }
        .description-test-area {
          height: 105px;
          width: 100%;
          background-color: white;
          border-radius: 6px;
        }
      }

      .dimensions {
        margin-top: 25px;
        padding: 0 !important;
      }

      .dimensions-inputs {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .edges-l {
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0px !important;
          margin-right: 1px;
        }
        .edges-r {
          border-radius: 0px !important;
          margin-right: 1px;
        }
        .edges-b {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0px !important;
          margin-right: 1px;
        }

        .edges-input-icon {
          margin-right: 5px;
        }

        .last {
          margin-right: 0 !important;
        }
      }

    }
    .task-assignee-content {
      display: flex;
      flex-direction: row;
      padding: 0 !important;
    }*/
  }

  .task-content-middle {
    display: flex;
    flex-direction: row;
    padding: 0 27px !important;
    margin-top: 10px;

    .task-content-middle-left {
      padding: 0 3px !important;
    }

    .edges-inputs {
      display: flex;
      flex-direction: row;
      padding: 0 !important;

      .edges-l {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        width: 122px;
        margin-right: 3px;
      }
      .edges-r {
        border-radius: 0 !important;
        margin-left: 3px;
        margin-right: 3px;
      }
      .edges-r1 {
        width: 122px;
      }
      .edges-r2 {
        width: 123px;
      }
      .edges-b {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        width: 124px;
        margin-left: 3px;
      }

      .edges-autocomplete:last-of-type {
        margin-right: 3px;
      }

      .edges-input-icon {
        margin-right: 5px;
      }

      .last {
        margin-right: 0 !important;
      }

      .edges-dropdowns {
        margin-right: 1px !important;
        padding-right: 7px !important;
        padding-left: 0 !important;

        width: 125px !important;
      }

      .ikt-ap_custom-dropdown .MuiList-root {
        max-height: 200px !important;
      }
    }

    .edges-structure-img {
      padding: 0 !important;
      margin: 24px 8px 0 5px;
    }
  }

  .task-content-bottom {
    display: flex;
    flex-direction: row;
    padding: 0 50px 0 30px !important;
    margin-top: 10px;

    .task-content-bottom-left {
      padding: 0 !important;
    }

    .task-content-bottom-right {
      padding: 0 !important;
    }

    .surface-coverings-inputs {
      display: flex;
      flex-direction: row;
      padding: 0 !important;

      .surface-left {
        margin-right: 3px !important;
      }

      .surface-right {
        margin-left: 3px !important;
      }

      .coverings-left {
        /*margin-right: 6px !important;*/
      }

      .sur-coverings-t {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        margin-right: 6px !important;
      }

      .sur-coverings-b {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }

      .sur-coverings-input-icon {
        margin-right: 5px;
      }

      .last {
        margin-right: 0 !important;
      }

      .surface-dropdown {
        margin-right: 1px !important;
        padding-right: 7px !important;
        padding-left: 0 !important;
        width: 125px !important;
      }

      .ikt-ap_custom-dropdown .MuiList-root {
        max-height: 100px !important;
      }
    }
    .surface-inputs {
      margin-bottom: 5px;
    }


  }

  .task-costs {
    display: flex;
    flex-direction: row;
    padding: 0 31px !important;
  }

  .surface-item {
    padding-left: 5px !important;
    height: 100%;

    .MuiList-root {
      max-height: 160px !important;
    }
  }

  .coverings-item {
    padding: 0 5px 0 0 !important;

    .MuiList-root {
      max-height: 160px !important;
    }
  }

}

.coverings-dropdowns {
  width: 100%;
}

.create-task-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 34px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
    width: 96px;
  }

  .create-btn {
    background-color: #0fd280 !important;
    color: white;
    width: 152px;
  }
}
