@import '../../assets/scss/material-dashboard-pro-react.scss';

.create-fitting-dialog {

  .create-fitting-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;

    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .create-fitting-content {
    width: 550px;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding: 25px 35px !important;

    .fitting-row {
      margin-bottom: 15px;

      .fitting-item {
        .info-title {
          padding-left: 5px;
        }

        .ikt-ap_custom-dropdown {
          margin-top: -5px !important;

          .ikt-ap_custom-dropdown-toggle-btn {
            max-width: 95% !important;
            min-width: 95% !important;
          }
        }
      } 
    }

    .three-col {
      display: flex;
      flex-direction: row;

      .item-amount {
        width: 35% !important;
        margin-right: 5px;
      }

      .item-metrics {
        width: 29% !important;

        .ikt-ap_custom-dropdown-menu {
          margin-left: 3% !important;
          max-width: 94% !important;
          min-width: 94% !important;
        }
      }

      .item-price {
        width: 35% !important;
      }
    }

    .suppliers-container {
      span {
        display: block;
      }

      textarea {
        height: 60px !important;
      }
    }

    textarea {
      display: block;
      font-family: "Work Sans";
      margin-top: 5px;
      margin-right: 0 !important;
      padding: 10px;
      width: 100%;
      height: 102px !important;
      border-radius: 15px !important;
      border: 0 !important;
      outline: none !important;
      resize: none;
    }
  }

  .fitting-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 20px;

    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }

    .cancel-btn {
      background-color: white;
      color: #3d73dd;
    }

    .create-btn {
      background-color: #0fd280;
      color: white;
    }
  }
}
