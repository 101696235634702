@import "../../../../assets/scss/material-dashboard-pro-react.scss";

.welcome-page {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  margin: 0 auto;
  padding-top: 70px;
  flex: 1;

  .welcome-page-title {
    font-weight: bold;
    text-align: center;
    font-size: 36px;
    color: #505665;
    line-height: 42px;
  }

  .welcome-text {
    text-align: center;
    max-width: 720px;
    margin-top: 30px;
    margin-bottom: 50px;

    span {
      font-size: 14px;
      color: #505665;
      line-height: 20px;

      a {
        color: #497de3;
      }
    }
  }

  .register-input-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    
    .input-container-inner {
      min-height: 60px;
    }

    .input-container-title{
      color: #98a9bb;
      font-size: 14px;
      padding-left: 9px;
      padding-bottom: 3px;
    }
    input {
      width: 300px;
    }
  }
}

@media (max-width: $screen-md-min) {
  .welcome-page {
    padding-top: 35px !important;
  
    .welcome-text {
      margin-top: 26px !important;
      margin-bottom: 30px !important;
    }
  
    .register-input-container {
      input {
        margin-bottom: 20px !important;
      }
    }
  }
}
