.logo-container-add {
    padding-top: 10px !important;
    padding-bottom: 8px !important;
    min-height: 86px;
}

/*.logo-container-add-mini {

}*/

.img-anchor img {
    width: 108px;
    height: 56px;
}

.img-anchor-mini {
    margin-right: 5px !important;
}

.img-anchor-mini img {
    width: 64px;
    height: 34px;
}