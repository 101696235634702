.material-list-positions-container {
  display: flex;
  flex-direction: column;
  padding: 0 12px !important;
  width: 100%;
  margin-top: -20px !important;

  .material-list-positions-header {
    text-align: right;

    .btn-dropdown,
    .create-btn,
    .delete-btn {
      border-radius:  6px;
      color: white;
      font-size: 12px;
      font-family: "Work Sans" !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
    }

    .btn-dropdown {
      width: 140px;
      padding: 0 15px !important;
      background-color: #3d73dd !important;
    }

    .dropdown-button-menu-outer-container {
      text-align: center !important;
    }

    .create-btn {
      margin: 0;
      width: 140px;
      background-color: #3d73dd !important;
    }

    .delete-btn {
      margin: 0;
      width: 140px;
      background-color: #f84141 !important;
    }
  }

  .material-list-positions-content {
    
  }

  .list-space-holder {
    display: block;
    width: 1px !important;
    height: 66px !important;
  }

  .material-list-add-buttons {
    padding: 5px 12px !important;
    text-align: right;
    background-color: #c9d8ef;
    border-radius: 10px;

    .material-list-add-position-btn,
    .material-list-add-article-btn {
      background-color: #3d73dd !important;
      border-radius:  6px;
      color: white;
      font-size: 12px;
      font-family: "Work Sans" !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 15px !important;
      margin-left: 7px;
    }

    .material-list-add-position-btn {
      width: 150px;
    }

    .material-list-add-article-btn {
      width: 125px;
    }
  }
}


