.calendar-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.week-days-container {
  color: #98a9bb;
  display: flex;
  font-size: 14px;

  span {
    min-width: 154px;
    text-align: center;
  }
}

.week-container {
  display: flex;
}

.day-container {
  align-items: center;
  background-color: #eeeeee;
  border: 1px solid #d5dae6;
  color: #adabab;
  display: flex;
  position: relative;
  width: 154px;
  height: 79px;
}

.date {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 4px;
  left: 4px;
}

.available-day {
  background-color: #d4ffed;
  color: #0fd280;
}

.unavailable-day {
  background-color: #ffeded;
  color: #f84141;
}

.available-day-icon {
  margin: auto;
  border-radius: 50%;
  background-color: #0fd280;
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
}

.unavailable-day-icon {
  margin: auto;
  border-radius: 50%;
  background-color: #f84141;
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
}

.day-off {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  justify-content: center;
}

.check-icon {
  fill: white !important;
  height: 31px !important;
}

.clear-icon {
  fill: white !important;
  height: 31px !important;
}

