.contacts-import-dialog {
  min-width: 850px;
}

.contact-file-import-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.contact-file-import-content {
  padding: 30px 26px 0 26px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  color: #505665;
  font-weight: bold;
  font-size: 14px;
  position: relative;

  .file-import-text {
    margin-top: 30px;
    margin-left: 9px
  }

  .contact-import-checkbox {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .checkbox-label {
      margin-top: 7px;
    }

    .MuiFormControlLabel-label {
      color: #505665;
      font-size: 14px;
      line-height: 16px;
    }

    .MuiCheckbox-colorSecondary {
      color: #3d73dd !important;
    }

    .MuiCheckbox-colorSecondary:hover {
      background-color: rgba(232, 232, 232, 0.8);
    }

    .MuiSvgIcon-root {
      fill: #3d73dd;
      width: 16px;
      height: 16px;
    }
  }

  .file-fields-title-container {
    width: 800px;
    .fields-title-container-scrollbar {
      display: flex;
      flex-direction: row;
      position: relative;
      .fields-title-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-left: 2px;
      }
    }

    .contacts-table-container {
      margin-top: 22px;
      .MuiPaper-elevation1 {
        box-shadow: none;
      }

      .MuiPaper-elevation1 {
        background-color: #f2f5f8;
      }

      .MuiTableContainer-root {
        overflow-x: hidden !important;
        padding-bottom: 175px;
      }


      td {
        min-width: 230px;
        padding-right: 22px !important;
        border-bottom: none;
        align-items: center;
        display: flex;
      }

      .table-data-row-item {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }

      .MuiTableRow-root {
        display: flex;
        height: 42px;
      }
    }
  }

  .import-loader {
    /*position: absolute;*/
    margin: 0 auto 30px auto;
  }
}

.contact-file-import-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 34px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
  }

  .create-btn {
    background-color: #0fd280 !important;
    color: white;
  }
}
