.view-notification-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.view-notification-content {
  display: flex;
  flex-direction: column;
  width: 595px;
  height: 440px;
  background-color: #f2f5f8;
  font-family: "Work Sans";
  padding: 20px 35px ;

  .notification-title-container {
    display: flex;
    flex-direction: row;

    .notification-name {
      font-size: 20px !important;
      font-weight: 600 !important;
      color: #505665;
      margin: 20px 0;
    }

    .view-notification-btn {
      display: flex;
      justify-content: space-around;
      min-height: 66px;

      button {
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: bold;
        line-height: 14px;
        height: 32px;
        width: 122px;
        padding: 0 10px;
        margin: auto 0;
      }

      .cancel-btn {
        background-color: white;
        color: #3d73dd;
      }

      .create-btn {
        background-color: #3d73dd;
        color: white;
      }
    }
  }

  .notification-data-container {
    display: flex;
    flex-direction: column;
    .notification-info-field {
      padding: 12px  0;
      .notification-info-item {
        display: flex;
        flex-direction: row;
        font-family: "Work Sans";
        color: #505665;
        margin-bottom: 5px;
        font-size: 14px;

        .notification-info-title {
          color: #98a9bb;
        }
      }
    }
  }
}
