.view-btn {
  display: flex;
  width: 64px;
  height: 32px;
  border-radius: 6px !important;
  background-color: white;
  margin: 5px 5px;

  .table-view {
    padding: 5px 7px;
  }

  .card-view {
    padding: 5px 8px;
  }

  .dark-table {
    background-color: #c6d7e9;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
  }

  .dark-card {
    background-color: #c6d7e9;
    border-bottom-right-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}
