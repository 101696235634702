.view-user-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.view-user-content {
  display: flex;
  flex-direction: column;
  width: 625px;
  height: 427px;
  background-color: #f2f5f8;
  font-family: "Work Sans";
  padding: 20px 20px ;

  .user-title-container {
    display: flex;
    flex-direction: row;

    .user-name {
      font-size: 20px !important;
      font-weight: 600 !important;
      color: #505665;
      margin: 13px 0;
    }

    .view-user-btn {
      display: flex;
      min-height: 66px;
      justify-content: space-between;

      button {
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: bold;
        line-height: 14px;
        height: 32px;
        width: 122px;
        padding: 0 10px;
        margin: auto 0;
      }

      .cancel-btn {
        background-color: white;
        color: #3d73dd;
      }

      .create-btn {
        background-color: #3d73dd;
        color: white;
      }

      .reset-btn {
        background-color: white;
        color: #3d73dd;
        width: 176px !important;
      }
    }
  }

  .user-data-container {
    display: flex;
    flex-direction: column;
    .user-info-field {
      padding: 12px  0;
      .user-info-item {
        display: flex;
        flex-direction: row;
        font-family: "Work Sans";
        color: #505665;
        font-size: 14px;

        .user-info-title {
          color: #98a9bb;
        }
      }
    }
  }
}

.reset-password-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: 'Work Sans' !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.reset-password-content {
  width: 377px;
  height: 235px;
  padding: 30px 26px;
  background-color: #f2f5f8;
  color: #505665;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Work Sans';

  .check-mark {
    margin-top: 45px;
    margin-bottom: 35px;
  }
}

.reset-password-btn-container {
  background-color: #f2f5f8;
  display: flex;
  justify-content: center;
  min-height: 66px;
  padding: 0 34px 20px 34px;

  button {
    font-size: 12px;
    font-family: 'Work Sans';
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
  }

  .create-btn {
    background-color: #f84141 !important;
    color: white;
  }
}
