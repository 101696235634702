.project-details-wrapper-container {
  display: flex;
  flex-direction: row;

  .project-details-left-content{
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .project-details-left-top {
      padding: 0 7px !important;
      display: flex;
      flex-direction: row;
    }
  }

  .project-details-right-content {
    padding: 0 12px 0 0 !important;
  }

  .inquiry-details-files-content {
    padding: 0 !important;
  }

  .inquiry-file-upload {
    padding: 0 !important;

    .inquiry-file-upload-title {
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 10px;
      margin-top:10px;
      margin-bottom: 7px;
    }
  }

  .project-details-description-content {
    padding: 0 6px !important;
  }

  .project-details-files-content {
    padding: 0 6px !important;
  }

  .project-details-item{
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    margin-top: 10px;
    padding: 0 6px !important;

    .order-dropdown {
      margin: 0 !important;
      width: 100% !important;
    }

    .customer-autocomplete {
      fieldset {
        border-radius: 0;
      }
    }

    input {
      width: 100%;
      font-family: "Work Sans";
    }

    .project-details-item-title {
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 10px;
      margin-top:10px;
      margin-bottom: 7px;
    }

    .top-margin {
      margin-top: 18px !important;
    }

    .project-type-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .project-type {
        display: flex;
        cursor: pointer;
        align-items: center;
        background-color: #e9eef3;
        border-radius: 6px;
        padding: 0 15px;
        margin-right: 8px;
        color: #505665;
        height: 32px;
        min-width: 128px;
        text-align: center;
      }

      .selected {
        background-color: #3d73dd;
        color: #ffffff;
      }
    }
  }

  .description-test-area {
    height: 335px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
  }

  .project-files-items {
    background-color: #e9eef3;
    border-radius: 6px;
    height: 328px;
    width: 100%;
    margin-bottom: 24px;
  .upload-item-list-container {
      z-index: 1;
      width: 100%;
      height: 230px;
      margin-top: -230px;
      overflow: auto;
    }
    .project-order-uploaded-file {
      position: relative;
      z-index: 1;
      background-color: #ffffff;
      border-radius: 6px;
      padding: 12px !important;
      margin: 3px 10px;
      display: flex;
      justify-content: space-between;

      .file-info {
        color: #505665;
        display: flex;
        flex-direction: column;

        .order-file-name {
          font-weight: bold;
          margin-bottom: 4px;
        }
      }
    }
  }

  .inquiry-files-items {
    background-color: #e9eef3;
    border-radius: 6px;
    height: 328px;
    width: 100%;
    margin-bottom: 24px;
    .upload-item-list-container {
      z-index: 1;
      width: 100%;
      height: 230px;
      margin-top: -230px;
      overflow: auto;
    }
    .project-order-uploaded-file {
      position: relative;
      z-index: 1;
      background-color: #ffffff;
      border-radius: 6px;
      padding: 12px !important;
      margin: 3px 10px;
      display: flex;
      justify-content: space-between;

      .file-info {
        color: #505665;
        display: flex;
        flex-direction: column;

        .order-file-name {
          font-weight: bold;
          margin-bottom: 4px;
        }
      }
    }
  }
}
