.ikt-ap_categories-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .categories-dropdown-content {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px !important;

    .category-dropdown-style {
      background-color: white !important;
      border-radius: 6px !important;
      box-shadow: none !important;
      display: flex !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      color: black !important;
      margin-right: 5px !important;
      width: 150px !important;
      height: 32px;
      justify-content: space-between !important;

      span {
        font-family: 'Work Sans';
        font-size: 14px;
        font-weight: 100;
        line-height: 16px;
        text-transform: none;
      }

      b {
        color: #3d73dd;
      }
    }

    .create-category-btn {
      background-color: #0fd280 !important;
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: 'Work Sans' !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }
  }

  .categories-header {
    display: flex;
    margin: 0 30px !important;
    margin-top: 25px !important;
    padding: 0 10px;
    border-bottom: 2px solid #3d73dd;

    .categories-header-title {
      font-size: 12px;
      font-family: 'Work Sans';
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: left;
      padding-bottom: 4px !important;
    }

    .name-header-title {
      padding-left: 35px !important;
    }

    ._actions {
      text-align: right;
      justify-content: flex-end;
    }
  }

  .categories-content {
    display: flex;
    margin: 0 30px !important;

    .categories-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;

      .category-item-title {
        font-size: 14px;
        font-family: 'Work Sans';
        color: #505665;
        line-height: 14px;
        text-align: left;
        padding-top: 19px !important;
        padding-bottom: 4px !important;
      }
      ._actions {
        text-align: right;
      }
      @media only screen and (max-width: 1100px) {
        ._actions {
          display: flex;
          justify-content: flex-end;
        }
      }
      @media only screen and (max-width: 959px) {
        ._actions {
          text-align: right;
          display: inline;
        }
      }
      ._description {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .name-l0-padding {
        padding-left: 15px !important;
      }

      .name-l1-padding {
        padding-left: 43px !important;
      }

      .name-l2-padding {
        padding-left: 44px !important;
      }

      .name-l3-padding {
        padding-left: 67px !important;
      }

      .bold {
        font-weight: 600;
      }

      .bullet-icon {
        max-width: 12px;
        max-height: 12px;
        margin: -5px 11px 0 5px;
      }

      .category-name-btn {
        color: gray;
        padding: 0px !important;
        margin: -5px 7px 0 -1px;

        img {
          max-width: 22px;
          max-height: 22px;
        }
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: -10px 5px 0 5px;
      }
    }

    .categories-card-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #505665;
      background-color: white;
      border-radius: 11px;
      flex-direction: column;
      font-family: 'Work Sans';
      line-height: 16px;
      font-size: 14px;
      margin: 10px;
      padding: 20px 19px 0px 19px;
      height: 218px;
      width: 312px;

      .contact-name {
        color: #3c4858;
        font-weight: bold;
      }

      .contact-company-name {
        color: #3c4858;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .contact-card-info {
        margin-bottom: 6px;
      }

      .categories-card-action-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .dark {
      background-color: rgba(180, 198, 217, 0.1);
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }

  .no_data {
    display: flex;
    margin: 20px auto 0 auto;
    color: red;
    font-weight: bold;
    font-size: 15px;
  }
}
