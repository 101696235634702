@import "../../assets/scss/material-dashboard-pro-react.scss";

.ikt-ap_materials-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .materials-dropdown-content-inner {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
  }

  .materials-dropdown-content {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px !important;
    flex-wrap: wrap;

    .material-dropdown-style {
      background-color: white !important;
      border-radius: 10px !important;
      box-shadow: none !important;
      display: flex !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      color: black !important;
      margin-right: 5px !important;
      width: 150px !important;
      height: 32px;
      justify-content: space-between !important;

      span {
        font-family: "Work Sans";
        font-size: 14px;
        font-weight: 100;
        line-height: 16px;
        text-transform: none;
      }

      b {
        color: #3d73dd;
      }
    }

    .create-material-btn,
    .delete-material-btn,
    .create-material-btn-dropdown,
    .select-material-btn-dropdown {
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: "Work Sans" !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }

    .create-material-btn,
    .create-material-btn-dropdown,
    .select-material-btn-dropdown {
      background-color: #0fd280 !important;
    }

    .delete-material-btn {
      background-color: #f84141 !important;
    }

    .create-dropdown-button-child {
      margin: 0;
      width: 170px !important;
    }

    .create-material-btn-dropdown {
      width: 170px !important;
    }

    .select-dropdown-button-child {
      margin: 0;
      width: 155px !important;
    }

    .select-material-btn-dropdown {
      width: 155px !important;
    }

    .import-btn {
      background-color: #3d73dd !important;
    }

    .update-category-button {
      width: 140px !important;
    }
  }

  .materials-header {
    display: flex;
    width: 100%;
    position: relative;
    margin: 0 39px !important;
    margin-top: 25px !important;
    padding: 0 10px;
    border-bottom: 2px solid #3d73dd;

    .materials-header-title {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: center;
      padding-bottom: 4px !important;
      padding-left: 8px !important;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mat-grid-col-category {
      padding-left: 30px !important;

      .MuiCheckbox-root {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    .mat-grid-col-category-selected-all {
      .MuiCheckbox-root {
        svg {
          fill: #4277de !important;
        }
      }
    }

    .mat-grid-col-brand {
      padding-left: 12px !important;
    }

    .mat-grid-col-supplier {
      padding-left: 16px !important;
    }

    .col-sort-active {
      color: #3d73dd !important;
      
      img { // arrow icon
        display: inline-block;
        margin-left: 2px;
        margin-top: -1px;
        width: 12px;
        height: 6px;
      }
    }

  }

  .materials-content {
    width: 100%;
    display: flex;
    margin: 0 39px !important;
    padding: 0 !important;

    .material-item {
      width: 100%;
      position: relative;
      background-color: rgba(180, 198, 217, 0.1);
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      min-height: 86px;
      max-height: 86px;

      .material-item-title {
        font-size: 14px;
        font-family: "Work Sans";
        font-weight: normal;
        color: #333333;
        line-height: 14px;
        text-align: center;
        padding-top: 15px !important;
        padding-bottom: 4px !important;

        .compound-material-category-name {
          font-weight: bold;
          color: #3d73dd;
        }

        .status-dropdown {
          margin-top: -3px !important;
          padding: 0 !important;
          color: white;
        }

        .brands-item,
        .suppliers-item {
          display: block;
          padding: 0;
          margin: 7px 0;
        }
      }

      .bold {
        font-weight: 700;
      }

      .mat-grid-col-category {
        padding-left: 40px !important;
      }

      .mat-grid-col-sku {
        padding-top: 15px !important;
      }

      .mat-grid-col-brand,
      .mat-grid-col-supplier {
        max-height: calc(100% - 4px);
        overflow-y: hidden;
        margin-bottom: 4px !important;
        padding-bottom: 0 !important;
      }

      .mat-grid-col-supplier {
        padding-top: 6px !important;
      }

      .mat-grid-col-brand {
        padding-top: 8px !important;
      }

      .mat-grid-col-actions {
        padding-top: 14px !important;
        padding-left: 3px !important;
        padding-right: 8px !important;
      }

      .availability-dropdown {
        padding-left: 5px !important;

        .status-title {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          color: white;
          font-family: "Work Sans";
          padding-left: 10px;
        }

        button {
          height: 23px;
          width: 130px !important;
          padding: 0 5px !important;
          border-radius: 5px !important;
        }
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 5px;
        min-width: 23px;
        max-width: 26px;
        width: 15%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .material-item-selected {
      background-color: #d7e6f5;

      .MuiCheckbox-root {
        svg {
          fill: #4277de !important;
        }
      }
    }
  }

  .material-card-view {
    margin-top: 10px;
    padding: 0 29px !important;

    .material-item-card {
      position: relative;
      display: flex;
      background-color: white;
      border-radius: 11px;
      flex-direction: row;
      font-family: "Work Sans";
      font-size: 14px;
      justify-content: space-between;
      margin: 10px;
      padding: 8px 18px;
      height: 188px;
      width: 269px;

      .material-item {
        padding: 7px 0 7px 0 !important;
      }

      .bold {
        font-weight: 600;
      }

      .material-item-left {
        width: 50%;
        display: flex;
        flex-direction: column;

        .category {
          padding-top: 8px !important;
        }

        .name {
          padding-top: 3px !important;
        }

        .brand {
          padding-top: 2px !important;
        }

        .supplier {
          padding-top: 2px !important;
          max-height: 50px;
          overflow: hidden;

          span {
            display: block;
            font-weight: 600;
            padding: 0 !important;
            margin: 0 !important;
            white-space: nowrap;
          }
        }

        .card-availability-dropdown {
          position: absolute;
          bottom: 9px;

          .status-dropdown {
            margin-top: -3px !important;
            padding: 0 0 0 5px !important;
            color: white;
          }

          .status-title {
            display: block;
            width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          span {
            color: white;
            font-family: "Work Sans";
          }

          button {
            height: 23px;
            width: 130px !important;
            padding: 0 5px !important;
            border-radius: 5px !important;
          }

        }
      }

      .material-item-right {
        width: 50%;
        display: flex;
        color: #3c4858;
        flex-direction: column;

        .id {
          padding-top: 8px !important;
          text-align: right;
        }

        .price {
          display: flex;
          color: #3c4858;
          font-weight: bold;
          justify-content: flex-end;
          padding: 3px 0 7px 0 !important;
        }

        .action-icons {
          display: flex;
          position: absolute;
          right: 14px;
          bottom: 14px;

          min-width: 120px;
          max-width: 120px;

          .duplicate-action-btn {
            margin-left: auto;
          }
          .view-action-btn {
            margin-right: 15px;
          }
          button {
            padding: 2px !important;

            img {
              width: 22px;
              height: 22px;
            }
          }

          transform: translateX(16px);
        }
      }
    }
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  .no_materials {
    display: flex;
    margin: 20px auto 0 auto;
    color: red;
    font-weight: bold;
    font-size: 15px;
  }
}

.mat-grid-col-sku {
  min-width: 14%;
  max-width: 14%;

  text-align: left !important;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-grid-col-name {
  min-width: 9%;
  max-width: 9%;

  text-align: left !important;
}

.mat-grid-col-category {
  min-width: 11%;
  max-width: 11%;

  text-align: left !important;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .MuiCheckbox-root {
    padding: 0 4px 2px 4px !important;
    margin: 0 0 0 -32px;
  }

  .menu-button-icon {
    padding: 4px !important;
    margin: -4px 2px 0 -2px;
    max-width: 31px;
    max-height: 31px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.mat-grid-col-category-compound {
  text-overflow: clip !important;

  img {
    width: 14px;
    height: 7px;
  }
}

.mat-grid-col-brand {
  min-width: 14.5%;
  max-width: 14.5%;

  text-align: left !important;
}

.mat-grid-col-price {
  min-width: 9%;
  max-width: 9%;

  text-align: right !important;
}

.mat-grid-col-availability {
  min-width: 150px;
  max-width: 150px;

  text-align: left !important;
}

.mat-grid-col-supplier {
  min-width: 15.5%;
  max-width: 15.5%;

  text-align: left !important;
}

.mat-grid-col-actions {
  min-width: 110px;
  width: 12%;
  max-width: 12%;

  position: absolute;
  right: 0;

  text-align: right !important;
}
@media (max-width: 1550px) {
  .mat-grid-col-actions {
    min-width: 15%;
    max-width: 15%;
  }
}
@media (max-width: 1350px) {
  .mat-grid-col-brand,
  .mat-grid-col-supplier {
    display: none !important;
  }
  .mat-grid-col-category {
    min-width: 20%;
    max-width: 20%;
  }
  .mat-grid-col-name {
    min-width: 15%;
    max-width: 15%;
  }
  .mat-grid-col-price {
    min-width: 12%;
    max-width: 12%;
  }
  .mat-grid-col-actions {
    min-width: 20%;
    max-width: 20%;
  }
}
@media (max-width: 1100px) {
  .mat-grid-col-sku {
      display: none;
  }
  .mat-grid-col-category {
    min-width: 25%;
    max-width: 25%;
  }
  .mat-grid-col-name {
    min-width: 22%;
    max-width: 22%;
  }
  .mat-grid-col-actions {
    min-width: 22%;
    max-width: 22%;
  }
}

@media (max-width: $screen-md-min) {
  .material-item-title {
    font-size: 12px !important;
  }

  .mat-grid-col-sku {
    min-width: 16.5%;
    max-width: 16.5%;

    .type-icon {
      margin-right: 2px !important;
    }
  }

  .mat-grid-col-name {
    min-width: 14.5%;
    max-width: 14.5%;
  }

  .mat-grid-col-category {
    min-width: 15%;
    max-width: 15%;
  }

  .mat-grid-col-price {
    min-width: 16%;
    max-width: 16%;
  }

  .mat-grid-col-availability {
    .ikt-ap_custom-dropdown-menu {
      min-width: 130px !important;
      max-width: 130px !important;
    }
  }
}

.dropdown-green {
  button {
    background-color: #0fd280 !important;
  }
  .CircularProgressbar-path {
    stroke: #0fd280 !important;
  }
}

.dropdown-red {
  button {
    background-color: #f84141 !important;
  }
  .CircularProgressbar-path {
    stroke: #f84141 !important;
  }
}
