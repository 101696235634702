.create-user-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.create-user-content {
  width: 653px;
  height: 570px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  padding: 30px 20px 0 20px;

  .user-info-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    input {
      width: 100%;
      margin-bottom: 20px;
    }

    .input-title {
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 10px;
    }

    .input-with-desc {
      position: relative;
      margin-bottom: 10px;

      .input-desc-text {
        position: absolute;
        bottom: 0;
        left: 5px;
        font-size: 10px;
      }
    }

    .long-error { 
      margin-bottom: 30px !important;
    }
    .ikt-ap_input-error-msg {
      width: 100%;
      line-height: 1;
    }
    .stock-dropdown {
      background-color: #0fd280 !important;
      height: 23px !important;
      padding: 2px 10px !important;
      border-radius: 6px !important;

      span {
        color: #ffffff !important;
        margin-left: 0px;
      }
    }
    .user-status {
      width: 100%;
      height: 32px;
    }
    .inactive_user {
      background-color: red !important;
    }
    .user-status-dropdown {
      width: 100% !important;
      height: 32px !important;
      margin: 0 !important;
    }
    .ikt-ap_custom-dropdown {
      width: 100% !important;
      padding-left: 0 !important;

      .ikt-ap_custom-dropdown-toggle-btn {
        width: 100% !important;
      }
    }
  }

  .user-info {
    display: flex !important;
    flex-direction: column !important;
    height: 60px;

    .ikt-ap_textarea-container {
      height: 85px !important;
    }

    textarea {
      margin-top: 4px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 10px;
    }

    .margin {
      margin-top: 30px;
    }

    .user-radio-container {
      margin-left: -25px;

      label {
        height: 25px;
      }

      span {
        font-family: "Work Sans";
        color: #505665;
      }
    }
  }
}

.user-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 20px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
  }

  .create-btn {
    background-color: #0fd280 !important;
    color: white;
    width: 175px !important;
  }
}
