.position-content-orientation {
    margin: 5px 0;

    .position-info {
     background-color: rgba(61, 115, 221, 0.1);
     display: flex;
     font-family: "Work Sans";
     justify-content: space-between;
     border-radius: 10px;
  
     .position-info-container {
       padding: 15px 0 !important;
       .position-info-title {
         display: flex;
         justify-content: space-between;
         .position-title {
            font-size: 20px;
            font-weight: bold;
            color: #505665;
            line-height: 24px;
          }
  
         .position-total-price {
           color: #505665;
           font-size: 20px;
           font-weight: 600;
           line-height: 24px;
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           .price {
             color: #333333;
             font-size: 12px;
             font-weight: normal;
             padding-right: 50px;
           }
  
         }
       }
  
       .position-info-inputs {
         display: flex;
         flex-direction: row;
         .position-input-item {
           display: flex;
           flex-direction: column;
  
           .types-container {
             display: flex;
             flex-direction: row;
           }
  
           span {
             color: #98a9bb;
             padding-left: 10px;
           }
         }
  
       }
  
       .position-parts-price {
         display: flex;
         flex-direction: row;
         font-size: 12px;
         color: #333333;
         margin-top: 15px;
  
         .parts-item {
           display: flex;
           flex-direction: column;
  
           .parts-price {
             font-size: 14px;
             font-weight: 800;
             color: #505665;
             line-height: 16px;
             padding: 0 3px;
           }
         }
       }
     }
  
     .card-btn-container {
       display: flex;
       padding: 0px !important;
       justify-content: flex-end;
       .card-btn {
         background-color: rgba(61, 115, 221, 0.1);
         border-radius: 0 10px 10px 0;
         display: flex;
         align-items: center;
         width: 26px !important;
         svg {
           color: #3d73dd;
         }
       }
  
       .bottom-borders {
         border-bottom-right-radius: 0;
       }
     }
   }

   .bottom-borders {
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0;
   }
  
  .position-info-content {
    display: flex;
    flex-direction: column;
    height: 67vh;
    background-color: rgba(216, 224, 232, 0.3);
    font-family: "Work Sans";
    padding: 12px 8px;

    .orientations-container {
      display: flex;
      flex-direction: row;

      img {
        flex-grow: 1;
        min-width: 40px;
        min-height: 40px;
        margin: 0 3%;
        padding: 1.5%;
        background-color: rgba(216, 224, 232, 0.3);
      }

      .orientation-selected {
        background-color: white !important;
      }
    }

    .orientations-input {
      display: flex;
      flex-direction: row;
      margin-top: 8px;

      .filter-input {
        flex-grow: 1;

        .input-icon {
          margin-left: -4px !important;
          width: 20px;
          height: 20px;
        }
      }
    }

    .orientations-list {
      flex-grow: 1;
      margin-top: 8px;
      overflow-y: auto;

      .orientation-class-row {
        position: relative;
        background-color: rgba(255, 255, 255, 0.3);
        height: 30px;
        padding: 3px 7px;
        border-radius: 8px;
        margin-bottom: 7px;

        .orientation-class-text {
          display: inline-block;
          width: calc(100% - 20px);
          white-space: nowrap;
          overflow: hidden;
        }

        .delete-btn-container {
          cursor: pointer;
          position: absolute;
          right: 5px;
          top: 5px;

          svg {
            opacity: 0.6;
          }
        }
      }
    }

    .orientations-list::-webkit-scrollbar {
      width: 5px;
    }
  
    .orientations-list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }
  
    .orientations-list::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border: 1px solid slategrey;
    }

    .orientations-control-add {
      position: relative;
      height: 35px;
      margin-top: 7px;

      .ikt-ap_input-container {
        display: inline-block;
        width: calc(100% - 95px) !important;
      }

      .add-input {
        width: 100%;
      }

      .add-btn {
        position: absolute;
        right: 0;
        background-color: #3d73dd !important;
        color: white;
        font-size: 12px;
        border-radius: 6px !important;
        font-family: 'Work Sans';
        font-weight: bold;
        line-height: 14px;
        height: 32px !important;
        width: 90px !important;
        padding: 0 30px;
        margin: 0 0 0 5px;
  
        span {
          color: white !important;
        }
        .MuiButton-label {
          display: flex;
          align-items: center;
  
          .material-icons.MuiIcon-root {
            font-weight: bold;
            font-size: 11px;
            margin-right: 1px;
          }
        }
      }
    }

    .position-tasks {
      .position-info-header {
        padding: 3px 0;
        font-size: 14px;
        color: #98a9bb;
        line-height: 16px;

        .MuiGrid-item {
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-right: 10px !important;
        }

        .MuiGrid-item:last-of-type {
          margin-right: 0px !important;
        }

        span {
          padding-left: 10px;
        }
      }
    }
    .position-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 10px;

      .position-btn-part {
        display: flex;
        flex-direction: row;

        .position-btn {
          height: 32px !important;
          padding: 9px 30px !important;
          font-size: 12px;
          font-family: "Work Sans";
          font-weight: bold;
          margin: 10px;
        }

        .blue {
          background-color: #3d73dd;
          color: white;
        }

        .white {
          background-color: white;
          color: #3d73dd;
        }

        .green {
          background-color: #0fd280;
          color: white;
        }
      }
    }
  }
}
  