.confirm-dialog-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;
    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }
  
    .close-btn {
      color: gray;
      padding: 0 !important;
    }
  }
  
  .confirm-dialog-content {
    width: 450px;
    height: 150px;
    padding: 30px 26px;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    color: #505665;
    font-weight: bold;
    font-size: 14px;
    justify-content: center;
    text-align: center;
  }
  
  .confirm-dialog-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 34px;
  
    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }
  
    .cancel-btn {
      background-color: white !important;
      color: #3d73dd !important;
    }
  
    .create-btn {
      background-color: #0fd280 !important;
      color: white;
    }
  }
  