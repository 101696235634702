.project-information-wrapper-container {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  position: relative;

  .file-viewer-container {
    position: absolute;
    z-index: 9999;
    width: 100%;
  }

  .project-details-card {
    display: flex;
    flex-direction: column;
    background-color: rgba(216, 224, 232, 0.3);
    border-radius:10px;
    padding: 0px !important;
    margin-bottom: 10px;

    .project-details-card-title {
      background-color: rgba(61, 115, 221, 0.1);
      min-height: 34px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 10px 11px !important;
      span {
        color: #505665;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }
    }

    .project-details-content {
      color: #98a9bb;
      font-family: "Work Sans";
      font-size: 14px;
      display: flex;
      flex-direction: column;
      padding: 10px 5px !important;

      .project-details-content-item {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .content-row-item {
          display: flex;
          flex-direction: column;
        }
      }

      .rt-notes-container {
        color: #505665;
      }

      .item-info {
        color: #505665;
      }

      .item-margin{
        margin-bottom: 15px;
      }
      .project-details-info-title {
        display: flex;
        flex-direction: column;
      }

      .order-support-file {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 12px !important;
        margin: 3px 10px;
        display: flex;
        justify-content: space-between;
        height: 52px;

        .file-info {
          color: #505665;
          display: flex;
          flex-direction: column;

          .file-name-data {
            font-weight: bold;
            margin-bottom: 4px;
          }
        }

        .file-delete-btn {
          .close-btn {
            padding: 4px !important;
          }
        }
      }
    }
  }

  .project-information-left-container {
    display: flex;
    flex-direction: column;
    padding: 0 5px 0 24px !important;
  }

  .project-information-right-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px 0 5px !important;
  }
}
