.view-material-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;

  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.view-material-content {
  display: flex;
  flex-direction: row;
  min-height: 435px;
  max-height: 675px;
  width: 869px;
  background-color: #f2f5f8;
  font-family: "Work Sans";
  padding: 20px 35px ;

  .margin {
    margin-top: 20px;
  }

  .view-material-info {
    display: flex;
    flex-direction: column;

    .material-name {
      font-size: 20px !important;
      font-weight: 600 !important;
      color: #505665;
      margin: 10px 0;
    }
    .material-info-item {
      border-bottom: 1px solid #b4c6d9 !important;
      margin: 5px 0;
      padding-bottom: 10px;
      font-family: "Work Sans";
      font-weight: 400;
      font-size: 14px;

      .material-info {
        display: flex;
        flex-direction: row;
        margin: 3px 0 !important;

        div {padding: 0 !important;}
        .material-info-title {
          color: #98a9bb;
          .bold {
            color: #505665;
          }
        }

        .material-info-price {
          span {
            font-weight: 600;
            font-size: 20px;
            color: #505665;
            line-height: 24px;
          }
        }

        .supplier {
          background-color: rgba(61, 115, 221, 0.1);
          border-radius: 6px;
          color: #3d73dd;
          font-size: 14px;
          display: inline-flex;
          justify-content: space-between;
          max-width: 170px;
          margin: 2px 2px;
          flex-direction: row;
          height: 23px;
          padding: 0 10px !important;
        }
      }

      .calculateRetailPricemargin {
        margin-top: 12px;
      }

      .bold {
        font-weight: bold;
      }
    }

    .material-info-item:last-of-type {
      border-bottom: none !important;
    }
  }

  .view-material-description {
    .view-material-btn {
      display: flex;
      justify-content: space-around;
      /*min-height: 66px;*/
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 5px auto;

      button {
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: bold;
        line-height: 14px;
        height: 32px;
        width: 122px;
        padding: 0 10px;
        margin: auto 0;
      }

      button:first-of-type {
        margin-right: 12px;
      }

      .cancel-btn {
        background-color: white;
        color: #3d73dd;
      }

      .duplicate-btn {
        background-color: #0fd280 !important;
        color: white !important;
        margin-right: 12px;
      }

      .create-btn {
        background-color: #3d73dd;
        color: white;
        margin-left: auto;
        margin-right: 0px!important;
      }
    }

    .material-img-container {
      margin-top: 15px;
      color: #98a9bb;

      .material-img {
        margin-top: 10px;
        margin-bottom: 15px;

        .img-container {
          height: 60px;
          width: 99px;
          border-radius: 6px;
          margin: 10px 0;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .img-container-empty {
          background-color: gray;
        }
      }

      .description-text {
        color: #505665;
        border: 0 !important;
        width: 260px;
      }

      .stock-dropdown {
        height: 23px !important;
        width: 100% !important;
        padding: 2px 10px !important;
        border-radius: 6px !important;

        span {
          color: #ffffff !important;
        }
      }
    }

    .dropdown-green {
      .stock-dropdown {
        background-color: #0fd280 !important;
      }
    }

    .dropdown-red {
      .stock-dropdown {
        background-color: #f84141 !important;
      }
    }
  }
}


