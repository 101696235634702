.webshop-settings-container {
  display: flex;
  font-family: "Work Sans";
  flex-direction: column;
  padding: 0 9px !important;
  margin-top: 25px;
  margin-bottom: 65px;

  .webshop-data-container,
  .webshop-data-container-double,
  .webshop-data-container-inner {
    display: flex;
    flex-direction: column;
    margin: 5px 0;

    .webshop-data-title {
      background-color: rgba(61, 115, 221, 0.1);
      height: 39px;
      width: 100%;
      border-radius: 6px 6px 0 0;
      padding: 11px 9px;

      span {
        color: #505665;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .webshop-data-content {
      background-color: #d8e0e8;
      display: flex;
      flex-direction: column;
      padding: 5px 3px;
      border-radius: 0 0 6px 6px;

      .data-content-title {
        color: #98a9bb;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        height: 20px;
      }

      .data-content {
        display: flex;
        flex-direction: row;
        height: 38px;
      }

      .data-title-container {
        background-color: #f2f5f8;
        border-radius: 6px;
        display: flex;
        color: #000000;
        font-size: 14px;
        align-items: center;
        margin: 3px 5px;
        height: 32px;
      }

      .right-aligned {
        justify-content: flex-end;
      }

      .data-value-container {
        padding: 0 !important;
        margin: 3px 5px !important;

        .ikt-ap_input-with-icon {
          width: 100%;
        }

        button {
          margin: 0;
          width: 100% !important;
        }
      }

      .total-title {
        color: #505665;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 3px 5px;
        padding: 0 !important;
      }

      .work-cost-item {
        display: flex;
        flex-direction: column;

        .work-cost-item-name {
          color: #505665;
          font-size: 20px;
          font-weight: 600;
          padding: 10px 0 0 5px;
        }
      }
    }
  }

  .webshop-data-container-double {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .webshop-data-container-inner {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 0 !important;

    .webshop-data-content {
      height: 100%;
    }
  }

  .webshop-data-container-inner:first-of-type {
    margin-right: 15px !important;
  }
}
