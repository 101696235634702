.order-inquiry-details-cost-card {
  display: flex;
  padding: 0 !important;
  flex-direction: column;
  background-color: rgba(216, 224, 232, 0.3);
  border-radius:10px;
  margin-bottom: 10px;

  .card-title {
    background-color: rgba(61, 115, 221, 0.1);
    min-height: 34px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 11px !important;
    span {
      color: #505665;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
  }

  .card-content {
    color: #98a9bb;
    font-family: "Work Sans";
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 10px 5px !important;

    .card-data-field {
      padding-bottom: 12px;
      padding-top: 5px;
      .card-data-item {
        display: flex;
        flex-direction: row;
        font-family: "Work Sans";
        align-items: center;
        color: #505665;
        font-size: 14px;

        .card-data-title {
          color: #98a9bb;
        }
      }

      .item-title {
        font-size: 14px;
        font-weight: bold;
      }

      .total-prices {
        margin-bottom: 12px;
      }
    }

    .with-border {
      border-bottom: 1px solid #b4c6d9 !important;
    }

    .card-data-field-total {
      color: #505665;
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      .total-value {
        font-weight: bold;
      }
    }

    .right-aligned {
      display: flex;
      justify-content: flex-end;
      align-items: center
    }

    .total-price {
      font-size: 20px;
    }

    .semi-bold {
      font-weight: 600;
    }
  }
}
