.ikt-ap_project-tabs-container {
  display: flex;
  margin-top: 7px;

  .ikt-ap_project-tab-item {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #505665;
    text-align: center;
    font-family: "Work Sans";
    font-weight: bold;
    font-size: 16px;
    padding: 3px 6px;
    margin-right: 15px;
    min-width: 140px;
    cursor: pointer;

    &.active {
      color: #3d73dd;
      border-bottom: 2px solid #b4c6d9;
    }

    &:hover {
      color: #3d73dd;
    }
  }
}
