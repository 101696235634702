.ikt-ap_decimal-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .decimal-input {
    border: none;
    width: 100% !important;
    font-family: 'Work Sans';
    font-size: 14px;
    outline: none !important;
    min-width: 30px;
    text-align: right;
    margin-right: 5px;
  }

  .decimal-input-padding-correction {
    padding-left: 0;
    padding-right: 13px;
    margin-right: 0;
  }

  .decimal-input::placeholder {
    text-align: left !important;
  }

  .left-input {
    text-align: right;
    width: 100%;
  }

  .right-input {
    max-width: 20px;
  }

  .decimal-input-adornment {
    font-weight: bold;
    font-size: 12px;
    color: #b4c6d9;
    margin-left: auto;
  }

  .adornment-padding-correction {
    margin-left: -11px;
  }
}

.ikt-ap_decimal-inputs {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  font-family: 'Work Sans';
  padding: 9px 11px;
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.ikt-ap_decimal-inputs-padding-correction {
  padding: 9px 11px 9px 5px;
}

.ikt-ap_decimal-inputs_error {
  display: flex;
  flex-direction: row;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #f84141;
  background-color: #ffffff;
  font-family: 'Work Sans';
  padding: 9px 11px;
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.ikt-ap_decimal-inputs-error-msg {
  font-size: 14px !important;
  color: #f84141 !important;
  padding-left: 9px;
  position: absolute;
  top: 35px;
  width: max-content;
}

.decimal-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.decimal-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.decimal-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}
