@import '~react-toastify/dist/ReactToastify.min.css';

.ikt-ap_input {
  border: 1px solid #eeeeee;

  &:hover {
    border-color: #477eea;
  }

  &:focus {
    border-color: #477eea;
    box-shadow:  0px 0px 2px rgba(61,115,221,0.6)
  }
}

// load fonts
// IMPORTANT: italics not included due to bundle size, need to be included if used 
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/WorkSans-Regular.ttf');
  src: local('Work Sans Regular'), local("WorkSans Regular"),
       url('../assets/fonts/WorkSans-Regular.ttf') format('truetype'),
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/WorkSans-SemiBold.ttf');
  src: local('Work Sans SemiBold'), local("WorkSans SemiBold"),
       url('../assets/fonts/WorkSans-SemiBold.ttf') format('truetype'),
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;  // 'bold'
  src: url('../assets/fonts/WorkSans-Bold.ttf');
  src: local('Work Sans Bold'), local("WorkSans Bold"),
       url('../assets/fonts/WorkSans-Bold.ttf') format('truetype'),
}
