.cdl {
  .cdl-dropdown-button-style {
    background-color: white !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    display: flex !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    color: black !important;
    margin-right: 5px !important;
    width: 100% !important;
    height: 32px;
    justify-content: space-between !important;
  
    span {
      font-family: "Work Sans";
      font-size: 14px;
      font-weight: 100;
      line-height: 16px;
      text-transform: none;
    }
  
    b {
      color: #3d73dd;
    }
  }

  .cdl-restyle-button {
    padding-left: 43px !important;

    img.menu-item-icon {
      position: absolute;
      left: 8px;
    }
  }
}