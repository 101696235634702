@import '../../assets/scss/material-dashboard-pro-react.scss';

$highlight-color: #eee;

.ikt-ap_custom-dropdown {
  position: relative;
  .ikt-ap_custom-dropdown-toggle-btn {
    border: 1px solid #eeeeee;
    border-radius: 6px !important;

    &:hover,
    &:focus {
      border-color: #477eea;
    }
    span {
      overflow: hidden;
      margin-right: 12px;
    }
    span img {
      position: absolute;
      right: 0;
      margin-right: 2.5px;
    }
  }

  .dropdown-error {
    border: 1px solid #f84141 !important;
  }

  .ikt-ap_custom-dropdown-menu {
    border-radius: 6px;
    border: 1px solid #477eea;
    position: absolute;
    text-align: left !important;
    min-width: 100% !important;
    max-width: 100% !important;
    max-height: 165px;
    overflow-x: hidden;
  }

  .ikt-ap_custom-dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }

  .ikt-ap_custom-dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
  }

  .ikt-ap_custom-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border: 1px solid slategrey;
  }

  .menu-corrected-width {
    min-width: calc(100% - 6px) !important;
    max-width: calc(100% - 6px) !important;
    left: 3px !important;
  }

  @media (max-width: $screen-md-min) {
    div[role="tooltip"] {
      min-width: 100%;
      max-width: 100%;
    }
    
    .ikt-ap_custom-dropdown-menu {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .ikt-ap_custom-dropdown-toggle-btn {
    span {
      display: flex !important;
    }
    .status-title {
      text-align: left;
    }
  }

  .MuiListItem-button {
    color: #000;
    &:hover {
      background-color: transparent;
      color: #000;
      box-shadow: none;
    }
  }

  .ikt-ap_custom-dropdown-item-selected {
    /*font-weight: 800;*/
    color: #3d73dd !important;
    background-color: $highlight-color !important;
  }

  .custom-dropdown-item {
    white-space: normal;
  }

  .MuiList-root {
    width: 100%;
    max-height: 100% !important;
    z-index: 200;
    overflow-y: auto;
  }

  .menu-item-icon {
    display: inline;
    max-width: 30px;
    margin-right: 2px;
  }
}
