.tags-content {

  .input-icon {
    width: 16px;
    height: 16px;
  }

  .title-container {
    margin-left: 10px;
    margin-bottom: 3px;
    color: #98a9bb;
  }

  .tags-list {
    margin-top: 5px !important;
    overflow-y: auto;

    .selected-tag {
      display: inline-block;
      background-color: rgba(61, 115, 221, 0.1);
      border-radius: 6px;
      color: #3d73dd;
      font-size: 14px;
      justify-content: space-between;
      margin: auto 5px 5px 0;
      height: 23px;
      padding: 0 30px 0 10px;
      position: relative;

      .tag-delete-btn {
        display: inline;
        position: absolute;
        right: 5px;
      }
    }
  }

  .tags-list::-webkit-scrollbar {
    width: 5px;
  }

  .tags-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
  }

  .tags-list::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border: 1px solid slategrey;
  }
}
