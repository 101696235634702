.files-drop-zone-container {
  position: relative;
  height: 100%;
  width: 100%;
  div {
    border: none !important;
    background-image: none !important;
    background-color: #e9eef3;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 0px;
    height: 100%;
    z-index: 0;
  }

  div:hover {
    border: none;
    background-image: none;
  }

  svg {
    display: none;
  }

  p {
    font-size: 14px;
    font-family: 'Work Sans';
    color: #505665;
  }

  .drop-zone-browse-btn {
    // position: absolute;
    cursor: pointer;
    // top: 49px;
    // left: 47%;
    // height: 0;
    // width: 0;
    font-size: 14px;
    font-family: 'Work Sans';
    color: #3d73dd;
    font-weight: bold;
    padding-top: 2px!important;
  }

  .drop-zone-browse-btn:hover {
    color: #477eea;
  }
}
