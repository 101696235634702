.ikt-ap_create-order-container {
  min-height: calc(100vh - 90px) !important;
  height: 100%;

  .ikt-ap_create-order-content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    .ikt-ap_create-order-top-wrapper {
      padding: 0 24px !important;
      max-height: 92px;
      .ikt-ap_create-order-top-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 27px;

        .ikt-ap_create-project-top-right {
          display: flex;
          flex-direction: row;
          margin-left: -6px;

          .project-number {
            display: flex;
            text-align: end;
            flex-direction: column;
            min-width: 120px;
            font-family: "Work Sans";
            font-size: 12px;
            color: #333333;
            line-height: 14px;
            margin-right: 10px;
            padding: 7px 0;
            .project-number-title {
              font-size: 14px;
              font-weight: bold;
            }
          }

          .ikt-ap_create-project-add-customer-btn {
            background-color: #0fd280;
            border-radius: 6px;
            color: white;
            font-size: 12px;
            font-family: "Work Sans";
            font-weight: bold;
            line-height: 14px;
            height: 32px !important;
            width: 100%;
            padding: 0 15px;

            .MuiButton-label {
              display: flex;
              align-items: center;

              .material-icons.MuiIcon-root {
                font-weight: bold;
                font-size: 11px;
                margin-right: 1px;
              }
            }
          }

          .position-btn {
            background-color: #3d73dd;
            margin-right: 5px;
          }
        }
      }
    }

    .ikt-ap_create-project-tab-content {
      display: flex;
      flex: 1;
      padding: 0 27px !important;
    }

    .create-project-bottom {
      align-self: flex-end;
      background-color: #e9eef3;
      justify-content: space-between;
      display: flex;
      height: 66px;
      position: fixed;
      bottom: 0;
      right: 0;

      .next-back-btn-container {
        display: flex;
        flex-direction: row;
        margin: 15px 24px;

        button {
          margin: 0 !important;
        }

        .back-btn,
        .next-btn {
          margin-right: 10px !important;
        }
      }

      button {
        border-radius: 6px;
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: bold;
        line-height: 14px;
        height: 32px;
        padding: 0 30px;
        margin: 15px 24px;
      }

      .cancel-btn,
      .next-btn {
        background-color: white;
        color: #3d73dd;
      }

      .create-btn {
        background-color: #0fd280;
        color: white;
      }
    }
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }
}

