.ikt-ap_settings-container {
  min-height: calc(100vh - 90px);
  position: relative;

  .ikt-ap_settings-top-wrapper {
    .ikt-ap_settings-top-container {
      display: flex;
      justify-content: space-between;
      padding: 0 9px !important;

      .ikt-ap_settings-top-right {
        display: flex;
        flex-direction: row;
        margin-left: -6px;

        .ikt-ap_settings-dropdown-btn {
          background-color: #fff;
          color: #000;
          margin-left: 6px;
          padding: 2px 11px;
          width: 155px;

          .MuiButton-label {
            display: flex;
            justify-content: space-between;
            text-transform: none;
            font-size: 14px;

            .ikt-ap_dropdown-btn-text {
              max-width: 119px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            b {
              border-top: 6px solid;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top-color: #e91e63;
            }
          }
        }
      }
    }

    .ikt-ap_settings-create-btn {
      background-color: #3d73dd;
      color: white;
      font-size: 12px;
      border-radius: 6px !important;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px !important;
      width: 100%;
      padding: 0 30px;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }

    .ikt-ap_settings-search {
      margin-left: 6px;
    }
  }

  .settings-bottom {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    height: 66px;
    padding: 15px 39px !important;
    position: absolute;
    width: 100%;
    bottom: 0;

    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
    }

    .cancel-btn {
      background-color: white;
      color: #3d73dd;
      min-width: 170px !important;
    }

    .next-btn {
      background-color: #0fd280;
      color: white;
    }
  }
}

.ikt-ap_loader {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 101px);
  width: 100%;
  margin-top: 100px;
}
