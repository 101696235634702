.projects-settings-container {
  display: flex;
  font-family: "Work Sans";
  flex-direction: column;
  padding: 0 9px !important;
  margin-top: 25px;
  margin-bottom: 65px;

  .projects-data-container-outer {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    margin-bottom: 5px;
    height: 100%;

    .projects-data-container-inner {
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      padding: 0 !important;

      .projects-data-title {
        background-color: rgba(61, 115, 221, 0.1);
        height: 39px;
        width: 100%;
        border-radius: 6px 6px 0 0;
        padding: 11px 9px;

        span {
          color: #505665;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .projects-data-content {
        background-color: #d8e0e8;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 5px 3px;
        border-radius: 0 0 6px 6px;

        .data-content-title {
          color: #98a9bb;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          height: 20px;
          
          .MuiGrid-item {
            padding-left: 4px !important;
          }

          .MuiGrid-item:first-of-type {
            padding-left: 10px !important;
          }

          .grid-item-width-6 {
            padding-left: 2.25% !important;
          }
        }

        .data-content {
          display: flex;
          flex-direction: row;
          height: 38px;
        }

        .vat-checkboxes-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 4px 0 0 1px;

          .checkbox-container {
            display: inline-block;
            margin-right: 15px;

            .MuiCheckbox-root {
              padding: 5px;
            }

            .checkbox-label {
              margin-left: 2px;
              font-weight: normal;
              font-size: 14px;
              color: rgba(80, 86, 101, 1);
            }
          }
        }

        .data-title-container {
          background-color: #f2f5f8;
          border-radius: 6px;
          display: flex;
          color: #000000;
          font-size: 14px;
          align-items: center;
          margin: 3px 5px;
          height: 32px;
          overflow-x: hidden;
          white-space: nowrap;
        }

        .right-aligned {
          justify-content: flex-end;
        }

        .data-value-container {
          padding: 0 !important;
          margin: 3px 5px !important;

          .ikt-ap_input-with-icon {
            width: 100%;
          }

          button {
            margin: 0;
            width: 100% !important;
          }
        }

        .total-title {
          color: #505665;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 3px 5px;
          padding: 0 !important;
        }

        .work-cost-item {
          display: flex;
          flex-direction: column;

          .work-cost-item-name {
            color: #505665;
            font-size: 20px;
            font-weight: 600;
            padding: 10px 0 0 5px;
          }
        }
      }

      .grid-item-width-6 {
        width: 49.3% !important;
      }
    }

    .general-container,
    .letterhead1-container {
      padding-right: 7px !important;
    }

    .discounts-container,
    .letterhead2-container {
      padding-left: 8px !important;
      margin-right: 15px !important;
    }

    .automatic-numering-container {
      margin-right: 15px !important;

      .grid-an-name {
        flex-grow: 1;
      }

      .data-content-title {
        .grid-an-prefix {
          margin-left: 7px;
        }

        .grid-an-starting {
          overflow-x: visible;
          white-space: nowrap;
          margin-left: 10px;
        }

        .grid-an-postfix {
          margin-left: 11px;
        }
      }
    }

    .letterhead1-container,
    .letterhead2-container {
      .upload-img {
        padding: 10px !important;
        
        .logo-upload {
          width: 100% !important;
          height: 180px !important;

          .logo-container {
            width: 100%;
            height: 100%;
          }

          img {
            width: calc(100% - 60px);
            height: 100%;
          }

          .remove-button {
            position: relative !important;
            top: 4px !important;
            bottom: 0 !important;
            left: 2px !important;
            right: 0 !important;
          }
        }
      }
    }

    .letterhead2-container {
      padding-right: 10px !important;
    }
  }
}
