.ikt-ap_manufacturers-inquiries-container {
  min-height: calc(100vh - 90px);
  position: relative;

  .ikt-ap_manufacturer-inquiries-content {
    position: relative;
  }

  .ikt-ap_manufacturers-inquiries-top-wrapper {
    position: absolute;
    .ikt-ap_manufacturers-inquiries-top-container {
      display: flex;
      justify-content: space-between;
      padding: 0 9px !important;
    }
  }

}

.ikt-ap_loader {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 101px);
  width: 100%;
  margin-top: 100px;
}
