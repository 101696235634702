.create-invoice-dialog {

  .create-invoice-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;

    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .section-right {
      .step-text {
        font-size: 14px;
        margin-right: 20px;
      }

      .close-btn {
        color: gray;
        padding: 0px !important;
      }
    }
  }

  .create-invoice-content {
    width: 1200px;
    height: 700px !important;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding: 27px 0 27px 27px !important;

    .step1-container,
    .step2-container {
      display: flex;
      flex-direction: column;

      .grid-item-no-padding {
        padding: 0 !important;
        display: flex;
        flex-direction: row;
      }

      .grid-item-padding-right {
        padding: 0 10px 0 0 !important;
      }

      .grid-item-padding-left {
        padding: 0 0 0 10px !important;
      }
    }

    .step1-container {
      height: 100%;
      padding-right: 27px;
      
      .details-box {
        padding: 3px !important;
        display: flex;
        flex-direction: column;
        background-color: rgba(61, 115, 221, 0.1);
        border-radius: 6px;
        margin-bottom: 10px;
      
        .details-box-title {
          min-height: 34px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 10px 15px 5px 15px !important;
          display: flex;
          justify-content: space-between;
      
          .title {
            color: #505665;
            font-size: 20px;
            font-weight: 600;
          }
    
          .controls-right {
            display: flex;
            flex-direction: row;
            margin-top: -6px;
    
            .text-preset-dropdown {
              min-width: 200px;
    
              .dropdown-blue-style{
                min-width: 200px;
              }
            }
    
            .ikt-ap_custom-dropdown-menu {
              margin-left: 3px !important;
              min-width: 200px !important; 
              max-width: 200px !important;
            }
    
            .expand-btn {
              padding: 8px 0 8px 8px !important;
              display: inline-block;
    
              img {
                max-width: 22px;
                max-height: 22px;
              }
            }
    
            .contract-btn {
              padding: 8px 0 4px 8px !important;
              display: inline-block;
    
              img {
                max-width: 24px;
                max-height: 24px;
              }
            }
          }
        }
        
        .details-box-content {
          color: #98a9bb;
          font-family: "Work Sans";
          font-size: 14px;
          display: flex;
          flex-direction: row;
          padding: 10px 5px !important;
          
          .details-box-info-title {
            display: flex;
            flex-direction: column;
          }
          
          .details-box-info-data {
            color: #505665;
            display: flex;
            flex-direction: column;
      
            .details-box-info-data-item {
              width: 250px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
    
        .textarea-container {
          padding: 0 10px 10px 10px !important;
    
          div {
            textarea{
              min-height: 120px;
            }
          }
        }

        .input-container {
          padding: 0 10px 10px 10px !important;
          
          .ikt-ap_input-container {
            width: 100% !important;
          }
        }
        
        .form-control-checkbox {
          margin-bottom: 2px !important;
          margin-left: -4px;
      
          .MuiFormControlLabel-label {
            font-family: "Work Sans" !important;
            font-size: 14px !important;
            color: #505665 !important;
            line-height: 16px !important;
          }
        }
    
        .additional-elements-checkbox {
          margin-top: -4px;
    
          svg {
            fill: gray;
          }
          span {
            font-family: "Work Sans" !important;
            font-size: 14px !important;
            color: #505665 !important;
            line-height: 16px !important;
          }
          span:hover {
            background-color: rgba(180, 198, 217, 0.1) !important;
          }
        }
    
        .additional-elements-checkbox-checked {
          svg {
            fill: #4277de !important;
          }
        }
      }
    
      .details-box-alternative {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      
        .details-box-alternative-title {
          padding-left: 0 !important;
          display: flex;
      
          .title {
            color: #98a9bb;
            font-size: 16px;
          }
        }
        
        .details-box-alternative-content {
          background-color: #e9eef3;
          font-family: "Work Sans";
          font-size: 14px;
          display: flex;
          flex-direction: column;
          min-height: 316px;
          border-radius: 10px;
          padding: 10px 10px !important;
    
          .invoice-type {
            position: relative;
            display: flex;
            background-color: white;
            border-radius: 5px;
            height: 38px;
            margin-bottom: 6px;
    
            span {
              color: #505665;
              padding-left: 10px;
              display: inline-flex;
              align-items: center;
              font-weight: 600;
              font-size: 13px;
            }
    
            .checkbox-btn {
              position: absolute;
              top: 2px;
              right: 5px;
              padding: 5px !important;
    
              span {
                padding-left: 0 !important;
    
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }
    
      .box-maximized {
        height: 100%;

        .expanded-textarea {
          padding: 0 10px 10px 10px !important;
          height: 100%;
    
          div {
            textarea{
              min-height: 100%;
            }
          }
        }
      }
    }

    .step2-container {
      height: 100%;

      .grid-item-padding-right {
        width: 71.5%;
        padding: 0 !important;
      }

      .grid-item-padding-left {
        padding-left: 0 !important;
        border-left: 2px solid #dde1e5;
        width: 28.5%;

        .step-area {
          padding-left: 15px;
        }
      }

      .step-area {
        .area-title {
          position: relative;
          padding: 0 !important;

          .title {
            color: #505665;
            font-size: 20px;
            font-weight: 600;
            font-family: 'Work Sans' !important;
          }

          .controls-right {
            position: absolute;
            top: -5px;
            right: 7px;

            display: flex;
            flex-direction: row;

            .area-title-btn {
              background-color: white;
              color: #3d73dd;
              font-weight: bold;
              padding: 8px 15px !important;
              margin: 0 0 0 10px !important;
              border-radius: 5px !important;
            }
          }
        }

        .area-content {
          padding: 0 !important;

          .positions-header {
            display: flex;
            margin: 25px auto 15px auto !important;
            position: relative;
            padding-left: 12px;
            
            .positions-header-title {
              display: flex;
              justify-content: flex-start;
              font-size: 12px;
              font-family: "Work Sans";
              font-weight: bold;
              color: #333333;
              line-height: 14px;
              text-align: center;
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
            
            .ts-col-number {
              min-width: 6% !important;
              max-width: 6% !important;
            }
            
            .ts-col-name {
              min-width: 22% !important;
              max-width: 22% !important;
            }
            
            .ts-col-amount {
              min-width: 7.75% !important;
              max-width: 7.75% !important;
            }
            
            .ts-col-metrics {
              min-width: 10% !important;
              max-width: 10% !important;
            }
            
            .ts-col-total-price {
              min-width: 17% !important;
              max-width: 17% !important;
            }
            
            .ts-col-vat {
              min-width: 10% !important;
              max-width: 10% !important;
              padding-right: 6px !important;
            }
            
            .ts-col-discount {
              min-width: 11% !important;
              max-width: 11% !important;
              padding-right: 8px !important;
            }
            
            .ts-col-action-btn {
              min-width: 10% !important;
              max-width: 10% !important;
          
              position: absolute !important;
              right: 0 !important;
            }
            
            .right-aligned {
              justify-content: flex-end !important;
            }
          }
          
          .positions-body {
            display: flex;
            margin: 0 0 15px 0 !important;
            padding-right: 10px;
            max-height: 574px;
            overflow-y: auto;
            
            .positions-content-no-records {
              width: 100%;
              display: flex;
              justify-content: center;

              .no-records {
                margin: 20px auto 0 auto;
                font-size: 12px;
                font-family: "Work Sans";
                font-weight: 400;
                color: #333333;
                line-height: 14px;
                text-align: center;
              }
            }

            .positions-item {
              display: flex;
              height: 40px;
              position: relative;
              margin: 0 auto 6px auto !important;
              padding-left: 12px !important;

              background-color: #e5eaf3;
              border-radius: 5px !important;
              
              .positions-item-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 14px;
                font-family: "Work Sans";
                color: #505665;
                line-height: 14px;
                padding: 0 !important;
      
                overflow: hidden;
                white-space: nowrap;

                .select-btn {
                  display: inline-block;
                  max-width: 32px !important;
                  max-height: 32px !important;
                  padding: 6px !important;

                  .MuiIconButton-label {
                    max-width: 22px;
                    max-height: 22px;
                    
                    img {
                      max-width: 22px;
                      max-height: 22px;
                    }
                  }
                }
              }
              
              .ts-col-number {
                min-width: 6% !important;
                max-width: 6% !important;
              }
              
              .ts-col-name {
                min-width: 22% !important;
                max-width: 22% !important;
              }
              
              .ts-col-amount {
                min-width: 7.75% !important;
                max-width: 7.75% !important;
              }
              
              .ts-col-metrics {
                min-width: 10% !important;
                max-width: 10% !important;
              }
              
              .ts-col-total-price {
                min-width: 17% !important;
                max-width: 17% !important;
              }
              
              .ts-col-vat {
                min-width: 10% !important;
                max-width: 10% !important;

                text-align: right;
              }
              
              .ts-col-discount {
                min-width: 11% !important;
                max-width: 11% !important;

                text-align: right;
              }
              
              .ts-col-action-btn {
                min-width: 32px !important;
                max-width: 32px !important;
                
                position: absolute !important;
                right: 10px !important;
                top: 3px !important;
              }
      
              .right-aligned {
                justify-content: flex-end !important;
              }
      
              .bold {
                font-weight: 600;
              }
            }

            .positions-item:last-of-type {
              margin-bottom: 0 !important;
            }

            .disabled-position {
              .positions-item-title {
                color: #a1a6af !important;
              }
            }

            .selected-position {
              border: 2px solid #4277de;
              padding-left: 10px !important;

              .ts-col-action-btn {
                right: 8px !important;
                top: 1px !important;
              }

              .ts-col-discount {
                position: relative;
                right: -2px;
              }

              .ts-col-vat {
                position: relative;
                right: -1px;
              }
            }
          }

          .positions-body::-webkit-scrollbar {
            width: 4px;
          }
          
          .positions-body::-webkit-scrollbar-track {
            box-shadow: none;
          }
        
          .positions-body::-webkit-scrollbar-thumb {
            background-color: #3d73dd;
            border: none;
          }

          .selection-header {
            display: flex;
            margin: 25px auto 15px auto !important;
            position: relative;
            padding-left: 0;

            .selection-header-title {
              display: flex;
              justify-content: flex-start;
              font-size: 12px;
              font-family: "Work Sans";
              font-weight: bold;
              color: #333333;
              line-height: 14px;
              text-align: center;
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
            
            .ts-col-number {
              padding-left: 9px !important;
              min-width: 24% !important;
              max-width: 24% !important;
            }
            
            .ts-col-name {
              min-width: 20% !important;
              max-width: 20% !important;
            }
          }

          .selection-body {
            height: 505px !important;
            padding-right: 27px;
            overflow-y: auto;

            .selection-list-container {
              .selection-list-item {
                position: relative;
                min-height: 40px;
                margin-bottom: 5px;
                border-radius: 5px;
                padding: 6px 9px;
                background-color: #e5eaf3;

                .drag-img-container {
                  padding-top: 2px;

                  img {
                    width: 18px;
                  }
                }

                .drag-img-container,
                .numeric-name,
                .name {
                  position: relative;
                  top: 3px;
                }

                .numeric-name {
                  font-weight: 600;
                  margin-left: 10px;
                }

                .name {
                  margin-left: 24px;
                }

                .delete-btn {
                  position: absolute;
                  right: 5px;
                  top: 4px;
                  padding: 4px !important;

                  img {
                    width: 24px;
                  }
                }
              }

              .selection-list-item:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          .selection-body::-webkit-scrollbar {
            width: 4px;
          }
          
          .selection-body::-webkit-scrollbar-track {
            box-shadow: none;
          }
        
          .selection-body::-webkit-scrollbar-thumb {
            background-color: #3d73dd;
            border: none;
          }
        }
      }

      .summary-container {
        padding: 14px 17px !important;
        background-color: white;

        .summary-header {
          .summary-header-title {
            font-size: 13px;
            padding: 0 !important;
          }
        }

        .summary-body {
          .summary-body-title {
            font-size: 13px;
            font-weight: bold;
            padding: 0 !important;
          }
        }

        .ts-col-price,
        .ts-col-vat,
        .ts-col-discount {
          width: 33%;
        }

        .ts-col-discount {
          text-align: right;
        }
      }
    }

    .step3-container {
      display: flex;
      flex-direction: row;
      padding-right: 27px;

      .data-container {
        display: flex;
        flex-direction: column;
        margin: 5px 0;
        padding: 0 !important;

        .data-title {
          background-color: rgba(61, 115, 221, 0.1);
          height: 39px;
          width: 100%;
          border-radius: 6px 6px 0 0;
          padding: 11px 9px;
  
          span {
            color: #505665;
            font-size: 20px;
            font-weight: 600;
          }
        }
  
        .data-content {
          background-color: #ebeff4;
          display: flex;
          padding: 8px;
          border-radius: 0 0 6px 6px;

          .data-content-title {
            color: #98a9bb;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;
            height: 20px;
            padding-left: 10px !important;
            flex-grow: 0;
          }
    
          .data-content-body {
            display: flex;
            flex-direction: row;
            padding: 0 !important;

            .ikt-ap_textarea-container {
              height: 100px !important;
            }
          }
        }

        .invoice-summary {
          flex-direction: row;
          padding: 13px 8px !important;
          
          .data-box {
            margin-left: 90px;

            .name,
            .value {
              display: block;
              text-align: right;
            }

            .name {
            }

            .value {
              font-weight: 600;
            }
          }

          .data-box:first-of-type {
            margin-left: 40px;
            margin-right: 30px;
          }
        }

        .discounts {
          flex-direction: column;

          .data-content-title {
            .right-aligned {
              text-align: right;;
            }

            .MuiGrid-item:first-of-type {
              padding-left: 5px !important;
            }

            .MuiGrid-item:nth-of-type(2) {
              padding-right: 25px !important;
            }

            .MuiGrid-item:nth-of-type(3) {
              padding-right: 20px !important;
            }

            .MuiGrid-item:nth-of-type(4) {
              padding-right: 15px !important;
            }
          }

          .data-content-body {
            flex-direction: column;

            .data-content-body-item {
              display: flex;
              flex-direction: row;
              padding: 0 !important;

              .data-title-container {
                background-color: #f2f5f8;
                border-radius: 6px;
                display: flex;
                color: #000000;
                font-size: 14px;
                align-items: center;
                margin: 3px 5px;
                height: 32px;
              }
        
              .right-aligned {
                justify-content: flex-end;
              }
        
              .data-value-container {
                padding: 0 !important;
                margin: 3px 5px !important;
              }

              .payment-terms-input-field {
                min-width: 372px !important;
              }
            }
          }
        }

        .billing-peculiarities {
          flex-direction: column;
        }
      }
    }
  }

  .invoice-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 20px;
    border-top: 2px solid #dde1e5;

    .rhs-button-container,
    .lhs-button-container {
      padding-top: 16px;
      
      button {
        margin: auto 0 auto 10px !important;
      }
    }

    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }

    .cancel-btn,
    .preview-btn,
    .navigation-btn {
      background-color: white;
      color: #3d73dd;
    }

    .save-draft-btn {
      background-color: #3d73dd;
      color: white;
    }

    .create-btn {
      min-width: 135px !important;
      background-color: #0fd280;
      color: white;
    }
  }
}
