@import "../../../../assets/scss/material-dashboard-pro-react.scss";

.contact-person-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Work Sans";
  margin: 0 auto;
  padding-top: 50px;
  flex: 1;

  .contact-person-page-title {
    font-weight: bold;
    text-align: center;
    font-size: 36px;
    color: #505665;
    line-height: 42px;
    margin-bottom: 30px;
  }

  .contact-persons-content {
    max-height: 390px;
    width: 100%;
    position: relative;
    padding-left: 8px;

    .contact-person-content {
      background-color: rgba(61, 115, 221, 0.1) !important;
      border-radius: 10px !important;
      font-family: "Work Sans";
      width: 98.5%;
      margin-bottom: 10px;
      padding-right: 20px;
      position: relative;

      .contact-person-info {
        padding: 18px 8px !important;

        .contact-person-header {
          display: flex;
          justify-content: space-between;
          .person-title {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #505665;
            line-height: 24px;
            padding-left: 8px;
          }
        }

        .contact-person-info-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 15px;

          .contact-info-item {
            display: flex;
            flex-direction: row;
            padding: 0 !important;
          }

          .contact-info {
            display: flex;
            flex-direction: column;
            padding: 0 8px !important;
            span{
              color: #98a9bb;
              font-size: 14px;
              padding-left: 9px;
              padding-bottom: 3px;
            }
            .input-container {
              min-height: 60px;
            }
          }

          .contact-info-col2 {
            width: 50%;
          }
        }
      }

      .card-btn {
        background-color: rgba(61, 115, 221, 0.5);
        border-radius: 0 10px 10px 0;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        width: 26px;
        height: 100%;

        div {
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          svg {
            color: #3d73dd;
          }
        }
      }

      .card-btn:hover {
        background-color: #b0c1dc;
      }
    }

  }

  .add-person-btn {
    display: flex;
    justify-content: flex-start;
    padding-left: 8px;
    button {
      background-color: #3d73dd !important;
      color: white;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 202px;
      padding: 0 10px;
    }
  }
}

@media (max-width: $screen-md-min) {
  .contact-person-page {
    padding-top: 35px;

    .contact-person-page-title {
      margin-bottom: 20px;
    }
  
    .contact-persons-content {
      max-height: 320px;

      .contact-person-content {
        margin-bottom: 10px;
  
        .contact-person-info {
          padding: 8px 8px 10px 8px !important;

          .contact-person-info-content {
            margin-top: 0;

            .contact-info {
              input {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
