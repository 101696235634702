.ikt-ap_notifications-container {
  .notifications-dropdown-content {
    display: flex;
    margin: 0 24px !important;

    .notifications-dropdown-style {
      background-color: white !important;
      border-radius: 6px !important;
      box-shadow: none !important;
      display: flex !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      color: black !important;
      margin-right: 5px !important;
      width: 150px !important;
      height: 32px;
      justify-content: space-between !important;

      span {
        font-family: "Work Sans";
        font-size: 14px;
        font-weight: 100;
        line-height: 16px;
        text-transform: none;
      }

      b {
        color: #3d73dd;
      }
    }

    .create-notification-btn {
      background-color: #0fd280 !important;
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: "Work Sans" !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }
  }

  .notifications-header {
    display: flex;
    margin: 0 39px !important;
    margin-top: 25px !important;
    border-bottom: 2px solid #3d73dd;

    .notifications-header-title {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: center;
      padding-bottom: 4px !important;
    }
  }

  .notifications-content {
    display: flex;
    margin: 0 39px !important;

    .notifications-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;

      .notifications-item-title {
        font-size: 14px;
        font-family: "Work Sans";
        color: #505665;
        line-height: 14px;
        text-align: center;
        padding-top: 19px !important;
        padding-bottom: 4px !important;
      }

      .bold {
        font-weight: 600;
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 5px;
      }
    }

    .dark {
      background-color:  rgba(180, 198, 217, 0.1);
    }
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }
}
