@import '../../assets/scss/material-dashboard-pro-react.scss';

.ikt-ap_custom-autocomplete {
  position: relative;
  .ikt-ap_custom-dropdown-toggle-btn {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 6px !important;
    font-size: 14px;

    &:hover,
    &:focus {
      border-color: #477eea;
    }
  }

  .dropdown-error {
    border: 1px solid #f84141 !important;
  }

  .ikt-ap_custom-dropdown-menu {
    border-radius: 6px;
    border: 1px solid #477eea;
    position: absolute;
    text-align: left !important;
    min-width: 100%;
    max-width: 100%;
    max-height: 165px;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border: 1px solid slategrey;
    }
  }

  .ikt-ap_custom-dropdown-menu-icon {
    left: -10px;
  }

  .ikt-ap_custom-dropdown-menu-no-icon {
    left: 0;
  }

  .ikt-ap_custom-dropdown-toggle-btn{
    span {
      display: flex !important;
    }
    .status-title {
      text-align: left;
    }
  }

  .MuiListItem-button {
    color: #000;
    &:hover {
      background-color: transparent;
      color: #3d73dd;
      box-shadow: none;
    }
  }
  .ikt-ap_custom-dropdown-item-selected {
    font-weight: 800;
    color: #000;
  }

  .MuiList-root {
    width: 100%;
    max-height: 250px;
    z-index: 200;
    overflow-y: auto;
  }

  @media (max-width: $screen-md-min) {
    div[role="tooltip"] {
      left: 0;
      right: 0;
    }
  }
}


