.create-notification-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.create-notification-content {
  width: 595px;
  height: 790px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  padding: 20px 20px !important;

  .notification-item {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    margin-top: 10px;
    .item-title {
      color: #98a9bb;
      padding-left: 10px;
    }

    .notification-radio-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5px;
      span {
        color: #505665;
        font-family: "Work Sans";
        font-size: 14px;
        padding: 0 !important;
        margin-left: 7px;
      }
    }

    .notification-checkbox {
      margin-left: 25px;
    }
  }
}

.notification-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 20px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white;
    color: #3d73dd;
  }

  .create-btn {
    background-color: #0fd280;
    color: white;
  }
}
