.ikt-ap_login-container {
  .ikt-ap_login-card-header {
    padding: 28px 0 24px 0 !important;

    .ikt-ap_login-login-text {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
      margin: 0;
    }
  }

  .ikt-ap_login-card {
    border-radius: 15px !important;
    .ikt-ap_login-card-body {
      background-color: #f2f5f8 !important;
      border-radius: 15px !important;
      padding: 33px 29px 25px 29px;

      .login-card-text {
        display: flex;
        flex-direction: column;
        min-height: 82px !important;
        
        span {
          font-family: "Work Sans";
          font-size: 14px;
          color: #98a9bb;
          line-height: 16px;
          margin-left: 9px;
          padding-bottom: 7px;
        }
        input{
          width: 100% !important;
        }
      }

      .error-text {
        font-family: "Work Sans";
        font-size: 14px;
        color: red;
        font-weight: normal;
        line-height: 16px;
        padding: 0;
        margin: -10px 0 -5px 19px;
        max-width: 265px;
      }

      .login-card-header {
        color: #505665;
        display: flex;
        justify-content: center;
        font-size: 36px;
        font-family: "Work Sans";
        font-weight: bold !important;
        margin-bottom: 30px;
      }
    }

    .ikt-ap_login-card-footer {
      display: flex;
      flex-direction: column;
      .register-text {
        font-family: "Work Sans";
        font-size: 20px;
        font-weight: 600 !important;
        color: #505665;
        line-height: 24px;
      }
      background-color: #dce4f0;
      margin: 0;
      padding: 21px 29px 25px 29px;
      border-radius: 0 0 15px 15px;
    }
  }

  .login-register-user-btn {
    display: flex;
    justify-content: center;

    button {
      color: white;
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600 !important;
      line-height: 14px;
      height: 32px;
      width: 100%;
      padding: 0 40px;
      margin-top: 20px;
    }

    .register-btn {
      background-color: #3d73dd;
    }

    .login-btn {
      background-color: #0fd280;
    }

    .link-text {
      color: #3d73dd;
      font-size: 14px;
      font-family: "Work Sans";
      line-height: 20px;
      padding-top: 27px;
      padding-bottom: 12px;
    }
  }

  .language-selector-container {
    position: absolute;
    top: 30px;
    right: 50px;
    width: 135px !important;
  }
}
