@import '../../assets/scss/material-dashboard-pro-react.scss';

$highlight-color: #eee;

.ikt-ap_custom-autocomplete {
  position: relative;
  .ikt-ap_custom-dropdown-toggle-btn {
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 6px !important;
    font-size: 14px;

    &:hover,
    &:focus {
      border-color: #477eea;
    }
  }

  .dropdown-error {
    border: 1px solid #f84141 !important;
  }

  .ikt-ap_custom-dropdown-menu {
    border-radius: 6px;
    border: 1px solid #477eea;
    position: absolute;
    text-align: left !important;
    min-width: calc(100% + 2px) !important;
    max-width: calc(100% + 2px) !important;
    max-height: 165px;
    overflow-x: hidden;
  }

  .ikt-ap_custom-dropdown-menu::-webkit-scrollbar {
    width: 5px;
  }

  .ikt-ap_custom-dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
  }

  .ikt-ap_custom-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border: 1px solid slategrey;
  }

  .ikt-ap_custom-dropdown-menu-icon {
    left: -8px !important;
  }

  .ikt-ap_custom-dropdown-menu-no-icon {
    left: 5px !important;
  }

  .ikt-ap_custom-dropdown-toggle-btn{
    span {
      display: flex !important;
    }
    .status-title {
      text-align: left;
    }
  }

  .MuiListItem-button {
    color: #000;
    &:hover {
      background-color: $highlight-color !important;
    }
  }
  .ikt-ap_custom-dropdown-item-selected {
    color: #3d73dd !important;
    font-weight: normal !important;
    background-color: $highlight-color !important;
  }

  .ikt-ap_custom-dropdown-item-whitespace-normal {
    white-space: normal !important;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .MuiList-root {
    width: 100%;
    max-height: 100% !important;
    z-index: 200;
    overflow-y: auto;
  }

  @media (max-width: $screen-md-min) {
    div[role="tooltip"] {
      left: 0;
      right: 0;
    }
  }

  div[role="tooltip"] { 
    width: calc(100% - 2px) !important;
    margin-left: -12px !important;
  }

  .alternative-disabled-input-container {
    .ikt-ap_custom-dropdown-toggle-btn {
      color: black !important;
      opacity: 0.65 !important;
      background-color: white !important;
    }
  }
}


