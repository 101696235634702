$animation-duration: 200ms;

.search-outer-container {
  position: relative;
  margin-bottom: -16px;
  height: 44px;

  width: 50px;
  background-color: none;
  border-radius: 0;
  box-shadow: none;

  .search-icon-container {
    position: absolute;
    right: 0;
    margin-top: -4px;
  }
  
  .search-input-container {
    display: none;
    width: 254px;
    
    position: absolute;
    right: -40px;
    top: 2px;

    input {
      display: block;
      width: 214px;
      border: 0;
      font-family: "Work Sans";
      font-size: 16px;
      font-weight: normal;
      color: #555;
      
      position: absolute;
      top: 10px;
      right: 40px;
    }

    input::placeholder {
      color: #b4c6d9;
      font-weight: 600;
      opacity: 1;
    }

    .button-key-return {
      position: absolute !important;
      right: 0;

      .img-key-return {
        margin-top: -6px;
        transform: scale(1.45);
      }
    }
  }
}

.search-outer-container-enter {
  .search-input-container {
    display: block;
  }
}

.search-outer-container-enter-active {
  width: 300px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 4px 4px 2px 0px rgba(0,0,0,0.1), 6px 6px 3px 0px rgba(0,0,0,0.05);

  transition: width $animation-duration, background-color $animation-duration;

  .search-icon-container {
    right: 250px;
  
    transition: right $animation-duration;
  }

  .search-input-container {
    display: block;
    right: 0;

    transition: right $animation-duration;
  }
}

.search-outer-container-enter-done, .search-outer-container-exit {
  width: 300px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 4px 4px 2px 0px rgba(0,0,0,0.1), 6px 6px 3px 0px rgba(0,0,0,0.05);

  .search-icon-container {
    right: 250px;
  }

  .search-input-container {
    display: block;
    right: 0;
  }
}

.search-outer-container-exit-active {
  width: 50px;
  background-color: rgba(0,0,0,0);
  box-shadow: none;

  transition: width $animation-duration, background-color $animation-duration, box-shadow $animation-duration;

  .search-icon-container {
    right: 0;
  
    transition: right $animation-duration;
  }

  .search-input-container {
    display: none;
  }
}

.search-outer-container-exit-done {
  width: 50px;
  background-color: none;
  border-radius: 0;
  box-shadow: none;

  .search-icon-container {
    right: 0;

  }

  .search-input-container {
    display: none;
  }
}
.settings-nav-link {
  text-decoration: none;
  color: #333!important;
}

.search-button-disabled {
  opacity: 1!important;
}