.ikt-ap_order-details-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ikt-app_order-details-content-container {
    height: 100%;

    .ikt-ap_order-details-top-wrapper {
      .ikt-ap_order-details-top-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .ikt-ap_order-details-top-left {
          padding: 0 24px !important;
        }

        .ikt-ap_order-details-top-right {
          display: flex;
          flex-direction: row;
          padding: 0 24px !important;
          margin-left: -6px;
        }
      }
    }

    .order-info-progress-bar-container {
      font-family: 'Work Sans';
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      color: #333333;

      .progress-bar-content {
        font-size: 12px;
        padding: 0 24px !important;
      }

      .information-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 380px;

        .info-item {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          padding: 0 5px !important;

          .info-title {
            font-size: 12px;
          }

          .info-name {
            color: #505665;
            font-size: 14px;
            font-weight: bold;
          }

          .order-status {
            display: flex;
            color: #ffffff;
            align-items: center;
            justify-content: center;
            height: 17px;
            font-size: 14px;
            font-family: 'Work Sans';
            border-radius: 12px;
            width: 100%;
            padding: 9px 8px;
          }

          .order-details-date-picker {
            fieldset {
              border-color: #f2f5f8 !important;
              border-radius: 6px;
              background-color: #f2f5f8;
            }

            input {
              display: none !important;
            }

            button {
              padding: 0 !important;
              margin-top: 48px;
            }
          }

          .first-order-status {
            background-color: #83e2e9 !important;
          }
    
          .second-order-status {
            background-color: #619ffc !important;
          }
    
          .third-order-status {
            background-color: #7bd2b5 !important;
          }
    
          .fourth-order-status {
            background-color: #43bf57 !important;
          }
    
          .fifth-order-status {
            background-color: #f49025 !important;
          }
          
          .sixth-order-status {
            background-color: #f2441a !important;
          }
        }

        .item-order-manager-img {
          padding: 0 !important;

          .order-manager-icon {
            margin: auto;
            width: 40px !important;
            height: 40px !important;
          }
        }

        @media only screen and (max-width: 1600px) {
          ._info-status {
            margin-right: 15px;
          }
        }
        @media only screen and (max-width: 1400px) {
          ._info-status {
            margin-right: 20px;
          }
        }
        @media only screen and (max-width: 1250px) {
          ._info-status {
            margin-right: 30px;
          }
        }
      }
    }

    .order-info-content-container {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .page-ination-container {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
      }

      .pagination-container {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
      }
    }

    .ikt-ap_order-details-btn {
      background-color: #3d73dd !important;
      border-radius: 6px !important;
      min-width: 100px;
      color: white;
      font-size: 12px;
      font-family: 'Work Sans' !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 16px !important;


      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }

    .duplicate-order-btn {
      background-color: #0fd280 !important;
      color: white !important;
      margin-right: 5px;
    }

    .create-btn {
      background-color: #0fd280 !important;
      color: white !important;
    }

    .delete-order-btn {
      background-color: white !important;
      color: #3d73dd !important;
      margin-right: 5px;
    }
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }
}
