.ikt-ap_input-with-icon {
  outline: unset;
  height: 32px;
  padding: 5px 11px 5px 11px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  font-family: 'Work Sans';
  font-size: 14px !important;
  color: #000;
  line-height: 18px;

  p {
    font-weight: bold;
    font-size: 12px;
    color: #b4c6d9;
    margin-top: 2px;
  }

  &:focus {
    border-color: #477eea;
    box-shadow:  0px 0px 2px rgba(61,115,221,0.6)
  }
}

.ikt-ap_input-with-icon:hover {
  border-color: #477eea;
}

.ikt-ap_input-with-icon:focus-within {
  border: 1px solid #477eea !important;
}

.ikt-ap_input-with-icon_error {
  outline: unset;
  height: 32px;
  padding: 5px 11px 5px 11px;
  border-radius: 6px;
  border: 1px solid red;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 18px;
  p {
    font-weight: bold;
    font-size: 12px;
    color: #b4c6d9;
    margin-top: 4px;
  }
}

.ikt-ap_input-with-icon input {
  padding-top: 8px;
}

.ikt-ap_input-with-icon input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.ikt-ap_input-with-icon input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.ikt-ap_input-with-icon input::-ms-input-placeholder { /* Microsoft Edge */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.disabled {
  input {
    caret-color: transparent;
  }
}
