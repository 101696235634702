.import-customer-selection-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.import-customer-selection-content {
  width: 450px;
  padding: 20px 26px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  color: #505665;
  font-weight: bold;
  font-size: 14px;

  .add-customer-btn {
    background-color: #3d73dd !important;
    border-radius: 6px;
    color: white;
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px !important;
    width: 200px;
    padding: 0 15px;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }

  .content-text {
    margin-bottom: 20px
  }

}

.import-customer-selection-content-min-height {
  min-height: 300px;
}

.import-customer-selection-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 34px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
  }

  .create-btn {
    background-color: #0fd280 !important;
    color: white;
  }
}

.missed-materials-mapping-content {
  width: 450px;
  padding: 0 26px 192px 26px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  color: #505665;
  font-weight: bold;
  font-size: 14px;

  .content-text {
    margin-bottom: 10px
  }

  .content-mapping-container {
    .content-mapping-group {
      display: flex;
      flex-direction: row;
      padding: 3px 0;

      .missed-material-label {
        width: 150px;
        padding-top: 5px;
        text-overflow: ellipsis;
      }

      .missed-material-selector {
        margin-left: 10px;
        width: 250px;
      }
    }
  }

}
