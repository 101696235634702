.create-manufacturer-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.create-manufacturer-content {
  width: 1094px;
  height: 760px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: row;
  font-family: "Work Sans";
  padding: 10px 19px 0 19px;

  .create-manufacturer-container {
    display: flex;
    flex-direction: column;

    .manufacturer-logo {
      padding: 0 15px;

      .logo-title {
        font-size: 14px;
        font-weight: 400;
        color: #98a9bb;
        line-height: 16px;
        margin-left: 10px;
      }
    }

    .contact-persons-content {
      max-height: 390px;
      width: 520px;
      position: relative;
      padding-left: 8px;
      margin-top: 10px;
      margin-left: 8px;
      .contact-person-content {
        background-color: rgba(61, 115, 221, 0.1) !important;
        border-radius: 10px !important;
        font-family: "Work Sans";
        width: 96.5%;
        margin-bottom: 10px;
        padding-right: 20px;
        position: relative;

        .contact-person-info {
          padding: 18px 8px !important;

          .contact-person-header {
            display: flex;
            justify-content: space-between;
            .person-title {
              display: flex;
              align-items: center;
              font-size: 20px;
              font-weight: bold;
              color: #505665;
              line-height: 24px;
              padding-left: 8px;
            }
          }

          .contact-person-info-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .contact-info-item {
              display: flex;
              flex-direction: row;
              padding: 0 !important;
            }

            .contact-info {
              display: flex;
              flex-direction: column;
              padding: 0 8px !important;
              span{
                color: #98a9bb;
                font-size: 14px;
                padding-left: 9px;
                padding-bottom: 3px;
              }
              input {
                margin-bottom: 6px;
              }
            }

            .contact-info-col2 {
              width: 50%;
            }
          }
        }

        .card-btn {
          background-color: rgba(61, 115, 221, 0.5);
          border-radius: 0 10px 10px 0;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          align-items: center;
          width: 26px;
          height: 100%;

          div {
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            svg {
              color: #3d73dd;
            }
          }
        }

        .card-btn:hover {
          background-color: #b0c1dc;
        }
      }

    }
  }

  .manufacturer-data-row-container {
    display: flex;
    flex-direction: row;
    min-width: 527px;
  }

  .manufacturer-data-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;

    .autocomplete-size-edit,
    .dropdown-size-edit {
      .ikt-ap_custom-dropdown-menu {
        max-height: 265px !important;
      }
    }

    .autocomplete-size-create,
    .dropdown-size-create {
      .ikt-ap_custom-dropdown-menu {
        max-height: 165px !important;
      }
    }

    input {
      width: 100%;
    }

    .item-title {
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 10px;
    }

    .delivery-options {
      display: flex;
      flex-direction: row;
      span {
        font-family: "Work Sans" !important;
        color: #98a9bb;
        margin-left: 0 !important;
      }

      .MuiFormControlLabel-label {
        color: #505665;
        font-size: 14px;
        line-height: 16px;
        margin-right: 15px;
      }

      .MuiCheckbox-colorSecondary:hover {
        background-color: rgba(232, 232, 232, 0.8);
      }

      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
      }

      .MuiFormGroup-root {
        flex-direction: row !important;
      }
    }

    .existing-email {
      margin-left: 15px
    }

    .time-selection {
      display: flex;
      flex-direction: row;

      .dropdown-blue {
        width: 100%;
      }

      .time-divider {
        font-size: 35px;
        color: black;
        margin-top: 3px;
      }

      .dropdown-style {
        background-color: white !important;
        border-radius: 6px !important;
        display: flex !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        color: black !important;
        margin-top: 0 !important;
        height: 32px;
        box-shadow: none !important;
        justify-content: space-between !important;
        text-transform: none !important;

        span {
          font-size: 14px;
          line-height: 16px;
        }

        b {
          color: #3d73dd;
        }
      }
    }

    .billing-address {
      height: auto !important;
    }

    .working-hours-container {
      .ikt-ap_custom-dropdown-menu {
        max-height: 60px !important;
      }
    }
  }

  .checkbox-item {
    margin-bottom: 0 !important;
  }

  .discount-item {
    margin-top: 3px !important;
  }

  .status-container {
    display: flex;
    justify-content: flex-end;

    .status-dropdown {
      .status-title {
        display: block;
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      span {
        color: white;
        font-family: "Work Sans";
      }

      button {
        height: 23px;
        width: 130px !important;
        padding: 0 5px !important;
        border-radius: 5px !important;
      }

    }

    .red {
      color: #f84141;
      button {
        background-color: #f84141 !important;
      }
      .CircularProgressbar-path {
        stroke: #f84141 !important;
      }
    }

    .green {
      color: #0fd280;
      button {
        background-color: #0fd280 !important;
      }
      .CircularProgressbar-path {
        stroke: #0fd280 !important;
      }
    }
  }

  .add-person-btn {
    display: flex;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 15px;
    button {
      background-color: #3d73dd !important;
      color: white;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 212px;
      padding: 0 10px;
    }
  }
}

.manufacturer-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 20px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
  }

  .create-btn {
    background-color: #0fd280 !important;
    color: white;
  }
}
