.sidebar-popper-container {
  position: absolute;
  color: red;
  z-index: 10000 !important;
  background-color: white;
  border-radius: .4em;
  padding: 8px 13px 2px 13px;
  transform: translate(0, -50%);
  box-shadow: 4px 4px 2px 0px rgba(0,0,0,0.1), 6px 6px 3px 0px rgba(0,0,0,0.05);
}

.sidebar-popper-container:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right-color: white;
	border-left: 0;
	margin-top: -10px;
  margin-left: -10px;
}

.hidden {
  display: none;
}

.sidebar-popper-container a {
  color: #535967;
}

.sidebar-popper-container a:hover,
.sidebar-popper-container a.active
{
  color: #3d73dd;
}
