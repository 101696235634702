@import "../../assets/scss/material-dashboard-pro-react.scss";

.project-invoices-outer-container {
  margin: 0;
  padding: 0;

  .project-invoices-container {
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding: 0 24px;
    margin-top: 10px;
    .project-invoices-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 !important;

      .invoices-top-right-bar {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .my-date-picker {
          margin-right: 4px !important;
          margin-top: 5px !important;
          min-width: 100%;
          max-width: 100%;

          input {
            font-size: 14px !important;
          }
        }

        .create-invoice-btn,
        .create-invoice-btn-dropdown {
          border-radius: 6px !important;
          color: white;
          font-size: 12px;
          font-family: "Work Sans" !important;
          font-weight: bold !important;
          line-height: 14px;
          height: 32px !important;
          padding: 0 23px !important;

          .MuiButton-label {
            display: flex;
            align-items: center;

            .material-icons.MuiIcon-root {
              font-weight: bold;
              font-size: 11px;
              margin-right: 1px;
            }
          }
        }

        .create-invoice-btn,
        .create-invoice-btn-dropdown {
          background-color: #0fd280 !important;
        }

        .create-dropdown-button-child {
          margin: 0;
          width: 170px !important;
        }

        .create-invoice-btn-dropdown {
          width: 170px !important;
        }
      }
    }

    .invoices-header {
      display: flex;
      margin: 0 auto !important;
      margin-top: 25px !important;
      border-bottom: 2px solid #3d73dd;
      position: relative;

      .invoices-header-title {
        display: flex;
        justify-content: flex-start;
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: 600;
        color: #333333;
        line-height: 14px;
        text-align: center;
        padding: 0 8px 4px 8px !important;
      }

      .col-sort-active {
        color: #3d73dd !important;
  
        img { // arrow icon
          display: inline-block;
          margin-left: 2px;
          margin-top: 0;
          width: 12px;
          height: 6px;
        }
      }

      .right-aligned {
        justify-content: flex-end !important;
      }
    }

    .invoices-content {
      display: flex;
      margin: 0 auto 15px auto !important;

      .invoices-content-no-records {
        width: 100%;
        display: flex;
        justify-content: center;

        .no-records {
          margin: 20px auto 0 auto;
          font-size: 12px;
          font-family: "Work Sans";
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          text-align: center;
        }
      }

      .invoices-item {
        border-bottom: 1px solid #b4c6d9;
        display: flex;
        margin: 0 auto !important;
        height: 50px;
        position: relative;

        .invoices-item-title {
          display: flex;
          justify-content: flex-start;
          font-size: 14px;
          font-family: "Work Sans";
          color: #505665;
          line-height: 14px;
          text-align: center;
          padding: 18px 8px 4px 8px !important;

          white-space: nowrap;
        }

        .ts-col-status {
          padding-top: 6px !important;

          .ikt-ap_custom-dropdown {
            max-width: 100%;

            button {
              max-width: 100%;
              max-height: 28px !important;

              .MuiButton-label {
                color: white;
              }
            }
          }
        }

        .ts-col-action-btn {
          padding-right: 4px !important;
        }

        .orange {
          button {
            color: white !important;
            background-color: #FF4C4C !important;
          }
          .CircularProgressbar-path {
            stroke:#FF4C4C !important;
          }
        }
  
        .kellyGreen {
          button {
            color: white !important;
            background-color: #0fd280 !important;
          }
          .CircularProgressbar-path {
            stroke: #0fd280 !important;
          }
        }

        .cyan {
          button {
            color: white !important;
            background-color: #00ffff !important;
          }
          .CircularProgressbar-path {
            stroke: #00ffff !important;
          }
        }
  
        .blue {
          button {
            color: white !important;
            background-color: #3d73dd !important;
          }
          .CircularProgressbar-path {
            stroke: #3d73dd !important;
          }
        }
  
        .gray {
          button {
            background-color: #b4c6d9 !important;
          }
          .CircularProgressbar-path {
            stroke: #b4c6d9 !important;
          }
        }

        .invoices-action-btn {
          padding-top: 15px !important;
        }

        .right-aligned {
          justify-content: flex-end !important;
        }

        .bold {
          font-weight: 600;
        }

        .blue {
          color: #3d73dd
        }

        .action-btn {
          color: gray;
          padding: 0px !important;
          margin: 0 8px;
          height: 20px;
          width: 20px;

          img {
            max-width: 23px;
            min-width: 23px;
          }
        }

        .action-btn:last-of-type {
          margin-right: 5px !important;
        }
      }
      .dark {
        background-color:  rgba(180, 198, 217, 0.1);
      }
    }

    .ts-col-date {
      min-width: 100px !important;
      max-width: 100px !important;
    }

    .ts-col-number {
      min-width: 9.5% !important;
      max-width: 9.5% !important;
    }

    .ts-col-type {
      min-width: 11% !important;
      max-width: 11% !important;
    }

    .ts-col-client {
      min-width: 9% !important;
      max-width: 9% !important;
    }

    .ts-col-payment-terms {
      min-width: 11% !important;
      max-width: 11% !important;
    }

    .ts-col-total-neto {
      min-width: 8.75% !important;
      max-width: 8.75% !important;
    }

    .ts-col-billing-peculiarities {
      min-width: 11% !important;
      max-width: 11% !important;
    }

    .ts-col-brutto {
      min-width: 7% !important;
      max-width: 7% !important;
    }

    .ts-col-total {
      min-width: 7% !important;
      max-width: 7% !important;
    }

    .ts-col-status {
      min-width: 125px !important;
      max-width: 125px !important;
    }

    .ts-col-action-btn {
      min-width: 180px !important;
      max-width: 180px !important;
  
      position: absolute !important;
      right: 0 !important;
    }

    @media (max-width: 1600px) {
      .ts-col-billing-peculiarities {
        display: none !important;
      }

      .ts-col-number {
        min-width: 10.5% !important;
        max-width: 10.5% !important;
      }

      .ts-col-type {
        min-width: 11.5% !important;
        max-width: 11.5% !important;
      }

      .ts-col-client {
        min-width: 10.5% !important;
        max-width: 10.5% !important;
      }
    }

    @media (max-width: 1300px) {
      .ts-col-billing-peculiarities,
      .ts-col-type {
        display: none !important;
      }

      .ts-col-number {
        min-width: 10.75% !important;
        max-width: 10.75% !important;
      }

      .ts-col-client {
        min-width: 13% !important;
        max-width: 13% !important;
      }
    }

    @media (max-width: $screen-md-min) {
      .ts-col-total-neto,
      .ts-col-billing-peculiarities,
      .ts-col-brutto,
      .ts-col-type,
      .ts-col-payment-terms {
        display: none !important;
      }

      .ts-col-date {
        min-width: 80px !important;
        max-width: 80px !important;
      }
  
      .ts-col-number {
        min-width: 14.5% !important;
        max-width: 14.5% !important;
      }
  
      .ts-col-client {
        min-width: 14% !important;
        max-width: 14% !important;
      }
  
      .ts-col-total {
        min-width: 12% !important;
        max-width: 12% !important;
      }
    }
  }

  .download-list {
    position: relative;
    top: -7px;
    right: -5px;
    list-style-type: none;
    background-color: white;
    border: 1px solid #3d73dd;
    border-radius: 5px;
    padding: 5px 7px;

    li {
      cursor: pointer;
      color: black;
      font-weight: 500;
      font-family: "Work Sans";
      padding: 3px 0;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
