.fitting-position-content {
  margin-bottom: 10px;
  font-family: "Work Sans";

  .fitting-position-info {
    background-color: rgba(61, 115, 221, 0.1);
    display: flex;
    justify-content: space-between;
    border-radius: 6px;

    .fitting-position-info-title {
      display: flex;
      justify-content: space-between;
      padding: 15px 26px 15px 9px !important;
      width: 100%;

      .fitting-position-title {
        font-size: 20px;
        font-weight: bold;
        color: #505665;
        line-height: 24px;
      }
    }
    .fitting-card-btn-container {
      display: flex;
      padding: 0px !important;
      justify-content: flex-end;

      .card-btn {
        background-color: rgba(61, 115, 221, 0.1);
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        width: 26px !important;
        svg {
          color: #3d73dd;
        }
      }

      .bottom-borders {
        border-bottom-right-radius: 0;
      }
    }
  }

  .fitting-bottom-borders {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fitting-position-info-content {
    background-color: rgba(216, 224, 232, 0.3);
    padding: 10px !important;

    .fitting-position-header {
      padding: 0 10px;
      margin-bottom: 5px;

      .grid-item {
        padding-left: 5px !important;
        overflow: hidden !important;
      }
    }

    .fitting-positions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .create-fitting {
        display: flex;
      }
    }

    .fitting-container {
      background-color: rgba(216, 224, 232, 0.3) !important;
      padding: 10px;
      border-radius: 7px;
      margin-bottom: 5px;
      width: 100%;
    }

    .grid-item {
      padding: 0 !important;
      margin: 0;
      margin-right: 8px;
    }

    .grid-item:last-of-type {
      margin-right: 0;
    }

    .fc-metric {
      .ikt-ap_custom-dropdown-toggle-btn {
        max-width: 100%;
        margin-top: 0 !important;
      }
    }

    @media (max-width: 800px) {
      .fc-article {
        width: calc((100% - 130px - 40px) * 0.34);
      }
  
      .fc-amount {
        width: calc((100% - 130px - 40px) * 0.0725);
      }
  
      .fc-metric {
        width: calc((100% - 130px - 40px) * 0.0725);
      }
  
      .fc-price {
        width: calc((100% - 130px - 40px) * 0.125);
      }

      .fc-total-price {
        width: calc((100% - 130px - 40px) * 0.125);
      }
  
      .fc-description {
        width: calc((100% - 130px - 40px) * 0.21);
      }
    }

    @media (min-width: 801px) {
      .fc-article {
        width: calc((100% - 130px - 40px) * 0.345);
      }
  
      .fc-amount {
        width: calc((100% - 130px - 40px) * 0.0775);
      }
  
      .fc-metric {
        width: calc((100% - 130px - 40px) * 0.0775);
      }
  
      .fc-price {
        width: calc((100% - 130px - 40px) * 0.125);
      }

      .fc-total-price {
        width: calc((100% - 130px - 40px) * 0.125);
      }
  
      .fc-description {
        width: calc((100% - 130px - 40px) * 0.215);
      }
    }

    @media (min-width: 1200px) {
      .fc-article {
        width: calc((100% - 130px - 40px) * 0.37);
      }
  
      .fc-amount {
        width: calc((100% - 130px - 40px) * 0.0775);
      }
  
      .fc-metric {
        width: calc((100% - 130px - 40px) * 0.0775);
      }
  
      .fc-price {
        width: calc((100% - 130px - 40px) * 0.125);
      }

      .fc-total-price {
        width: calc((100% - 130px - 40px) * 0.125);
      }
  
      .fc-description {
        width: calc((100% - 130px - 40px) * 0.21);
      }
    }

    .fc-description, .fc-price, .fc-total-price {
      .ikt-ap_input-with-icon {
        width: 100%;
      }
    }

    .fc-actions {
      width: 130px;
      padding-left: 10px !important;

      .action-btn {
        padding: 5px !important;
        margin-right: 10px;
      }

      .action-btn:last-of-type {
        margin-right: 0;
      }
    }
  }

  .create-fitting-btn {
    background-color: #3d73dd !important;
    border-radius: 6px;
    color: white !important;
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px !important;
    width: 155px;
    padding: 0 15px;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }
}
