.project-progress-container {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  font-family: "Work Sans";
  .project-progress-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .progress-positions {
      display: flex;
      align-items: center;
      color: #505665;
      font-size: 20px;
      font-weight: 600;
      padding: 0 9px !important;
    }

    .progress-top-right-bar {
      display: flex;
      flex-direction: row;
      padding: 0 9px !important;

      .checkbox-title {
        align-self: center;
        color: #505665;
        font-size: 14px;
        padding-right: 11px;
        min-width: 122px;
      }
    }

    .ikt-ap_order-add-position-btn {
      background-color: #0fd280 !important;
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: "Work Sans" !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;
      margin-left: 9px;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }
  }

  .progress-positions-header {
    display: flex;
    flex-direction: row;
    color: #333333;
    font-size: 12px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 10px;
    padding: 0 39px !important;
  }
  .progress-content-no-records {
    width: 100%;
    display: flex;
    justify-content: center;
    .no-records {
    margin: 20px auto 0 auto;
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    text-align: center;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
}

.progress-position {
  background-color: white;
  border-radius: 5px;
  color: #505665;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  margin: 6px 24px !important;
  min-height: 52px;
  align-items: center;
  .assignee-name {
    color: #3d73dd;
    cursor: pointer;
  }

  .progress-container {
    position: relative;

    .set-progress-card {
      background-color: white;
      border-radius: 5px;
      border: 1px solid #b4c6d9;
      box-shadow: 0px 7px 4px rgba(220,228,240,1);
      padding: 5px 0 !important;
      position: absolute;
      height: 121px;
      width: 133px;
      z-index: 342;
      .set-progress-actions {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: normal;
        justify-content: space-between;
        margin: 15px 0;

        .progress-change-btn {
          color: #3d73dd;
          cursor: pointer;
          font-size: 25px;
        }
      }

      .set-progress-btn-container {
        padding: 0 10px !important;

        .set-btn {
          background-color: #3d73dd;
          color: white;
          height: 32px;
          width: 109px;
          button {
            padding: 0!important;
          };
        }
      }
    }
  }

  .user-view-btn {
    button {
      padding: 0 !important;
      margin: 12px
    }
  }
}

.pp-col-name {
  min-width: 28%;
  max-width: 20%;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.pp-col-assignee {
  min-width: 17.5%;
  max-width: 17.5%;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.pp-col-progress {
  min-width: 21%;
  max-width: 21%;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.pp-col-deadline {
  min-width: 17%;
  max-width: 17%;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.pp-col-actions {
  min-width: 10%;
  max-width: 130px;
  margin-left: 5px !important;
  margin-right: 5px !important;

  transform: translateX(-25%);

  text-align: right;
  position: absolute;
  right: 0;
}