.ikt-ap_textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.ikt-ap_textarea {
  outline: unset;
  resize: none;
  width: 100%;
  height: 100%;
  padding: 9px 11px 9px 11px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.ikt-ap_textarea_error {
  outline: unset;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 9px 11px;
  border-radius: 6px;
  border: 1px solid #f84141;
  background-color: #ffffff;
  font-family: 'Work Sans';
  font-size: 14px;
  color: #000;
  line-height: 18px;
}

.ikt-ap_textarea-error-msg {
  font-size: 14px !important;
  color: #f84141 !important;
  padding-left: 9px;
  position: absolute;
  top: 35px;
  width: max-content;
}

.ikt-ap_textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.ikt-ap_textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.ikt-ap_textarea::-ms-input-placeholder { /* Microsoft Edge */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}
