.create-category-dialog {
  overflow-y: visible !important;

  .create-category-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;
    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .create-category-content {
    width: 355px;
    height: 442px;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding-top: 30px;

    .category-info-item {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      margin-bottom: 5px;
      input {
        width: 100%;
      }

      .parent-category-dropdown {
        max-height: 160px;
      }

      .category-info-item-title {
        font-size: 14px;
        font-weight: 400;
        color: #98a9bb;
        line-height: 16px;
        margin-left: 10px;
      }

      .category-item {
        display: flex;
        flex-direction: column;
      }

      .dropdown-style {
        background-color: white !important;
        border-radius: 6px !important;
        box-shadow: none !important;
        display: flex !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        color: black !important;
        margin-bottom: 5px !important;
        margin-top: 0px !important;

        width: 100% !important;
        height: 32px;
        box-shadow: none !important;
        justify-content: space-between !important;
        text-transform: none !important;

        span {
          font-size: 14px;
          line-height: 16px;
          margin-left: 0px !important;
          color: black !important;
        }

        b {
          color: #3d73dd;
        }
      }

      .description-text {
        background-color: white;
        width: 100%;
        padding: 0 !important;
      }
    }
  }

  .category-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 20px;

    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }

    .cancel-btn {
      background-color: white !important;
      color: #3d73dd;
    }

    .create-btn {
      background-color: #0fd280 !important;
      color: white;
    }
  }
}
