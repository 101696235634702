.ikt-ap_timesheets-list-container {
  min-height: calc(100vh - 90px);
  position: relative;

  .ikt-ap_timesheets-list-top-wrapper {
    .ikt-ap_timesheets-list-top-container {
      display: flex;
      justify-content: space-between;
      padding: 0 9px !important;

      .ikt-ap_timesheets-list-top-left {
        color: #505665;
        font-size: 20px;
        font-weight: 600;
        margin-top: 5px;

        .time-period {
          cursor: pointer;
        }
      }

      .ikt-ap_timesheets-list-top-right {
        display: flex;
        flex-direction: row;
        margin-left: -6px;
        padding: 0 !important;
      }
    }

    .ikt-ap_timesheets-list-hours-container {
      margin-top: 15px;
      padding: 0 24px;

      .ikt-ap_timesheets-list-hours {
        background-color: white;
        border-radius: 6px;
        height: 103px;
        width: 100%;
        padding: 12px !important;

        box-shadow: 0 10px 30px -30px #0acf05;

        .hours-logged {
          font-family: "Work Sans";
          border-left: 5px solid #0acf05;
          border-radius: 5px;
          padding-left: 15px;
          height: 100%;
          padding-top: 16px;

          .hours-logged-value {
            display: block;
            font-size: 34px;
            font-weight: bold;
          }
  
          .hours-logged-label {
            display: block;
            font-size: 16px;
            font-weight: bold;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .timesheets-content {
    position: relative;

    .time-sheet-tabs-container {
      position: absolute;
      margin-left: 24px;
      margin-top: 15px;
    }
  }
}

.manual-timer-finances {

  .MuiPaper-rounded {
    border-radius: 12px;
  }

  .manual-timer-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;
    
    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .manual-timer-content {
    background-color: #f2f5f8;
    font-family: "Work Sans";
    width: 720px;
    height: 530px;
    padding: 25px 34px;

    .time-entries-content {
      max-height: 440px;
      width: 656px;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden !important;

      .time-entry-content {
        background-color: rgba(61, 115, 221, 0.1) !important;
        border-radius: 10px !important;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-family: "Work Sans";
        width: 645px;
        margin-bottom: 10px;

        .time-entry-info {
          padding: 17px 8px !important;

          .time-entry-title {
            display: flex;
            justify-content: space-between;
            .entry-title {
              font-size: 20px;
              font-weight: bold;
              color: #505665;
              line-height: 24px;
              padding-left: 8px;
            }

            .remove-btn {
              padding: 6px;
            }
          }

          .time-entry-info-content {
            display: flex;
            flex-direction: column;

            .timer-entry-data-item {
              display: flex;
              flex-direction: column;
              padding: 0 6px;
              
              .timer-entry-data-item-title {
                color: #98a9bb !important;
                padding-left: 5px;
                margin-bottom: 4px;
              }
            }

            .timer-entry-type {
              padding: 0 8px;

              .timer-type-items {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 4px;
                
                .timer-type {
                  display: flex;
                  cursor: pointer;
                  justify-content: center;
                  align-items: center;
                  background-color: #e9eef3;
                  border-radius: 6px;
                  color: #505665;
                  height: 32px;
                  width: 286px;
                  margin: 0 4px;
                  text-align: center;
                }

                .selected {
                  background-color: #3d73dd;
                  color: #ffffff;
                }
              }

              span {
                color: #98a9bb !important;
                padding-left: 10px;
              }
            }

            .timer-date-picker-container {
              display: flex;
              flex-direction: row;
              padding: 0 8px;
              margin-top: 12px;

              .timer-entry-date-picker {
                div {
                  border-radius: 6px !important;
                }
                input {
                  height: 18px;
                  width: 226px;
                }
              }

              .time-hours {
                width: 294px;
              }
            }

            .timer-dropdowns,
            .timer-dropdowns-alt {
              display: flex;
              flex-direction: row;
              padding: 0 8px;
              margin-top: 12px;
            }

            .timer-dropdowns {
              flex-direction: column;

              .timer-dropdowns-row {
                display: flex;
                flex-direction: row;
                margin-top: 10px;

                .timer-entry-data-item:first-of-type {
                  .ikt-ap_custom-dropdown-toggle-btn {
                    min-width: 270px !important;
                    max-width: 270px !important;
                  }

                  button {
                    margin: 0 !important;
                    width: 270px !important;
                  }
    
                  ul {
                    max-width: 270px !important;
    
                    li {
                      text-overflow: ellipsis;
                    }
                  }
                }

                .timer-entry-data-item:last-of-type {
                  button {
                    margin: 0 !important;
                    width: 282px !important;
                  }
    
                  ul {
                    max-width: 282px !important;
    
                    li {
                      text-overflow: ellipsis;
                    }
                  }
                }
              }

              .timer-dropdowns-row:first-of-type {
                margin-top: 0 !important;
              }
            }

            .timer-dropdowns-alt {

              .timer-entry-data-item:first-of-type {
                button {
                  margin: 0 !important;
                  width: 270px !important;
                }

                ul {
                  max-width: 270px !important;

                  li {
                    text-overflow: ellipsis;
                  }
                }
              }

              .timer-entry-data-item:last-of-type {
                button {
                  margin: 0 !important;
                  width: 282px !important;
                }

                ul {
                  max-width: 282px !important;

                  li {
                    text-overflow: ellipsis;
                  }
                }
              }
            }

            .ikt-ap_custom-dropdown-menu {
              max-height: 140px;
            }
            
            .time-entry-dropdown {
              max-height: 90px;
            }

            .timer-entry-note {
              padding: 0 8px;
              margin-top: 12px;
            }
          }
        }
        .card-btn {
          background-color: rgba(61, 115, 221, 0.5);
          border-radius: 0 10px 10px 0;
          display: flex;
          align-items: center;
          width: 26px;
          svg {
            color: #3d73dd;
          }
        }

        .card-btn:hover {
          background-color: #b0c1dc;
        }
      }
    }

    .time-entries-content::-webkit-scrollbar {
      width: 5px;
    }

    .time-entries-content::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }

    .time-entries-content::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border: 1px solid slategrey;
    }
  }

  .manual-timer-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 15px 33px;

    .cancel-btn {
      background-color: white !important;
      color: #3d73dd !important;
    }

    .create-btn {
      background-color: #0fd280 !important;
      color: white !important;
    }
  }

  .manual-timer-btn {
    background-color: #3d73dd !important;
    border-radius: 6px !important;
    min-width: 100px;
    color: white;
    font-size: 12px;
    font-family: "Work Sans" !important;
    font-weight: bold !important;
    line-height: 14px;
    height: 32px !important;
    padding: 0 16px !important;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }
}

.ikt-ap_loader {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 101px);
  width: 100%;
  margin-top: 100px;
}
