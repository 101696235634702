.edges-structure-container {
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 60px;

  .selected {
    background-color: #3d73dd !important;
  }

  .diagonalRight {
    background: linear-gradient(45deg, #3d73dd 50%, #b4c6d9 50%);
  }

  .diagonalLeft {
    background: linear-gradient(45deg, #b4c6d9 50%, #3d73dd 50%);
  }

  .diagonalTop {
    background: linear-gradient(-45deg, #3d73dd 50%, #b4c6d9 50%);
  }

  .diagonalBottom {
    background: linear-gradient(-45deg, #b4c6d9 50%, #3d73dd 50%);
  }

  .structure-top-container {
    display: flex;
    flex-direction: row;
    height: 15px;

    .side {
      background-color: #b4c6d9;
      width: 34px;
    }

    .top {
      border-bottom: 1px solid black;
    }

    .structure-button {
      width: 15px;
      background-color: #b4c6d9;
    }

    .top-left-full {
      background-color: #3d73dd;
      border-right: 1px solid black;
    }

    .top-left-diagonal {
      position: relative;
      background-color: #3d73dd;
    }

    .top-left-diagonal-line {
      height: 19px;
      width: 1px;
      transform: rotate(135deg);
      background-color: black;
      bottom: -2px;
      position: absolute;
      left: 7px;
    }

    .top-left-empty {
      background-color: #3d73dd;
      border-bottom: 1px solid black;
    }

    .top-right-full {
      background-color: #3d73dd;
      border-left: 1px solid black;
    }

    .top-right-diagonal {
      position: relative;
      background-color: #3d73dd;
    }

    .top-right-diagonal-line {
      height: 19px;
      width: 1px;
      transform: rotate(45deg);
      background-color: black;
      bottom: -2px;
      position: absolute;
      left: 7px;
    }

    .top-right-empty {
      background-color: #3d73dd;
      border-bottom: 1px solid black;
    }
  }

  .structure-middle-container {
    display: flex;
    flex-direction: row;
    height: 31px;

    .side-title {
      background-color: #ffffff;
      color: #505665;
      cursor: pointer;
      width: 34px;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: bold;
      user-select: none;
    }

    .side {
      width: 15px;
      background-color: #b4c6d9;
    }

    .left-side {
      border-right: 1px solid black;
    }

    .right-side {
      border-left: 1px solid black;
    }
  }

  .structure-bottom-container {
    display: flex;
    flex-direction: row;
    height: 15px;

    .side {
      width: 34px;
      background-color: #b4c6d9;
    }

    .bottom {
      border-top: 1px solid black;
    }

    .structure-button {
      width: 15px;
      background-color: #b4c6d9;
    }

    .bottom-left-full {
      background-color: #3d73dd;
      border-right: 1px solid black;
    }

    .bottom-left-diagonal {
      position: relative;
      background-color: #3d73dd;
    }

    .bottom-left-diagonal-line {
      height: 19px;
      width: 1px;
      transform: rotate(45deg);
      background-color: black;
      bottom: -2px;
      position: absolute;
      left: 7px;
    }

    .bottom-left-empty {
      background-color: #3d73dd;
      border-top: 1px solid black;
    }

    .bottom-right-full {
      background-color: #3d73dd;
      border-left: 1px solid black;
    }

    .bottom-right-diagonal {
      position: relative;
      background-color: #3d73dd;
    }

    .bottom-right-diagonal-line {
      height: 19px;
      width: 1px;
      transform: rotate(135deg);
      background-color: black;
      bottom: -2px;
      position: absolute;
      left: 7px;
    }

    .bottom-right-empty {
      background-color: #3d73dd;
      border-top: 1px solid black;
    }
  }
}
