.dropdown-blue-style {
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  display: flex !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  color: black !important;
  margin-right: 5px !important;
  width: 150px !important;
  height: 32px;
  justify-content: space-between !important;

  span {
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 100;
    line-height: 16px;
    text-transform: none;
  }

  b {
    color: #3d73dd;
  }
}

.add-product-btn {
  background-color: #0fd280 !important;
  box-shadow: none !important;
  width: 102px !important;
  height: 32px !important;
  border-radius: 10px !important;
}

.products-dropdowns-grid-item {
  display: flex;
}

.products-header {
  display: flex;
  margin: 0 39px !important;
  margin-top: 25px !important;
  border-bottom: 2px solid #3d73dd;

  .products-header-title {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: 600;
    color: #333333;
    line-height: 14px;
    text-align: center;
    padding: 4px 5px !important;
  }
}

.products-content {
  display: flex;
  margin: 0 39px !important;

  .products-item {
    background-color: rgba(180, 198, 217, 0.1);
    border-bottom: 1px solid #b4c6d9;
    display: flex;
    margin: 0 auto !important;
    min-height: 86px;

    .products-item-title {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: center;
      padding-top: 19px !important;
      padding-bottom: 4px !important;

      .product-color {
        display: block;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: #0d47a1;
        margin: 0 10px;
      }

      .stock-dropdown {
        background-color: #0fd280 !important;
        height: 23px !important;
        padding: 2px 10px !important;
        border-radius: 6px !important;

        span {
          color: #ffffff !important;
        }
      }
    }

    .flex {
      display: flex;
    }

    .action-btn {
      color: gray;
      padding: 0px !important;
      margin: 0 5px;
    }

    .product-img {
      background-color: gray;
      height: 62px !important;
      border-radius: 4px;
      margin-top: 10px;
      padding: 0 !important;
    }

    .availability {
      display: flex;
      justify-content: center;
      margin-top: -10px;
    }
  }

  .dark {
    background-color:  rgba(180, 198, 217, 0.1);
  }
}
