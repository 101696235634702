@import '../../assets/scss/material-dashboard-pro-react.scss';

.create-material-dialog {

  .create-material-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;

    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .create-material-content {
    width: 869px;
    height: 465px !important;
    background-color: #f2f5f8;
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding: 0 35px !important;

    .material-info {
      display: flex;
      flex: 1;
      flex-direction: row;

      padding-top: 28px;
      .info-title {
        color: #98a9bb;
        font-size: 14px;
        padding-left: 10px;
        padding-bottom: 4px;
        padding-top: 4px;
      }

      button {
        width: 260px !important;
        margin: 0 !important;
      }

      .material-name {
        display: flex;
        flex-direction: column;

        .material-thickness-unit {
          display: flex;
          flex-direction: row;

          .material-thickness {
            display: flex;
            flex-direction: column;
            input {
              width: 132px;
            }
          }

          .material-unit {
            display: flex;
            flex-direction: column;
            margin-left: 5px;

            button {
              width: 122px !important;
            }
          }
        }
      }

      .material-price {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        .purchasing-price-input {
          width: 260px !important;
        }

        .text-input {
          display: flex;
          flex-direction: row;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #505665;
            line-height: 16px;
            width: 95px;
            margin-top: 10px;
            margin-left: 11px;
          }
        }

        .retail-price {
          display: flex;
          color: #505665 !important;
          font-size: 14px;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 25px;

          span {
            color: #505665 !important;
          }

          .price {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }

      .material-img {
        margin-left: 15px;

        .ikt-ap_custom-dropdown {

          div {
            max-width: 99% !important;
            min-width: 99% !important;
          }
        }

        .edge-checkbox {
          margin-top: -4px;

          svg {
            fill: gray;
          }
          span {
            font-family: "Work Sans" !important;
            font-size: 14px !important;
            color: #505665 !important;
            line-height: 16px !important;
          }
          span:hover {
            background-color: rgba(180, 198, 217, 0.1) !important;
          }
        }

        .edge-checkbox-checked {
          svg {
            fill: #4277de !important;
          }
        }

        .description-container {

          textarea {
            display: block;
            font-family: "Work Sans";
            margin-top: 5px;
            margin-right: 0 !important;
            padding: 10px;
            width: 97%;
            height: 102px !important;
            border-radius: 15px !important;
            border: 0 !important;
            outline: none !important;
            resize: none;
          }

          .description-text {
            background-color: none;
            width: 260px;
            margin-top: 3px !important;

            div {
              padding: 10px;

              border: 0 !important;
              outline: none !important;
              border-radius: 10px !important;
              background-color: white;

              textarea {
                font-size: 14px !important;
                height: 85px !important;
              }
            }
          }
        }

        .stock-dropdown {
          background-color: #0fd280 !important;
          height: 23px !important;
          padding: 2px 10px !important;
          border-radius: 6px !important;

          span {
            color: #ffffff !important;
          }
        }

        .out-of-stock {
          background-color: #f84141 !important;
        }

        .upload-img {
          .logo-upload {
            width: 260px !important;
            margin-bottom: 10px;
          }
        }
      }
    }

    .material-suppliers {
    }
  }

  .material-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 20px;

    button {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }

    .cancel-btn {
      background-color: white;
      color: #3d73dd;
    }

    .create-btn {
      min-width: 150px !important;
      background-color: #0fd280;
      color: white;
    }
  }

  .form-control-edge {
    margin-top: 14px !important;
    margin-left: 3px;

    .MuiFormControlLabel-label {
      font-family: "Work Sans" !important;
      font-size: 14px !important;
      color: #505665 !important;
      line-height: 16px !important;
    }
  }

  .tags-grid {
    margin-top: 5px;
    min-height: 120px !important;

    .ikt-ap_custom-dropdown-menu {
      height: 100px !important;
    }

    .tags-grid-item {
      padding: 0 3px !important;

      .tags-container {
        width: 100%;

        input {
          font-family: "Work Sans";
          font-size: 14px;
        }
      }

      .tags-list {
        margin-top: 7px !important;
        max-height: 48px !important;
      }
    }
  }

  .input-with-icon-special {

    input {
      font-family: "Work Sans" !important;
      font-size: 14px !important;
    }
  }

  .thickness-input {
    max-width: 132px !important;
  }

  .price-category-special-input {
    max-width: 155px !important;
    margin-bottom: 14px !important;
  }

}
