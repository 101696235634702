@import "../../assets/scss/material-dashboard-pro-react.scss";

.project-timesheets-outer-container {
  margin: 0;
  padding: 0;

  .project-timesheets-container {
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    padding: 0 24px;
    margin-top: 10px;

    .project-timesheets-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 !important;

      .timesheets-top-right-bar {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .ikt-ap_custom-dropdown-menu {
          margin-left: 3px !important;
          max-width: calc(100% - 6px) !important;
          min-width: calc(100% - 6px) !important;
        }
      }

      .add-time-btn {
        background-color: #0fd280;
        color: white;
      }
    }

    .timesheets-header {
      display: flex;
      margin: 0 auto !important;
      margin-top: 25px !important;
      border-bottom: 2px solid #3d73dd;
      position: relative;

      .timesheets-header-title {
        display: flex;
        justify-content: flex-start;
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: 600;
        color: #333333;
        line-height: 14px;
        text-align: center;
        padding-bottom: 4px !important;
      }

      .col-sort-active {
        color: #3d73dd !important;
  
        img { // arrow icon
          display: inline-block;
          margin-left: 2px;
          margin-top: 0;
          width: 12px;
          height: 6px;
        }
      }

      .ts-col-date {
        min-width: 120px !important;
        max-width: 120px !important;
      }

      .ts-col-position,
      .ts-col-cost-center,
      .ts-col-cost-center-item {
        min-width: 10.5% !important;
        max-width: 10.5% !important;
      }

      .ts-col-coworker {
        min-width: 14% !important;
        max-width: 14% !important;
      }

      .ts-col-description {
        min-width: 16.5% !important;
        max-width: 16.5% !important;
      }

      .ts-col-time-estimated {
        min-width: 10% !important;
        max-width: 10% !important;
      }

      .ts-col-time-spent {
        min-width: 9% !important;
        max-width: 9% !important;
      }

      .ts-col-action-btn {
        min-width: 10% !important;
        max-width: 120px !important;

        position: absolute !important;
        right: 0 !important;
      }

      @media (max-width: $screen-md-min) {
        .ts-col-position,
        .ts-col-description {
          display: none !important;
        }

        .ts-col-position,
        .ts-col-cost-center,
        .ts-col-cost-center-item {
          min-width: 14% !important;
          max-width: 14% !important;
        }

        .ts-col-coworker {
          min-width: 16.5% !important;
          max-width: 16.5% !important;
        }

        .ts-col-description {
          min-width: 20% !important;
          max-width: 20% !important;
        }

        .ts-col-time-estimated {
          min-width: 14% !important;
          max-width: 14% !important;
        }

        .ts-col-time-spent {
          min-width: 13% !important;
          max-width: 13% !important;
        }
      }
      @media (max-width: 1300px) {
        .ts-col-time-estimated {
          display: none!important;
        }
      }
      .right-aligned {
        justify-content: flex-end !important;
      }
    }

    .timesheets-content {
      display: flex;
      margin: 0 auto 15px auto !important;

      .timesheets-content-no-records {
        width: 100%;
        display: flex;
        justify-content: center;
        .no-records {
        margin: 20px auto 0 auto;
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        text-align: center;
        }
      }

      .timesheets-item {
        border-bottom: 1px solid #b4c6d9;
        display: flex;
        margin: 0 auto !important;
        height: 50px;
        position: relative;

        .timesheets-item-title {
          display: flex;
          justify-content: flex-start;
          font-size: 14px;
          font-family: "Work Sans";
          color: #505665;
          line-height: 14px;
          text-align: center;
          padding-top: 19px !important;
          padding-bottom: 4px !important;
        }

        .ts-col-date {
          min-width: 120px !important;
          max-width: 120px !important;
        }

        .ts-col-position,
        .ts-col-cost-center,
        .ts-col-cost-center-item {
          min-width: 10.5% !important;
          max-width: 10.5% !important;
          overflow: hidden;
          white-space: nowrap;
        }

        .ts-col-coworker {
          min-width: 14% !important;
          max-width: 14% !important;
          overflow: hidden;
          white-space: nowrap;
        }

        .ts-col-description {
          min-width: 16.5% !important;
          max-width: 16.5% !important;

          overflow: hidden;
          white-space: nowrap;
        }

        .ts-col-time-estimated {
          min-width: 10% !important;
          max-width: 10% !important;
        }

        .ts-col-time-spent {
          min-width: 9% !important;
          max-width: 9% !important;
        }

        .ts-col-action-btn {
          min-width: 10% !important;
          max-width: 120px !important;

          position: absolute !important;
          right: 0 !important;

          padding-right: 4px !important;
        }

        @media (max-width: $screen-md-min) {
          .ts-col-position,
          .ts-col-description {
            display: none !important;
          }

          .ts-col-position,
          .ts-col-cost-center,
          .ts-col-cost-center-item {
            min-width: 14% !important;
            max-width: 14% !important;
          }

          .ts-col-coworker {
            min-width: 16.5% !important;
            max-width: 16.5% !important;
          }

          .ts-col-description {
            min-width: 20% !important;
            max-width: 20% !important;
          }

          .ts-col-time-estimated {
            min-width: 14% !important;
            max-width: 14% !important;
          }

          .ts-col-time-spent {
            min-width: 13% !important;
            max-width: 13% !important;
          }
        }

        .time-sheet-action-btn {
          padding-top: 15px !important;
        }

        .right-aligned {
          justify-content: flex-end !important;
        }

        .bold {
          font-weight: 600;
        }

        .blue {
          color: #3d73dd
        }

        .action-btn {
          color: gray;
          padding: 0px !important;
          margin: 0 5px;
          height: 20px;
          width: 20px;
        }
      }
      @media (max-width: 1300px) {
        .ts-col-time-estimated {
          display:none!important;
        }
      }

      .dark {
        background-color:  rgba(180, 198, 217, 0.1);
      }
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.manual-timer-orders {
  .MuiPaper-rounded {
    border-radius: 12px;
  }
  .manual-timer-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;
    .dialog-title {
      display: flex;
      font-family: "Work Sans" !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .manual-timer-content {
    background-color: #f2f5f8;
    font-family: "Work Sans";
    width: 720px;
    height: 487px;
    padding: 25px 34px;
    .time-entries-content {
      max-height: 410px;
      width: 656px;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden!important;
      .time-entry-content {
        background-color: rgba(61, 115, 221, 0.1) !important;
        border-radius: 10px !important;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-family: "Work Sans";
        width: 645px;
        margin-bottom: 10px;

        .time-entry-info {
          padding: 17px 8px !important;

          .time-entry-title {
            display: flex;
            justify-content: space-between;
            .entry-title {
              font-size: 20px;
              font-weight: bold;
              color: #505665;
              line-height: 24px;
              padding-left: 8px;
            }

            .remove-btn {
              padding: 6px;
            }
          }

          .time-entry-info-content {
            display: flex;
            flex-direction: column;

            .timer-entry-data-item {
              display: flex;
              flex-direction: column;
              padding: 0 6px;
              .timer-entry-data-item-title {
                color: #98a9bb !important;
                padding-left: 5px;
                margin-bottom: 4px;
              }
            }

            .timer-entry-type {
              padding: 0 8px;
              .timer-type-items {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 4px;
                .timer-type {
                  display: flex;
                  cursor: pointer;
                  justify-content: center;
                  align-items: center;
                  background-color: #e9eef3;
                  border-radius: 6px;
                  color: #505665;
                  height: 32px;
                  width: 286px;
                  margin: 0 4px;
                  text-align: center;
                }

                .selected {
                  background-color: #3d73dd;
                  color: #ffffff;
                }
              }

              span {
                color: #98a9bb !important;
                padding-left: 10px;
              }
            }

            .timer-date-picker-container {
              display: flex;
              flex-direction: row;
              padding: 0 8px;
              margin-top: 12px;

              .timer-entry-date-picker {
                div {
                  border-radius: 6px !important;
                }
                input {
                  height: 18px;
                  width: 226px;
                }
              }

              .time-hours {
                width: 294px;
              }
            }

            .timer-dropdowns {
              display: flex;
              flex-direction: row;
              padding: 0 8px;
              margin-top: 12px;

              button {
                margin: 0 !important;
                width: 184px !important;
              }

              ul {
                max-width: 184px !important;

                li {
                  text-overflow: ellipsis;
                }
              }

            }
            .time-entry-dropdown {
              max-height: 90px;
            }
            .timer-entry-note {
              padding: 0 8px;
              margin-top: 12px;
            }
          }
        }
        .card-btn {
          background-color: rgba(61, 115, 221, 0.5);
          border-radius: 0 10px 10px 0;
          display: flex;
          align-items: center;
          width: 26px;
          svg {
            color: #3d73dd;
          }
        }

        .card-btn:hover {
          background-color: #b0c1dc;
        }
      }
    }

    .time-entries-content::-webkit-scrollbar {
      width: 5px;
    }

    .time-entries-content::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }

    .time-entries-content::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border: 1px solid slategrey;
    }
  }

  .manual-timer-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 15px 33px;

    .cancel-btn {
      background-color: white !important;
      color: #3d73dd !important;
    }

    .create-btn {
      background-color: #0fd280 !important;
      color: white !important;
    }
  }

  .manual-timer-btn {
    background-color: #3d73dd !important;
    border-radius: 6px !important;
    min-width: 100px;
    color: white;
    font-size: 12px;
    font-family: "Work Sans" !important;
    font-weight: bold !important;
    line-height: 14px;
    height: 32px !important;
    padding: 0 16px !important;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }
}

.timer-dropdown-style {
  background-color: white !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  display: flex !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  color: black !important;
  margin-bottom: 5px !important;
  margin-top: 0px !important;

  width: 100% !important;
  height: 32px;
  box-shadow: none !important;
  justify-content: space-between !important;
  text-transform: none !important;

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 0px !important;
    color: black !important;
  }

  b {
    color: #3d73dd;
  }
}
