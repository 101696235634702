@import "../../../../assets/scss/material-dashboard-pro-react.scss";

.company-info-page {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  margin: 0 auto;
  padding-top: 50px;
  flex: 1;
  .company-info-page-title {
    font-weight: bold;
    text-align: center;
    font-size: 36px;
    color: #505665;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .company-info-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .company-info {
      display: flex;
      flex-direction: column;
      margin: 0 7px;

      .dropdown-size {
        max-height: 150px;
      }

      .autocomplete-size {
        max-width: 300px;
        margin-bottom: 27px;

        .ikt-ap_custom-dropdown-menu {
          max-height: 150px !important;
        }
      }

      .company-info-title {
        color: #98a9bb;
        font-size: 14px;
        padding-left: 9px;
        padding-bottom: 3px;
      }

      .company-info-upload {
        max-width: 300px;
        height: 142px;
      }

      .input-container {
        min-height: 60px;
      }

      .company-info-field-container {
        position: relative;
        min-height: 60px;

        .error-msg {
          position: absolute;
          top: 55px;
          left: 10px;
          color: red;
        }
      }

      input {
        width: 300px;
      }
      .dropdown-style {
        background-color: white !important;
        border-radius: 6px !important;
        display: flex !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        color: black !important;
        margin-bottom: 27px !important;
        margin-top: 0 !important;
        width: 296px;
        height: 32px;
        box-shadow: none !important;
        justify-content: space-between !important;
        text-transform: none !important;

        span {
          font-size: 14px;
          line-height: 16px;
          color: black !important;
        }

        b {
          color: #3d73dd;
        }
      }
    }
  }
}


@media (max-width: $screen-md-min) {
  .company-info-page {
    padding-top: 35px;
    .company-info-page-title {
      margin-bottom: 20px;
    }
    .company-info-content {
      .company-info {
        .company-info-upload {
          margin-bottom: 44px;
        }
        input {
          margin-bottom: 17px;
        }
        .dropdown-style {
          margin-bottom: 17px !important;
        }
      }
    }
  }
}