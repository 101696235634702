@import "../../../assets/scss/material-dashboard-pro-react.scss";

.register-container {
  background-color: white;
  display: flex;
  width: 100% !important;
  height: 100vh;

  .register-sidebar-container {
    background-image: url('../../../assets/img/background/blue-registration@2x.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    padding: 50px 50px !important;
    
    .register-logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;
      height: 41px;
      width: 145px;
    }

    .bottom-text {
      color: rgba(255, 255, 255, 0.5);
      margin-top: auto;
    }

    .sidebar-title {
      display: flex;
      color: white;
      font-size: 36px;
      font-weight: bold;
      font-family: Work Sans;
      line-height: 42px;
      width: 243px;
      height: 77px;
      margin: 0 auto;
    }

    .slider-btn-container {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      padding-top: 50px;

      div {
        background-color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        height: 3px;
        width: 60px;
        margin: 0 2px;
      }

      .selected {
        background-color: #ffffff;
      }

      .valid {
        background-color: #0fd280;
      }
    }
  }

  .content-container {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    right: 3.5%;
    top: 12.5%;
    background-color: #f2f5f8;
    border-radius: 20px;
    padding-bottom: 10px;
    min-height: 620px;
    width: 60%;
    height: auto;
    margin: auto auto;

    .btn-container {
      display: flex;
      justify-content: space-between;
      margin: 0 20px;
      padding-bottom: 30px;

      button {
        font-size: 12px;
        font-family: "Work Sans";
        font-weight: bold;
        line-height: 14px;
        height: 32px;
        width: 135px;
        padding: 0 10px;
      }

      .cancel-btn {
        background-color: white;
        color: #3d73dd;
      }

      .next-btn {
          background-color: #0fd280;
          color: white;
          margin-left: 9px
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  .register-container {
    
    .register-sidebar-container {
      .register-logo {
        margin-bottom: 30px !important;
      }
      .slider-btn-container {
        padding-top: 35px !important;
      }
    }

    .content-container {
      width: 90% !important;
      left: 35px !important;
      right: 35px !important;
      top: 270px !important;
      min-height: 500px !important;
    }
  }
}