.top-brand-name {
  display: flex;
  flex-direction: column;

  .brand-name {
    color: #c6d7e9 !important;
    font-family: "Work Sans" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    margin-left: 10px !important;
  }
}
