.view-compound-material-container {
  margin: 0;
  padding: 0;

  .view-category-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    height: 75px;
    .dialog-title {
      display: flex;
      font-family: 'Work Sans' !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }

    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }

  .view-category-content {
    display: flex;
    flex-direction: column;
    // height: 800px;
    width: 1000px;
    background-color: #f2f5f8;
    font-family: 'Work Sans';

    .category-title-container {
      display: flex;
      flex-direction: row;

      .category-name {
        font-size: 20px !important;
        font-weight: 600 !important;
        color: #505665;
        margin: 20px 0;
      }

      .view-category-btn {
        display: flex;
        justify-content: flex-end;
        min-height: 66px;

        button {
          font-size: 12px;
          font-family: 'Work Sans';
          font-weight: bold;
          line-height: 14px;
          height: 32px;
          width: 122px;
          padding: 0 10px;
          margin: auto 0;
          margin-right: 10px;
        }

        .cancel-btn {
          background-color: white;
          color: #3d73dd;
        }

        .duplicate-btn {
          background-color: #0fd280 !important;
          color: white !important;
        }

        .create-btn {
          background-color: #3d73dd;
          color: white;
        }
      }
    }

    .category-data-container {
      display: flex;
      flex-direction: row;
      .category-info-field {
        padding: 12px 0;
        width: 50%;
        .category-info-item {
          display: flex;
          flex-direction: row;
          font-family: 'Work Sans';
          color: #505665;
          font-size: 14px;

          .category-info-title {
            color: #98a9bb;
          }
        }
      }
    }
  }

  .category-footer-container {
    border-top: 1px solid #b4c6d9 !important;
    padding: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100px;
    background-color: #f2f5f8;
    font-family: 'Work Sans';
    .footer-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 50px;
      .footer-info-item {
        .footer-info-title {
          color: #98a9bb;
        }
      }
    }
  }

  .materials-content {
    display: flex;
    padding: 0 15px !important;
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;

    .materials-list-title {
      color: #98a9bb;
    }
    .material-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      min-height: 86px;

      .material-item-title {
        font-size: 12px;
        font-family: 'Work Sans';
        font-weight: 600;
        color: #333333;
        line-height: 14px;
        text-align: center;
        padding-top: 19px !important;
        padding-bottom: 4px !important;

        .status-dropdown {
          margin-top: 5px !important;
          padding: 0 !important;
          background-color: #0fd280 !important;
          color: white;
        }
        .dropdown-green {
            background-color: #0fd280 !important;
          .CircularProgressbar-path {
            stroke: #0fd280 !important;
          }
        }

        .dropdown-red {
            background-color: #f84141 !important;
          .CircularProgressbar-path {
            stroke: #f84141 !important;
          }
        }
        .brands-item,
        .suppliers-item {
          display: block;
        }

      }

      .action-btn-container {
        padding: 15px 5px 0 5px !important;
      }

      .mat-grid-col-availability {
        padding-top: 10px!important;
      }
      ._compound-dropdown{
        padding-top: 8px!important;
      }
      .availability-dropdown {
        .status-title {
          display: block;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          color: white;
          font-family: 'Work Sans';
          padding-left: 10px;
        }

        button {
          height: 23px;
          width: 130px !important;
          padding: 0 5px !important;
          border-radius: 5px !important;
        }
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 5px;
      }
    }
  }
}

