@import "../../assets/scss/material-dashboard-pro-react.scss";

.ikt-ap_projects-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ikt-ap_projects-top-wrapper {
    .ikt-ap_projects-top-container {
      display: flex;
      justify-content: space-between;

      .ikt-ap_projects-top-left {
        padding: 0 12px !important;
        margin-bottom: 8px;

        /* lots of overloading here, maybe generalize in original ProjectTabs component? */
        .ikt-ap_project-tab-item {
          margin-right: 4px;
          min-width: 105px;
          padding-left: 0;
          padding-right: 0;
        }
      }

      .ikt-ap_projects-top-right {
        display: flex;
        padding: 0 24px !important;
        flex-wrap: wrap;
        margin-left: auto;
        /*margin-left: -6px;*/

        .ikt-ap_custom-dropdown.date-range-picker {
          min-width: 190px !important;
          max-width: 190px !important;
        }

        .ikt-ap_custom-dropdown.date-range-picker {
          margin-right: 6px !important;
        }

        .ikt-ap_custom-dropdown.date-range-picker > div > button {
          min-width: 190px !important;
          max-width: 190px !important;
        }

        .ikt-ap_projects-dropdown-btn {
          background-color: #fff;
          color: #000;
          margin-left: 6px;
          padding: 2px 11px;
          width: 155px;

          .MuiButton-label {
            display: flex;
            justify-content: space-between;
            text-transform: none;
            font-size: 14px;

            .ikt-ap_dropdown-btn-text {
              max-width: 119px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            b {
              border-top: 6px solid;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top-color: #e91e63;
            }
          }
        }

        .project-view-button {
          margin-right: 14px;
        }
      }
    }

    .ikt-ap_projects-create-btn {
      background-color: #0fd280;
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: bold;
      line-height: 14px;
      height: 32px !important;
      width: 100%;
      padding: 0 23px;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }

    .ikt-ap_projects-search {
      margin-left: 6px;
    }
  }

  .ikt-ap_projects-card-top {
    display: flex;
    justify-content: space-between;

    .ikt-ap_projects-card-top-left {
      display: flex;
    }

    .ikt-ap_projects-card-top-right {
      display: flex;
      align-items: center;
      padding-top: 12px;

      .ikt-ap_projects-card-top-sort-by-text {
        font-family: Roboto;
        font-size: 14px;
        color: #aaaaaa;
        line-height: 16px;
        font-weight: 400;
        margin-right: 10px;
      }

      .ikt-ap_projects-dropdown-btn {
        border: 1px solid #eeeeee;
        padding: 3px 11px;
        margin: 0;
        width: 155px;

        .MuiButton-label {
          display: flex;
          justify-content: space-between;
          text-transform: none;
          font-size: 14px;

          .ikt-ap_dropdown-btn-text {
            max-width: 119px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: 14px;
            color: #000000;
            line-height: 16px;
          }

          b {
            border-top: 6px solid;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top-color: #e91e63;
          }
        }
      }
    }
  }

  .ikt-ap_projects-card-icon {
    padding: 16px 15px !important;

    .material-icons.MuiIcon-root {
      display: block;
      font-size: 28px;
      line-height: 34px;
    }
  }

  .ikt-ap_projects-star-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: -2px 2px 15px 7px;

    .ikt-ap_projects-star-icon.material-icons.MuiIcon-root {
      color: #d8d8d8;
      font-size: 20px;

      &.ikt-ap_is-active {
        color: #ffc466;
      }
    }
  }

  .ikt-ap_projects-actions {
    display: flex;
    align-items: center;

    button.ikt-ap_projects-action-btn {
      display: flex;
      outline: none;
      border: none;
      background: unset;
      cursor: pointer;
      border-radius: 3px;
      padding: 7px;

      &.eye {
        color: #00bcd4;
      }

      &.edit {
        color: #4f4f4f;
      }

      &:active {
        background-color: #eeeeee;
      }

      .material-icons.MuiIcon-root {
        font-size: 16px;
      }
    }
  }

  .ikt-ap_issue-container {
    display: flex;
    align-items: center;

    .ikt-ap_issue-indicator {
      min-width: 10px;
      min-height: 10px;
      border-radius: 50%;
      margin-right: 9px;
    }
  }

  .ikt-ap_projects-status {
    display: flex;
    align-items: center;

    .ikt-ap_project-status-dropdown-btn {
      background-color: transparent;
      margin-left: 0;
      padding: 0;
      width: 158px;
      border: none;

      .MuiButton-label {
        display: flex;
        justify-content: left;
        text-transform: none;
        font-size: 14px;

        .ikt-ap_project-status-dropdown-toggle-btn-text {
          margin-right: 5px;
        }
      }
    }
  }

  .ikt-ap_projects-customer {
    color: #00bcd4;
    font-weight: bold;
  }

  .ikt-ap_loader {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 101px);
    width: 100%;
    margin-top: 100px;
  }

  // current grid container & columns
  .pr-gd-col-container {
    position: relative;
  }

  .pr-gd-col-star {
    min-width: 1.75%;
    max-width: 1.75%;
    margin-right: 1% !important;
  }

  .pr-gd-col-order-id {
    min-width: 6%;
    max-width: 6%;
  }

  .pr-gd-col-type {
    min-width: 7.5%!important;
    max-width: 7.5%!important;
  }

  .pr-gd-col-status {
    min-width: 165px;
    max-width: 165px;
  }

  .pr-gd-col-customer {
    min-width: 13%;
    max-width: 13%;
  }

  .pr-gd-col-modified {
    min-width: 85px;
    max-width: 85px;
  }

  .pr-gd-col-created {
    min-width: 85px;
    max-width: 85px;
  }

  .pr-gd-col-cost {
    min-width: 6.5%;
    max-width: 6.5%;
    margin-right: 2.45% !important;
  }

  .pr-gd-col-progress {
    min-width: 6%;
    max-width: 6%;
  }

  .pr-gd-col-issues {
    min-width: 10%;
    max-width: 10%;
  }

  .pr-gd-col-delay {
    min-width: 3.0%;
    max-width: 3.0%;
  }

  .pr-gd-col-actions {
    position: absolute;
    right: 0;
    min-width: 12%!important;
    max-width: 130px!important;
  }

  .col-sort-active {
    color: #3d73dd !important;

    img { // arrow icon
      display: inline-block;
      margin-left: 2px;
      margin-top: -1px;
      width: 12px;
      height: 6px;
    }
  }

  @media (max-width: 1500px) {
    .pr-gd-col-issues {
      min-width: 5%;
      max-width: 5%;
    }
    .pr-gd-col-delay {
      display: none;
    }
  }

  @media (max-width: 1300px) {
    .pr-gd-col-order-id {
      min-width: 7.25%;
      max-width: 7.25%;
    }
    .pr-gd-col-type {
      display:none;
    }
    .pr-gd-col-customer {
      min-width: 22%;
      max-width: 22%;
    }
    .pr-gd-col-cost {
      min-width: 7.5%;
      max-width: 7.5%;
    }
    .pr-gd-col-progress {
      min-width: 12%;
      max-width: 12%;
    }
    .pr-gd-col-delay,
    .pr-gd-col-issues,
    .pr-gd-col-created {
      display: none !important;
    }

    .pr-gd-col-actions {
      min-width: 12%!important;
      max-width: 130px!important;
    }
  }

  @media (max-width: 1100px) {
    .pr-gd-col-progress {
      min-width: 8%;
      max-width: 8%;
    }
    .pr-gd-col-actions {
      min-width: 14%!important;
      max-width: 130px!important;
    }
  }

  @media (max-width: 1000px) {
    .pr-gd-col-order-id {
      min-width: 8%;
      max-width: 8%;
    }
  }

  @media (max-width: $screen-md-min) {
    .pr-gd-col-progress,
    .pr-gd-col-issues,
    .pr-gd-col-delay,
    .pr-gd-col-modified,
    .pr-gd-col-created {
      display: none !important;
    }

    .pr-gd-col-actions {
      min-width: 14%!important;
      max-width: 140px!important;
    }
  }

  .projects-table-header {
    display: flex;
    margin: 20px 38px 0 28px !important;
    border-bottom: 2px solid #3d73dd;

    .projects-header-title {
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: center;
      padding: 0 !important;
      padding-bottom: 4px !important;
      margin-right: 0.78%;
    }

    .left-aligned {
      text-align: left !important;
    }

    .right-aligned {
      text-align: right !important;
    }
  }

  .projects-content {
    display: flex;
    padding: 0 38px 0 28px;
    margin-bottom: 15px;

    .projects-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;

      .project-item-title {
        font-size: 14px;
        font-family: "Work Sans";
        color: #505665;
        line-height: 14px;
        text-align: center;
        margin-top: 14px;
        margin-right: 0.78%;
        padding: 0 !important;

        .project-id {
          padding-left: 12px !important;
        }

        .CircularProgressbar-trail {
          stroke: none !important;
        }
      }

      .action-grid-item {
        padding: 0 !important;
      }

      .order-action-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 5px;

        .hidden {
          visibility: hidden;
        }
      }

      .left-aligned {
        text-align: left;
      }

      .right-aligned {
        text-align: right;
      }

      .first-order-status {
        button {
          background-color: #83e2e9 !important;
        }
        .CircularProgressbar-path {
          stroke:#83e2e9 !important;
        }
      }

      .second-order-status {
        button {
          background-color: #619ffc !important;
        }
        .CircularProgressbar-path {
          stroke:#619ffc !important;
        }
      }

      .third-order-status {
        button {
          background-color: #7bd2b5 !important;
        }
        .CircularProgressbar-path {
          stroke: #7bd2b5 !important;
        }
      }

      .fourth-order-status {
        button {
          background-color: #43bf57 !important;
        }
        .CircularProgressbar-path {
          stroke:#43bf57 !important;
        }
      }

      .fifth-order-status {
        button {
          background-color: #f49025 !important;
        }
        .CircularProgressbar-path {
          stroke:#f49025 !important;
        }
      }
      
      .sixth-order-status {
        button {
          background-color: #f2441a !important;
        }
        .CircularProgressbar-path {
          stroke:#f2441a !important;
        }
      }

      .gray {
        button {
          background-color: #b4c6d9 !important;
        }
        .CircularProgressbar-path {
          stroke: #b4c6d9 !important;
        }
      }

      .customer {
        color: #3d73dd;
        cursor: pointer !important;
      }

      .customer:hover {
        color: #477eea
      }

      .row {
        display: flex;
        flex-direction: row;
      }

      .status-dropdown {
        margin-top: 5px !important;
        padding: 0 !important;

        /*@media (max-width: $screen-md-min) {
          .ikt-ap_custom-dropdown-menu {
            min-width: 138px;
            position: relative !important;
            left: 0 !important;
          }
        }*/
      }

      .progress-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 13px !important;

        .CircularProgressbar-trail {
          stroke: #dbe7f8 !important;
        }
      }

      .delay-container {
      }

      .bold {
        font-weight: 600;
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 2px;
      }

      .action-btn-view {
        margin-top: 3px
      }

      .status-dropdown {
        .status-title {
          display: block;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          color: white;
          font-family: "Work Sans";
          padding: 0 0 0 4px !important;
        }

        height: 23px;
        width: 100% !important;
        min-width: 115px !important;
        padding: 0 5px 0 0 !important;
        border-radius: 5px !important;
      }
    }

    .dark {
      background-color:  rgba(180, 198, 217, 0.1);
    }

    .order-item-card {
      display: flex;
      background-color: white;
      border-radius: 11px;
      flex-direction: row;
      font-family: "Work Sans";
      font-size: 14px;
      justify-content: space-between;
      margin: 10px;
      padding: 8px 18px;
      height: 188px;
      width: 269px;

      .order-item {
        padding: 7px 0 7px 0 !important;
      }

      .bold {
        font-weight: 600;
      }

      .first-order-status {
        color: #83e2e9 !important;
        button {
          background-color: #83e2e9 !important;
        }
        .CircularProgressbar-path {
          stroke:#83e2e9 !important;
        }
      }

      .second-order-status {
        color: #619ffc !important;
        button {
          background-color: #619ffc !important;
        }
        .CircularProgressbar-path {
          stroke:#619ffc !important;
        }
      }

      .third-order-status {
        color: #7bd2b5 !important;
        button {
          background-color: #7bd2b5 !important;
        }
        .CircularProgressbar-path {
          stroke: #7bd2b5 !important;
        }
      }

      .fourth-order-status {
        color: #43bf57 !important;
        button {
          background-color: #43bf57 !important;
        }
        .CircularProgressbar-path {
          stroke:#43bf57 !important;
        }
      }

      .fifth-order-status {
        color: #f49025 !important;
        button {
          background-color: #f49025 !important;
        }
        .CircularProgressbar-path {
          stroke:#f49025 !important;
        }
      }
      
      .sixth-order-status {
        color: #f2441a !important;
        button {
          background-color: #f2441a !important;
        }
        .CircularProgressbar-path {
          stroke:#f2441a !important;
        }
      }

      .gray {
        color: #b4c6d9;
        button {
          background-color: #b4c6d9 !important;
        }
        .CircularProgressbar-path {
          stroke: #b4c6d9 !important;
        }
      }

      .order-item-left {
        display: flex;
        flex-direction: column;

        .category {
          color: #3c4858;
        }

        .customer {
          padding: 5px 0 10px 0 !important;
          color: #3d73dd;
          cursor: pointer;
          height: 32px;
        }

        .progress-container {
          display: flex;
          flex-direction: row;
          padding: 5px 0 5px 0 !important;

          .CircularProgressbar-trail {
            stroke: #f2f5f8 !important;
          }

          .issue-text {
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 2px 5px;
          }
        }

        .card-status-dropdown {
          .status-title {
            display: block;
            width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          span {
            color: white;
            font-family: "Work Sans";
          }

          button {
            height: 23px;
            width: 130px !important;
            padding: 0 5px !important;
            border-radius: 5px !important;
          }

        }

        .delay {
          padding: 6px 0 5px 0 !important;
          color: black;
        }
      }

      .order-item-right {
        display: flex;
        color: #3c4858;
        flex-direction: column;

        .star-id {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .star-icon {
            margin-bottom: 0 !important;
          }

          .project-id {
            padding: 0 3px;
          }
        }

        .project-price {
          display: flex;
          color: #3c4858;
          font-weight: bold;
          justify-content: flex-end;
          padding: 4px 0 16px 0 !important;
        }

        .remove-btn {
          display: flex;
          justify-content: flex-end;
          min-height: 30px;
          margin-top: 30px;
          margin-right: -14px;

          .MuiIconButton-root {
            padding: 4px !important;
          }
        }

        .action-icons {
          display: flex;
          flex-direction: row;
          transform: translateX(15px);

          .MuiIconButton-root {
            padding: 4px !important;
          }
        }
      }
    }

    .no_orders {
      display: flex;
      margin: 20px auto 0 auto;
      color: red;
      font-weight: bold;
      font-size: 15px;
    }
  }

  .project-card-view {
    margin-top: 10px;
    padding: 0 29px !important;
  }

  .dropdown-span {
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .page-ination-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
}
