@import "../../../assets/scss/material-dashboard-pro-react.scss";

.tasks-position-content {
  margin-bottom: 10px;
  font-family: "Work Sans";

  .tasks-position-info {
    background-color: rgba(61, 115, 221, 0.1);
    display: flex;
    justify-content: space-between;
    border-radius: 6px;

    .tasks-position-info-title {
      display: flex;
      justify-content: space-between;
      padding: 15px 26px 15px 9px !important;
      width: 100%;

      .tasks-position-title {
        font-size: 20px;
        font-weight: bold;
        color: #505665;
        line-height: 24px;
      }
    }
    .tasks-card-btn-container {
      display: flex;
      padding: 0px !important;
      justify-content: flex-end;

      .card-btn {
        background-color: rgba(61, 115, 221, 0.1);
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        width: 26px !important;
        svg {
          color: #3d73dd;
        }
      }

      .bottom-borders {
        border-bottom-right-radius: 0;
      }
    }
  }

  .tasks-bottom-borders {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tasks-position-info-content {
    background-color: rgba(216, 224, 232, 0.3);
    font-family: 'Work Sans';
    padding: 12px;

    .position-tasks {
      .position-info-header {
        padding: 0 10px;
        margin-bottom: 6px;
        font-size: 14px;
        color: #98a9bb;
        line-height: 16px;

        div {
          display: flex;
          justify-content: center;
        }
      }
    }
    .position-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 !important;

      .position-btn-part {
        display: flex;
        flex-direction: row;
        padding: 0 !important;

        .position-btn {
          border-radius: 6px !important;
          height: 32px !important;
          padding: 9px 30px !important;
          font-size: 12px;
          font-family: 'Work Sans';
          font-weight: bold;
          margin: 18px 8px 10px 0;
        }

        .blue {
          background-color: #3d73dd;
          color: white;
        }

        .white {
          background-color: white;
          color: #3d73dd;
        }

        .green {
          background-color: #0fd280;
          color: white;
          margin-right: 0 !important;
        }
      }

      .delete-position-btn {
        .position-btn {
          width: 170px !important;
        }
      }

      .right-aligned {
        justify-content: flex-end;
      }
    }
  }

  .task-data-container {
    height: 77px;
    width: 100%;
    padding: 13px;
    background-color: rgba(215, 225, 240, 0.8);
    border-radius: 5px;
    margin-bottom: 4px;
    position: relative;
  
    .task-data-item {
      padding: 0 2px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
  
      .item-with-title {
        display: flex;
        flex-direction: column;
  
        .ikt-ap_custom-dropdown-toggle-btn {
          margin: 0;
        }
  
        .assignee-title {
          color: #98a9bb;
          font-size: 14px;
          margin-bottom: 1px;
          overflow:hidden;
          text-overflow: ellipsis;
        }
        .amount-title {
          color: #98a9bb;
          font-size: 14px;
          margin-bottom: 1px;
          overflow:hidden;
          text-overflow: ellipsis;
          //max-width: 45px;
        }
        span {
          font-size: 12px;
          color: #333333;
          padding-left: 10px;
          overflow:hidden;
          text-overflow: ellipsis;
        }
      }
  
      .task-name {
        /*margin-right: 10px;*/
      }

      @media (max-width: $screen-md-min) {
        .item-with-title {
          span {
            padding-left: 2px;
          }
        }
      }
    }

    .edges-structure-grid-container {
      display: block;
      position: relative;
      
      .edges-structure-container {
        width: 30px !important;
        height: 30px !important;
        position: absolute;
        left: 2px;
        bottom: 2px;

        .structure-top-container {
          height: 8px;
      
          .side {
            width: 17px;
          }
      
          .structure-button {
            width: 8px;
          }
          
          .top-left-diagonal-line {
            height: 6px;
            width: 1px;
            left: 3px;
            top: 1px;
          }
          
          .top-right-diagonal-line {
            height: 6px;
            width: 1px;
            left: 3px;
            top: 1px;
          }
        }
      
        .structure-middle-container {
          height: 15px;
      
          .side-title {
            width: 17px;
            font-size: 12px;
          }
      
          .side {
            width: 8px;
          }
        }
      
        .structure-bottom-container {
          height: 8px;
      
          .side {
            width: 17px;
          }

          .structure-button {
            width: 8px;
          }
      
          .bottom-left-diagonal-line {
            height: 9px;
            width: 1px;
            left: 3px;
            bottom: 0;
          }
      
          .bottom-right-diagonal-line {
            height: 9px;
            width: 1px;
            left: 3px;
            bottom: 0;
          }
        }
      }
    }

    @media (max-width: $screen-sm-min) {
      .edges-structure-container {
        display: none;
      }
    }
  
    .tdi-child-one > div {
      min-width: 100%;
      max-width: 100%;
    }
  
    .tdi-child-one > div {
      .task-name {
        max-width: none !important;
      }
    }
  
    .tdi-child-two > div {
      min-width: 50%;
      max-width: 50%;
    }
  
    .tdi-child-three > div {
      min-width: 33.333%;
      max-width: 33.333%;
    }
  
    .tdi-child-four > div {
      min-width: 25%;
      max-width: 25%;
    }
  
    .tdi-child-two > div > div,
    .tdi-child-four > div > div {
      min-width: 100%;
      max-width: 100%;
    }
  
    @media (max-width: $screen-md-min) {
      .tdi-hidden-sm {
        display: none;
      }
    }
  
    .task-number {
      max-width: 42px;
      // margin-top: 21px;
    }
  
    .task-name {
      max-width: 110px;
    }
  
    .task-material {
      width: 100%;
    }
  
    .task-amount {
      width: 100%;
    }
  
    .task-me {
      min-width: 100% !important;
      max-width: 100% !important;

      .ikt-ap_custom-dropdown-toggle-btn {
        min-width: 100% !important;
        max-width: 100% !important;

        .MuiButton-label {
          padding-left: 0 !important;
        }
      }
    }
  
    .finished-dimension-l {
      max-width: 70px;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0px !important;
      margin-top: 21px;
    }
    .finished-dimension-w {
      max-width: 70px;
      border-radius: 0px !important;
      margin: 21px 1px 0 1px;
    }
    .finished-dimension-t {
      max-width: 70px;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0px !important;
      margin-top: 21px;
    }
  
    .finished-dimension-l input,
    .finished-dimension-w input,
    .finished-dimension-t input,
    .edges-l input,
    .edges-r input,
    .edges-b input,
    .covering-t input,
    .covering-b input {
      padding-top: 10px;
      font-size: 14px;
    }
  
    .edges-l {
      max-width: 56px;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0px !important;
      margin-right: 1px;
      padding: 5px 6px !important;
    }
    .edges-r {
      max-width: 56px;
      border-radius: 0px !important;
      margin-right: 1px;
      padding: 5px 6px !important;
    }
    .edges-b {
      max-width: 56px;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0px !important;
      margin-right: 1px;
      padding: 5px 6px !important;
    }

    .surface-t {
      .ikt-ap_custom-dropdown-toggle-btn {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .surface-b {
      .ikt-ap_custom-dropdown-toggle-btn {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  
    .surface-t,
    .surface-b {
      .ikt-ap_custom-dropdown-menu {
        margin-left: 8px;
      }
    }

    .covering-t {
      max-width: 56px;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0px !important;
      margin-right: 1px;
      padding: 5px 6px !important;
    }
    
    .covering-b {
      max-width: 56px;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0px !important;
      padding: 5px 6px !important;
    }
  
    .task-action-btn {
      max-width: 7.5%;
      margin-top: 15px;
      padding: 2px 10px 0 0 !important;
      position: absolute;
      right: 0.3%;
      display: flex;
      
      .action-btn {
        padding: 8px;
        //flex: 1;
        height: 100%;
      }
    }
  
    @media (max-width: $screen-md-min) {
      .task-action-btn {
        right: 6px;
        .action-btn {
          padding: 8px 5px;
        }
      }
    }
  
    @media (min-width: $screen-md-min) and (max-width: 1150px) {
      .task-action-btn {
        right: 2%;
        .action-btn {
          padding: 8px 5px;
        }
      }
    }
  }
  
  @media (max-width: $screen-md-min) {
    .task-data-container {
      padding: 13px 5px;
    }
  }
  
  .edit-description-title {
    display: flex;
    font-size: 20px;
    color: #505665;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px !important;
    height: 75px;
    .dialog-title {
      display: flex;
      font-family: 'Work Sans' !important;
      font-size: 20px !important;
      color: #505665 !important;
      text-transform: none !important;
      font-weight: 600 !important;
    }
  
    .close-btn {
      color: gray;
      padding: 0px !important;
    }
  }
  
  .edit-description-content {
    width: 654px;
    height: 500px;
    padding: 30px 26px;
    background-color: #f2f5f8;
    color: #505665;
    font-size: 14px;
    display: flex;
    justify-content: center;
    font-family: 'Work Sans';
  
    .description-area {
      width: 100%;
      padding: 0 10px;
      span {
        padding-left: 10px;
      }
      .description-test-area {
        height: 420px;
        width: 100%;
        background-color: white;
        border-radius: 6px;
      }
    }
  }
  
  .edit-description-btn-container {
    background-color: #e9eef3;
    display: flex;
    justify-content: space-between;
    min-height: 66px;
    padding: 0 34px;
  
    button {
      font-size: 12px;
      font-family: 'Work Sans';
      font-weight: bold;
      line-height: 14px;
      height: 32px;
      width: 135px;
      padding: 0 10px;
      margin: auto 0;
    }
  
    .cancel-btn {
      background-color: white !important;
      color: #3d73dd !important;
    }
  
    .create-btn {
      background-color: #0fd280 !important;
      color: white;
    }
  }  
}
