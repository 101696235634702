.my-date-picker {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  width: 100%;

  .MuiOutlinedInput-adornedEnd {
    padding-right: 7px !important;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 12px !important;
  }

  fieldset {
    border-color: #eeeeee !important;
    border-radius: 6px;
    background-color: white;
  }

  input {
    font-family: "Work Sans";
    z-index: 22;
    margin-bottom: 0 !important;
    padding: 7px 5px;
    font-size: 14px;
  }

  input::placeholder {
    color: #505665 !important;
    opacity: 1 !important;
  }

  button {
    padding: 12px 0;
  }

  button:hover {
    background-color: transparent !important;
  }

  span {
    padding: 0 !important;
    margin: 0 !important;
    z-index: 10;
  }
}

.error {
  fieldset {
    border-color: red !important;
  }
}

.have-date-value {
  input {
    min-height: 20px !important;
    font-size: 13px !important;
  }
}