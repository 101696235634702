@import "../../../assets/scss/material-dashboard-pro-react.scss";

.timesheets-overview-content {
  margin-top: 20px;

  .timesheets-chart {
    background-color: #f8fafb;
    border-radius: 10px;
    width: calc(50% - 18px);
    padding: 15px 20px 20px 20px !important;

    .timesheets-chart-header {
      .timesheets-title {
        padding: 0 !important;

        .ikt-ap_project-tabs-container {
          float: right;
          margin-top: 0;
          
          .ikt-ap_project-tab-item {
            max-width: 85px !important;
            min-width: 85px !important;
          }
        }

        .view-details-btn {
          font-weight: bold;
          background-color: white;
          color: #3d73dd;
          width: 120px;
          max-height: 36px;
        }
      }

      .timesheets-title:first-of-type {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        font-family: 'Work Sans';
        font-weight: 600;
        font-size: 20px;
        color: #505665;
      }

      .timesheets-title:last-of-type {
        text-align: right;
      }
    }

    .timesheets-chart-content > div {
      margin-top: 10px;
      width: 100%;
    }
  }

  .timesheets-chart:first-of-type {
    margin-right: 18px;
  }

  .timesheets-chart:last-of-type {
    margin-left: 18px;

    .timesheets-chart-header {
      padding-top: 10px;
    }
  }
  
}