@import "../../../assets/scss/material-dashboard-pro-react.scss";

.project-timesheets-container {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  padding: 0 24px;
  margin-top: 10px;
  .project-timesheets-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 !important;

    .timesheets-top-right-bar {
      display: flex;
      flex-direction: row;
      padding: 0 !important;

      .ikt-ap_custom-dropdown-menu {
        margin-left: 3px !important;
        max-width: calc(100% - 6px) !important;
        min-width: calc(100% - 6px) !important;
      }
    }

    .add-time-btn {
      background-color: #0fd280 !important;
      border-radius: 6px !important;
      color: white;
      font-size: 12px;
      font-family: "Work Sans" !important;
      font-weight: bold !important;
      line-height: 14px;
      height: 32px !important;
      padding: 0 23px !important;

      .MuiButton-label {
        display: flex;
        align-items: center;

        .material-icons.MuiIcon-root {
          font-weight: bold;
          font-size: 11px;
          margin-right: 1px;
        }
      }
    }
  }

  .timesheets-header {
    display: flex;
    margin: 0 auto !important;
    margin-top: 25px !important;
    border-bottom: 2px solid #3d73dd;
    position: relative;

    .timesheets-header-title {
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      text-align: center;
      padding-bottom: 4px !important;
    }

    .col-sort-active {
      color: #3d73dd !important;

      img { // arrow icon
        display: inline-block;
        margin-left: 2px;
        margin-top: 0;
        width: 12px;
        height: 6px;
      }
    }

    .ts-col-date {
      min-width: 120px !important;
      max-width: 120px !important;
    }

    .ts-col-order,
    .ts-col-position,
    .ts-col-cost-center,
    .ts-col-cost-center-item {
      min-width: 12.5% !important;
      max-width: 12.5% !important;
    }

    .ts-col-coworker {
      min-width: 13% !important;
      max-width: 13% !important;
    }

    .ts-col-description {
      min-width: 13% !important;
      max-width: 13% !important;
    }

    .ts-col-time-spent {
      min-width: 9% !important;
      max-width: 9% !important;
    }

    .ts-col-action-btn {
      min-width: 10% !important;
      max-width: 120px !important;
  
      position: absolute !important;
      right: 0 !important;
    }

    @media (max-width: $screen-md-min) {
      .ts-col-description,
      .ts-col-order {
        display: none !important;
      }

      .ts-col-order,
      .ts-col-position,
      .ts-col-cost-center,
      .ts-col-cost-center-item {
        min-width: 15% !important;
        max-width: 15% !important;
      }

      .ts-col-coworker {
        min-width: 14.5% !important;
        max-width: 14.5% !important;
      }

      .ts-col-description {
        min-width: 16% !important;
        max-width: 16% !important;
      }

      .ts-col-time-spent {
        min-width: 13% !important;
        max-width: 13% !important;
      }
    }

    @media (max-width: 1300px) {
      .ts-col-time-estimated {
        display: none !important;
      }
    }

    .right-aligned {
      justify-content: flex-end !important;
    }
  }

  .timesheets-content {
    display: flex;
    margin: 0 auto 15px auto !important;

    .timesheets-content-no-records {
      width: 100%;
      display: flex;
      justify-content: center;
      .no-records {
      margin: 20px auto 0 auto;
      font-size: 12px;
      font-family: "Work Sans";
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      text-align: center;
      }
    }

    .timesheets-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;
      position: relative;

      .timesheets-item-title {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        font-family: "Work Sans";
        color: #505665;
        line-height: 14px;
        text-align: center;
        padding-top: 19px !important;
        padding-bottom: 4px !important;
      }

      .ts-col-date {
        min-width: 120px !important;
        max-width: 120px !important;
      }

      .ts-col-order,
      .ts-col-position,
      .ts-col-cost-center,
      .ts-col-cost-center-item {
        min-width: 12.5% !important;
        max-width: 12.5% !important;
        overflow: hidden;
        white-space: nowrap;
      }

      .ts-col-coworker {
        min-width: 13% !important;
        max-width: 13% !important;
        overflow: hidden;
        white-space: nowrap;
      }

      .ts-col-description {
        min-width: 13% !important;
        max-width: 13% !important;

        overflow: hidden;
        white-space: nowrap;
      }

      .ts-col-time-spent {
        min-width: 9% !important;
        max-width: 9% !important;
      }

      .ts-col-action-btn {
        min-width: 10% !important;
        max-width: 120px !important;
    
        position: absolute !important;
        right: 0 !important;

        padding-right: 4px !important;
      }

      @media (max-width: $screen-md-min) {
        .ts-col-description,
        .ts-col-order {
          display: none !important;
        }

        .ts-col-order,
        .ts-col-position,
        .ts-col-cost-center,
        .ts-col-cost-center-item {
          min-width: 15% !important;
          max-width: 15% !important;
        }

        .ts-col-coworker {
          min-width: 14.5% !important;
          max-width: 14.5% !important;
        }

        .ts-col-description {
          min-width: 16% !important;
          max-width: 16% !important;
        }

        .ts-col-time-spent {
          min-width: 13% !important;
          max-width: 13% !important;
        }
      }

      .time-sheet-action-btn {
        padding-top: 15px !important;
      }

      .right-aligned {
        justify-content: flex-end !important;
      }

      .bold {
        font-weight: 600;
      }

      .blue {
        color: #3d73dd
      }

      .action-btn {
        color: gray;
        padding: 0px !important;
        margin: 0 5px;
        height: 20px;
        width: 20px;
      }
    }

    @media (max-width: 1300px) {
      .ts-col-time-estimated {
        display: none !important;
      }
    }

    .dark {
      background-color:  rgba(180, 198, 217, 0.1);
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}

.timer-dropdown-style {
  background-color: white !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  display: flex !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  color: black !important;
  margin-bottom: 5px !important;
  margin-top: 0px !important;

  width: 100% !important;
  height: 32px;
  box-shadow: none !important;
  justify-content: space-between !important;
  text-transform: none !important;

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 0px !important;
    color: black !important;
  }

  b {
    color: #3d73dd;
  }
}
