.ikt-ap_input-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.ikt-ap_input {
  outline: unset;
  height: 32px;
  padding: 9px 11px 9px 11px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.ikt-ap_input_error {
  outline: unset;
  height: 32px;
  padding: 9px 11px;
  border-radius: 6px;
  border: 1px solid #f84141;
  background-color: #ffffff;
  font-family: 'Work Sans';
  font-size: 14px;
  color: #000;
  line-height: 18px;
}

.ikt-ap_input-error-msg {
  font-size: 14px !important;
  color: #f84141 !important;
}

.error-msg-float {
  position: absolute;
  top: 35px;
  width: max-content;
}

.error-msg-no-float {
  margin-top: 3px;
}

.error-msg-no-padding {
  padding-left: 0;
}

.error-msg-padding {
  padding-left: 9px;
}

.ikt-ap_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.ikt-ap_input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.ikt-ap_input::-ms-input-placeholder { /* Microsoft Edge */
  color: #999999;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}
