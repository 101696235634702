.create-contact-title {
  display: flex;
  font-size: 20px;
  color: #505665;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px !important;
  height: 75px;
  .dialog-title {
    display: flex;
    font-family: "Work Sans" !important;
    font-size: 20px !important;
    color: #505665 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  .close-btn {
    color: gray;
    padding: 0px !important;
  }
}

.create-contact-content {
  width: 910px;
  height: 660px;
  padding: 30px 26px;
  background-color: #f2f5f8;
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";

  .info-title-item {
    margin: 0 8px 10px 8px;
  }

  .contact-info-item {
    display: flex;
    flex-direction: row;
    margin: 10px 0;

    .info-item {
      padding: 0 8px !important;

      .category-dropdown {
        margin-top: -1px;
        span {
          color: black;
        }
      }
    }
    input {
      width: 100% !important;
      margin-top: 0 !important;
    }

    button {
      width: 100% !important;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #98a9bb;
      line-height: 16px;
      margin-left: 8px;
    }

    .company-info-input {
      display: flex;
      flex-direction: column;
      input {
        width: 100% !important;
        margin-top: 5px;
      }
    }
  }
}

.contact-btn-container {
  background-color: #e9eef3;
  display: flex;
  justify-content: space-between;
  min-height: 66px;
  padding: 0 34px;

  button {
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px;
    width: 135px;
    padding: 0 10px;
    margin: auto 0;
  }

  .cancel-btn {
    background-color: white !important;
    color: #3d73dd !important;
  }

  .create-btn {
    background-color: #0fd280 !important;
    color: white;
  }
}

.shipping-content {
  margin-top: 10px;

  .shipping-content-scrollbar {
    max-height: 490px;
    width: 820px;
    position: relative;
    .shipping-card-content {
      margin-bottom: 10px;
      width: 815px;
      font-family: "Work Sans";
      .shipping-card-info {
        background-color: rgba(61, 115, 221, 0.1);
        display: flex;
        justify-content: space-between;
        border-radius: 6px;

        .shipping-card-info-title {
          display: flex;
          flex-direction: column;
          padding: 10px !important;
          width: 100%;
          .shipping-card-header {
            display: flex;
            justify-content: space-between;
          }
          .shipping-card-title {
            font-size: 20px;
            font-weight: bold;
            color: #505665;
            line-height: 24px;
            padding: 0 7px;
            margin-bottom: 10px;
            margin-top: 7px;
          }

          .shipping-card-data {
            margin-top: 10px;
            .shipping-card-item {
              padding: 0 7px !important;
              margin-bottom: 16px;
              .shipping-card-item-title {
                color: #98a9bb;
                padding-left: 9px;
                margin-bottom: 7px !important;
              }
            }
          }

        }
        .shipping-card-btn-container {
          display: flex;
          padding: 0px !important;
          justify-content: flex-end;

          .card-btn {
            background-color: rgba(61, 115, 221, 0.1);
            border-radius: 0 10px 10px 0;
            display: flex;
            align-items: center;
            width: 26px !important;
            svg {
              color: #3d73dd;
            }
          }

          .bottom-borders {
            border-bottom-right-radius: 0;
          }
        }
      }
      .shipping-card-bottom-borders {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .shipping-card-info-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: rgba(216, 224, 232, 0.3);
        padding: 10px !important;

        .cost-positions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          padding-right: 10px;

          .create-cost {
            display: flex;
          }
        }

        .details-container {
          background-color: rgba(216, 224, 232, 0.3);
          border-radius: 6px;
          padding: 0 !important;
          height: 100%;

          .details-title {
            background-color: rgba(61, 115, 221, 0.1);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            padding: 5px;
            span {
              color: #505665;
              font-size: 14px;
              font-weight: bold;
              margin: 5px;
            }
          }

          .details-item {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #b4c6d9;

            margin:10px 10px 0 10px;

            .details-item-title {
              color: #505665;
              font-size: 14px;
              font-weight: bold;
              line-height: 16px;
              padding: 0 !important;
              margin-bottom: 5px;
            }

            .detail-item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0 !important;

              .detail-count {
                color: #000000;
                font-weight: bold;
              }

              span {
                color: #98a9bb;
                font-size: 14px;
              }
            }
          }

          .detail-total-cost {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 30px 10px 10px 10px;

            .total-cost-item {
              display: flex;
              flex-direction: column;

              .total-cost {
                color: #505665;
                font-size: 20px;
                font-weight: 600;
              }

              span {
                color: #98a9bb;
                font-size: 14px;
              }
            }
          }
        }

      }
    }
  }

  .create-address-btn {
    background-color: #3d73dd !important;
    border-radius: 6px;
    box-shadow: none !important;
    color: white;
    font-size: 12px;
    font-family: "Work Sans";
    font-weight: bold;
    line-height: 14px;
    height: 32px !important;
    width: 155px;
    padding: 0 15px;

    .MuiButton-label {
      display: flex;
      align-items: center;

      .material-icons.MuiIcon-root {
        font-weight: bold;
        font-size: 11px;
        margin-right: 1px;
      }
    }
  }
}

.finance-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .finance-data-item {
    padding: 0 7px !important;
    margin-bottom: 25px;
    .finance-data-item-title {
      color: #98a9bb;
      padding-left: 9px;
      margin-bottom: 7px !important;
    }
  }
}

.note-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .note-container {
    margin-bottom: 15px;
  }

  .note-item {
    padding: 0 7px !important;
    margin-bottom: 16px;
    height: 230px;

    .note-item-title {
      color: #98a9bb;
      padding-left: 9px;
      margin-bottom: 7px !important;
    }
  }
}

.dropdown-style {
  background-color: white !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  display: flex !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  color: black !important;
  margin-bottom: 5px !important;
  margin-top: 0px !important;

  width: 100% !important;
  height: 32px;
  box-shadow: none !important;
  justify-content: space-between !important;
  text-transform: none !important;

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 0px !important;
    color: black !important;
  }

  b {
    color: #3d73dd;
  }
}
