.customer-inquiry-details {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  background-color: rgba(216, 224, 232, 0.3);
  border-radius: 6px;
  margin-bottom: 10px;

  .customer-inquiry-details-title {
    background-color: rgba(61, 115, 221, 0.1);
    min-height: 34px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 15px !important;
    display: flex;
    justify-content: space-between;

    span {
      color: #505665;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
  }

  .customer-inquiry-details-content {
    color: #98a9bb;
    font-family: "Work Sans";
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 10px 5px !important;
    min-height: 300px;

      .inquiry-item {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 12px !important;
        margin: 3px 10px;
        display: flex;
        justify-content: space-between;

        .inquiry-info {
          display: flex;
          flex-direction: column;
          min-width: 200px;
          color: #505665;

          .inquiry-title-cost {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
          }

          .inquiry-data {
            font-size: 12px;

            .no-positions-found {
              color: #d9a46b;
            }
          }
        }

        .inquiry-action-btn {
          display: flex;
          .progress-container {
            display: flex;
            justify-content: flex-start;
            padding: 6px !important;
          }

          .green {
            color: #0fd280;
            button {
              background-color: #0fd280 !important;
            }
            .CircularProgressbar-path {
              stroke: #0fd280 !important;
            }
          }

          .red {
            color: #f84141;
            button {
              background-color: #f84141 !important;
            }
            .CircularProgressbar-path {
              stroke: #f84141 !important;
            }
          }

          .blue {
            color: #3d73dd;
            button {
              background-color: #3d73dd !important;
            }
            .CircularProgressbar-path {
              stroke: #3d73dd !important;
            }
          }

          .gray {
            color: #b4c6d9;
            button {
              background-color: #b4c6d9 !important;
            }
            .CircularProgressbar-path {
              stroke: #b4c6d9 !important;
            }
          }

          .action-btn {
            padding: 0 !important;
            height: 24px;
            margin: 6px !important;
          }
        }
      }
  }
}
