.project-logs-container {
  display: flex;
  flex-direction: column;
  font-family: 'Work Sans';
  margin-top: 10px;
  .project-logs-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logs-title {
      color: #505665;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      padding: 0 9px !important;
    }

    .logs-top-right-bar {
      display: flex;
      flex-direction: row;
      padding: 0 4px !important;
    }
    .date-picker {
      width: 150px !important;
      height: 32px !important;
      margin: 0.3125rem 1px !important;

      div {
        height: 32px;
      }
    }
  }

  .logs-header {
    display: flex;
    padding: 0 24px !important;
    margin-top: 20px;

    .logs-header-content {
      border-bottom: 2px solid #3d73dd;
      .logs-header-title {
        display: flex;
        justify-content: flex-start;
        font-size: 12px;
        font-family: 'Work Sans';
        font-weight: 600;
        color: #333333;
        line-height: 14px;
        text-align: center;
        padding-bottom: 4px !important;
      }
    }
  }

  .logs-content {
    display: flex;
    padding: 0 24px;

    .logs-item {
      border-bottom: 1px solid #b4c6d9;
      display: flex;
      margin: 0 auto !important;
      height: 50px;

      .logs-item-title {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        font-family: 'Work Sans';
        color: #505665;
        line-height: 14px;
        text-align: center;
        padding-top: 19px !important;
        padding-bottom: 4px !important;
      }

      .bold {
        font-weight: 600;
      }

      .blue {
        color: #3d73dd;
      }
    }

    .dark {
      background-color: rgba(180, 198, 217, 0.1);
    }
  }
  .rejected {
    background-color: rgba(232, 8, 8, 0.1) !important;
  }

  .pagination-container {
    margin: 10px 0;
    justify-content: flex-end;
  }
}
