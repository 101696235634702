.position-content-standard-surcharges {
    margin: 5px 0;

   .position-info {
     background-color: rgba(61, 115, 221, 0.1);
     display: flex;
     font-family: "Work Sans";
     justify-content: space-between;
     border-radius: 10px;
  
     .position-info-container {
       padding: 15px 0 !important;
       .position-info-title {
         display: flex;
         justify-content: space-between;
         .position-title {
            font-size: 20px;
            font-weight: bold;
            color: #505665;
            line-height: 24px;
          }
  
         .position-total-price {
           color: #505665;
           font-size: 20px;
           font-weight: 600;
           line-height: 24px;
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           .price {
             color: #333333;
             font-size: 12px;
             font-weight: normal;
             padding-right: 50px;
           }
  
         }
       }
  
       .position-info-inputs {
         display: flex;
         flex-direction: row;
         .position-input-item {
           display: flex;
           flex-direction: column;
  
           .types-container {
             display: flex;
             flex-direction: row;
           }
  
           span {
             color: #98a9bb;
             padding-left: 10px;
           }
         }
  
       }
  
       .position-parts-price {
         display: flex;
         flex-direction: row;
         font-size: 12px;
         color: #333333;
         margin-top: 15px;
  
         .parts-item {
           display: flex;
           flex-direction: column;
  
           .parts-price {
             font-size: 14px;
             font-weight: 800;
             color: #505665;
             line-height: 16px;
             padding: 0 3px;
           }
         }
       }
     }
  
     .card-btn-container {
       display: flex;
       padding: 0px !important;
       justify-content: flex-end;
       .card-btn {
         background-color: rgba(61, 115, 221, 0.1);
         border-radius: 0 10px 10px 0;
         display: flex;
         align-items: center;
         width: 26px !important;
         svg {
           color: #3d73dd;
         }
       }
  
       .bottom-borders {
         border-bottom-right-radius: 0;
       }
     }
   }
   .bottom-borders {
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0;
   }
  
  .position-info-content {
    background-color: rgba(216, 224, 232, 0.3);
    min-height: 67vh;
    font-family: "Work Sans";
    padding: 12px 8px;

    .position-tasks {
      .position-info-header {
        padding: 3px 0;
        font-size: 14px;
        color: #98a9bb;
        line-height: 16px;

        .MuiGrid-item {
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-right: 10px !important;
        }

        .MuiGrid-item:last-of-type {
          margin-right: 0px !important;
        }

        .pihi-name {
          min-width: 100px;
          width: calc(100% - 330px);
        }

        .pihi-discount,
        .pihi-overheads,
        .pihi-waste {
          min-width: 100px;
          max-width: 100px;
        }

        span {
          padding-left: 10px;
        }
      }
    }
    .position-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 10px;

      .position-btn-part {
        display: flex;
        flex-direction: row;

        .position-btn {
          height: 32px !important;
          padding: 9px 30px !important;
          font-size: 12px;
          font-family: "Work Sans";
          font-weight: bold;
          margin: 10px;
        }

        .blue {
          background-color: #3d73dd;
          color: white;
        }

        .white {
          background-color: white;
          color: #3d73dd;
        }

        .green {
          background-color: #0fd280;
          color: white;
        }
      }
    }
  }
}
  